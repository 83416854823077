/**
 * Created by boris on 3/31/17.
 */
import React from 'react'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import UserPageInfo from '../UserPageInfo'
import ContentLoading from '../ContentLoading'
import { browserHistory } from 'react-router'
import { connect } from 'react-redux'
import {
  loadFollowersRequestsList,
  initialFollowersRequestsList,
  loadNextFollowersRequestsList,
  approveRequest,
  rejectRequest,
} from '../../actions/FollowersRequestsActions'
import { getShowUser, showUserInitial } from '../../actions/ShowUserActions'
import { setActiveItem } from '../../actions/NavbarActions'
import UsersList from '../UsersList'

class FollowersRequestsPage extends React.Component {
  componentWillMount() {
    const { loadFollowersRequestsList, getShowUser, auth } = this.props
    const { user, isAuthenticated } = auth
    const { user_id } = this.props.params
    if (user_id !== user.id) getShowUser(user_id, !isAuthenticated)
    loadFollowersRequestsList(user_id)

    const { setActiveItem } = this.props
    setActiveItem('nav-notifications')
  }

  componentWillUnmount() {
    this.props.showUserInitial()
    this.props.initialFollowersRequestsList()
  }

  loadNextData() {
    const { followings, loadNextFollowersRequestsList } = this.props
    loadNextFollowersRequestsList(followings.pagination.next_url)
  }

  approveRequest(user_id) {
    const { approveRequest, auth } = this.props
    approveRequest(auth.user.id, user_id)
  }

  rejectRequest(user_id) {
    const { rejectRequest, auth } = this.props
    rejectRequest(auth.user.id, user_id)
  }

  render() {
    const { followings, auth } = this.props
    let { user } = auth
    const { user_id } = this.props.params
    let { users, pagination, isLoading } = followings
    users = [...users.values()]
    let userPage = (
      <UserPageInfo
        followingsLink={`/users/${user.id}/followings`}
        followersLink={`/users/${user.id}/followers`}
        buttonTitle={'MY NUUZ'}
        handleButtonClick={() => browserHistory.push(`/users/${user.id}`)}
        linkTo={`/users/${user.id}`}
        user={user}
      />
    )

    // let showUserPage = null
    // if (user_id !== user.id && !showUser.isLoading) {
    //   showUserPage = (
    //     <UserPageInfo
    //       followingsLink={`/users/${showUser.user.id}/followings`}
    //       followersLink={`/users/${showUser.user.id}/followers`}
    //       buttonTitle={'MY NUUZ'}
    //       handleButtonClick={() =>
    //         browserHistory.push(`/users/${showUser.user.id}`)
    //       }
    //       linkTo={`/users/${showUser.user.id}`}
    //       user={showUser.user}
    //     />
    //   )
    // }

    return (
      <div className="page-content">
        <div className="nuuz-archive-content__title">FOLLOW REQUESTS</div>

        {users.length === 0 ? (
          <Typography className="no-stories">No users to display</Typography>
        ) : (
          <Paper elevation={5} className="user-page">
            <div>
              {user_id === user.id && userPage}
              <div className="blacklist-content">
                {isLoading ? (
                  <ContentLoading small={true} />
                ) : (
                  <div className="blacklist-container">
                    <UsersList
                      menuItems={[
                        {
                          handler: this.approveRequest.bind(this),
                          label:   'Approve',
                        },
                        {
                          handler: this.rejectRequest.bind(this),
                          label:   'Reject',
                        },
                      ]}
                      users={users}
                      loadNextData={this.loadNextData.bind(this)}
                      pagination={pagination}
                      subheader="Followings"
                    />
                  </div>
                )}
              </div>
            </div>
          </Paper>
        )}
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    showUser:   state.showUser,
    auth:       state.auth,
    followings: state.followersRequests,
  }
}

export default connect(mapStateToProps, {
  loadFollowersRequestsList,
  initialFollowersRequestsList,
  loadNextFollowersRequestsList,
  getShowUser,
  showUserInitial,
  approveRequest,
  rejectRequest,
  setActiveItem,
})(FollowersRequestsPage)

import * as ActionCable from 'actioncable'
import { publishReplay, refCount, map, distinct } from 'rxjs/operators'

import { actionCableUrl } from '../../config'
import currentUserAccessTokenObservable from '../../observables/currentUserAccessToken'

const cableObervable = currentUserAccessTokenObservable.pipe(
  distinct(),
  map(token => {
    console.log('token changed', token)
    if (token) {
      const url = `${actionCableUrl}?access_token=${token}`
      const cable = ActionCable.createConsumer(url)

      return cable
    } else {
      return null
    }
  }),

  // this is magic incantation that will
  // Before: `map` function called for each `subscribe`, when `token` changed - `map` function called again for each `subscribe`
  // After: `map` function called ONCE, when `token` changed - `map` function called again ONCE
  // More info https://github.com/ReactiveX/rxjs/issues/4729
  publishReplay(1),
  refCount()
)

export default cableObervable

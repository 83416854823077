import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router'
import classnames from 'classnames'
import PropTypes from 'prop-types'

import _ from 'lodash'

import Avatar from '@material-ui/core/Avatar'
import Divider from '@material-ui/core/Divider'

import { setShowUserCurrentPosition } from '../../actions/ShowUserActions'
import { messageContent } from './lib/messageContent'
import formatActivityCreatedAtAgo from '../../utils/formatActivityCreatedAtAgo'

import { markAlertAsRead } from '../../actions/Alerts'
import { Box } from '@material-ui/core'

class AlertsItem extends React.Component {
  constructor(props) {
    super(props)
    this.handleVisit = this.handleVisit.bind(this)
  }

  handleVisit(event) {
    if (event.defaultPrevented) {
      // event was handled elsewhere in child item
      return
    }

    const { markAlertAsRead, alert } = this.props
    markAlertAsRead(alert.id)

    this.context.router.push(alert.link_to_content)
  }

  render() {
    const { alert, setShowUserCurrentPosition } = this.props
    const { user } = alert

    const message = messageContent(alert)

    const readState = _.get(alert, 'read', true)

    return (
      <div
        className={classnames('user-item', 'story-item', {
          'story-item__unread': !readState,
        })}
      >
        <div className="user-item-content">
          <Link
            to={`/users/${user.id}`}
            className="user-info-avatar"
            onClick={e => setShowUserCurrentPosition(null)}
          >
            <Avatar
              src={user.avatar.medium}
              style={{ width: '45px', height: '45px' }}
            />
          </Link>
          <div onClick={this.handleVisit} className="user-info-name">
            <div className="container">
              <Box className="story-item-info">
                <span
                  className="story-item-excerpt story-item-description"
                  style={{ color: '#030303' }}
                >
                  {message}
                </span>
              </Box>
              <div className="user-item__created_at_ago">
                {formatActivityCreatedAtAgo(_.get(alert, 'created_at'))}
              </div>
            </div>
          </div>
          <Link to={alert.link_to_content} onClick={this.handleVisit}>
            <div className="story-item-image">
              <img
                src={
                  alert.feedable_type === 'Relationship'
                    ? alert.item_owner.avatar.medium
                    : alert.image
                }
                alt=""
              />
            </div>
          </Link>
        </div>
        <Divider inset="true" className="story-item-divider" />
      </div>
    )
  }
}

AlertsItem.contextTypes = {
  router: PropTypes.object,
}

export default connect(null, { setShowUserCurrentPosition, markAlertAsRead })(
  AlertsItem
)

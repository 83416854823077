import React from 'react'
import { Link } from 'react-router'
import Typography from '@material-ui/core/Typography'
import _ from 'lodash'

// helpers
function linkToUser(itemOwner) {
  const { id, username } = itemOwner
  const link = `/users/${id}`
  return <Link to={link}>{`@${username}`}</Link>
}

function alertOwnerLink(activity) {
  return linkToUser(activity.item_owner)
}

// makers
function makeMentionStoryContent(activity) {
  return <Typography>You mentioned {alertOwnerLink(activity)}</Typography>
}

function makeMentionCommentContent(activity) {
  return (
    <Typography>
      You mentioned {alertOwnerLink(activity)} in a comment
    </Typography>
  )
}

function makeMentionOtherContent(activity) {
  return <Typography>You mentioned {alertOwnerLink(activity)}</Typography>
}

function makeStoryContent(activity) {
  return <Typography>You posted nuuz</Typography>
}

function makeCommentContent(activity) {
  return (
    <Typography>
      You commented on {alertOwnerLink(activity)}&apos;s nuuz
    </Typography>
  )
}

function makeRelationshipContent(activity) {
  return (
    <Typography>You started following {alertOwnerLink(activity)}</Typography>
  )
}

function makeStoryLikeContent(activity) {
  return (
    <Typography>You liked {alertOwnerLink(activity)}&apos;s nuuz</Typography>
  )
}

function makeCommentLikeContent(activity) {
  return (
    <Typography>You liked {alertOwnerLink(activity)}&apos;s comment</Typography>
  )
}

function makeOtherContent(activity) {
  console.warn('makeOtherContent: unknown type', activity)
  return 'Unknown activity'
}

// main
export function messageContent(activity) {
  const feedableType = activity.feedable_type

  switch (feedableType) {
    case 'Mention': {
      const mentionableType = _.get(activity, 'feedable.mentionable_type')

      if (mentionableType === 'Story') {
        return makeMentionStoryContent(activity)
      } else if (mentionableType === 'Comment') {
        return makeMentionCommentContent(activity)
      } else {
        return makeMentionOtherContent(activity)
      }
    }
    case 'Story':
      return makeStoryContent(activity)
    case 'Comment':
      return makeCommentContent(activity)
    case 'Relationship':
      return makeRelationshipContent(activity)
    case 'StoryLike':
      return makeStoryLikeContent(activity)
    case 'CommentLike':
      return makeCommentLikeContent(activity)
    default:
      return makeOtherContent(activity)
  }
}

/**
 * Created by borysyudin on 17.02.17.
 */
import React, { Component } from 'react'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import '../css/AddComment.css'
import { MentionsInput, Mention } from 'react-mentions'

export default class AddComment extends Component {
  constructor(props) {
    super(props)
    const { initialValue } = this.props
    this.state = {
      comment_value: initialValue,
      disableButton: initialValue.trim().length === 0,
    }
    this.handleInput = this.handleInput.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.moveCaretAtEnd = this.moveCaretAtEnd.bind(this)
  }

  moveCaretAtEnd(event) {
    let temp_value = event.target.value
    event.target.value = ''
    event.target.value = temp_value
  }

  handleInput(event) {
    const { value } = event.target
    this.setState({
      comment_value: value,
      disableButton: value.trim().length === 0,
    })
  }

  handleSubmit(event) {
    event.preventDefault()
    const { addComment, parent_id } = this.props
    this.setState(prevState => ({ disableButton: !prevState.disableButton }))
    addComment(this.state.comment_value.trim(), parent_id)
    if (!parent_id) this.setState({ comment_value: '' })
  }

  renderUserSuggestion = (
    entry,
    search,
    highlightedDisplay,
    index,
    focused
  ) => {
    return (
      <div className={`mention${focused ? '-focused' : '-unfocused'}`}>
        <Avatar className="mentions-avatar" src={entry.avatar} />
        <span className="mentions-full-name">{highlightedDisplay}</span>
        <br />
        <span className="mentions-username">@{entry.id}</span>
      </div>
    )
  }

  displayTransform = display => {
    return `@${display}`
  }

  render() {
    const { allUsers } = this.props

    var users = [...allUsers.values()].map(
      ({ username, first_name, last_name, avatar }) => {
        return {
          id:      username.toLowerCase(),
          display: first_name + ' ' + last_name,
          avatar:  avatar.medium,
        }
      }
    )

    return (
      <div className="add-comment-container">
        <MentionsInput
          placeholder={this.props.hint}
          name="comment_field"
          value={this.state.comment_value}
          onChange={this.handleInput}
          className="text-field"
          onFocus={this.moveCaretAtEnd}
        >
          <Mention
            trigger="@"
            data={users}
            renderSuggestion={this.renderUserSuggestion}
            className="mentions"
            markup="@__id__ "
            displayTransform={this.displayTransform}
          />
        </MentionsInput>

        <Button
          variant="contained"
          disabled={this.state.disableButton}
          color="primary"
          onClick={this.handleSubmit}
          className="send-comment"
        >
          Post
        </Button>
      </div>
    )
  }
}

/**
 * Created by borysyudin on 15.02.17.
 */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import { Link } from 'react-router'
import { connect } from 'react-redux'
import { logout } from '../../actions/AuthActions'
import { setDrawerToggle, setActiveItem } from '../../actions/NavbarActions'
import { setCurrentFeedPosition } from '../../actions/FeedActions'
import { setArchiveCurrentPosition } from '../../actions/ArchiveActions'
import { signupReload } from '../../actions/SignUpActions'
import { setMyNuuzCurrentPosition } from '../../actions/MyNuuzActions'
import { setCurrentReadingListPosition } from '../../actions/ReadingListActions'
import { SET_EDIT_STORY } from '../../actions/constants'
import NavbarLogos from './NavbarLogos'
// import * as R from 'ramda'

import Drawer from '@material-ui/core/Drawer'
import DropdownMenu from '../DropdownMenu'
import MenuItem from '@material-ui/core/MenuItem'
import classnames from 'classnames'

import '../../css/Navbar.css'

function notifications_feeds_unread_count_text(
  notifications_feeds_unread_count
) {
  // feature is teporarily disabled (https://pm.nordicresults.com/issues/51483)
  // corresponding tests are here (please enable) nuuz-backend/spec/entries/feature/features/notifications/red_dot_with_numbers_spec.rb_
  return null
  // if (R.isNil(notifications_feeds_unread_count)) {
  //   throw new Error()
  // }

  // if (notifications_feeds_unread_count === 0) {
  //   return null
  // }

  // if (notifications_feeds_unread_count < 100) {
  //   return String(notifications_feeds_unread_count)
  // } else {
  //   return '99+'
  // }
}

class Navbar extends Component {
  constructor(props) {
    super(props)
    this.logout = this.logout.bind(this)
    this.navClickHandler = this.navClickHandler.bind(this)
  }

  logout(event) {
    event.preventDefault()
    this.props.logout()
  }

  navClickHandler(event) {
    let { setActiveItem } = this.props
    setActiveItem(event.target.getAttribute('data-active-nav'))
  }

  render() {
    const { isAuthenticated, user } = this.props.auth

    const {
      setCurrentFeedPosition,
      setArchiveCurrentPosition,
      signupReload,
      setMyNuuzCurrentPosition,
      setCurrentReadingListPosition,
      setDrawerToggle,
      active,
      showNav,
      story,
    } = this.props

    let links = (
      <div className="nav-block-content">
        <Link to="/register" onClick={signupReload} activeClassName="active">
          Sign Up
        </Link>
        <Link to="/login" activeClassName="active">
          Login
        </Link>
      </div>
    )

    let drawer = (
      <Drawer
        openSecondary={true}
        docked={false}
        open={showNav}
        className="navbar-drawer"
        onRequestChange={open => setDrawerToggle(open)}
      >
        <MenuItem onClick={setDrawerToggle}>
          <Link to="/register" onClick={signupReload} activeClassName="active">
            Sign Up
          </Link>
        </MenuItem>
        <MenuItem onClick={setDrawerToggle}>
          <Link to="/login" activeClassName="active">
            Login
          </Link>
        </MenuItem>
      </Drawer>
    )

    if (isAuthenticated) {
      const dropDownItems = [
        <Link
          data-active-nav="nav-my-nuuz"
          to={`/users/${user.id}`}
          onClick={e => {
            this.navClickHandler(e)
            setMyNuuzCurrentPosition(null)
          }}
        >
          My nuuz page
        </Link>,
        <Link
          data-active-nav="nav-my-nuuz"
          to={`/users/${user.id}/stories/nuuz_archive`}
          onClick={e => {
            this.navClickHandler(e)
            setArchiveCurrentPosition(null)
          }}
        >
          Archive
        </Link>,
        <Link
          data-active-nav="nav-my-nuuz"
          to="/editprofile"
          onClick={e => this.navClickHandler(e)}
        >
          Settings
        </Link>,
        <Link
          data-active-nav="nav-my-nuuz"
          to="/activity_history"
          onClick={e => this.navClickHandler(e)}
        >
          Activity history
        </Link>,
        <hr />,
        <a href="#" onClick={this.logout}>
          Logout
        </a>,
      ]

      const dropDownItemsNotifications = [
        <Link
          to="/alerts"
          data-active-nav="nav-notifications"
          onClick={e => this.navClickHandler(e)}
        >
          My notifications
          <div className="request-dot" />
        </Link>,

        <Link
          to={`/users/${user.id}/followers/requests`}
          data-active-nav="nav-notifications"
          onClick={e => this.navClickHandler(e)}
        >
          Follow requests
          <div className="request-dot" />
        </Link>,
      ]
      const dropDownItemsReadingList = [
        <Link
          to="/reading_list"
          data-active-nav="nav-reading-list"
          id="nav-reading-list"
          onClick={e => this.navClickHandler(e)}
          className={classnames({ active: active === 'nav-reading-list' })}
        >
          My reading list
          <div className="request-dot" />
        </Link>,
        <Link
          to="/add_reading_list"
          data-active-nav="nav-add-reading-list"
          id="nav-add-reading-list"
          onClick={e => this.navClickHandler(e)}
          className={classnames({ active: active === 'nav-add-reading-list' })}
        >
          Add to reading list
        </Link>,
      ]
      links = (
        <div className="nav-block-content">
          <Link
            to="/"
            data-active-nav="nav-feed"
            id="nav-feed"
            onClick={e => {
              this.navClickHandler(e)
              setCurrentFeedPosition(null)
            }}
            className={classnames({ active: active === 'nav-feed' })}
          >
            Feed
          </Link>
          <Link
            to="/search/people"
            data-active-nav="nav-search"
            id="nav-search"
            onClick={e => this.navClickHandler(e)}
            className={classnames({ active: active === 'nav-search' })}
          >
            Search
          </Link>
          <DropdownMenu
            dataActiveNav="nav-my-nuuz"
            active={active === 'nav-my-nuuz'}
            title="My nuuz"
            mainItems={dropDownItems}
          />
          <Link
            to="/addstory"
            data-active-nav="nav-add-story"
            id="nav-add-story"
            onClick={e => this.navClickHandler(e)}
            className={classnames({ active: active === 'nav-add-story' })}
          >
            {story.actionType === SET_EDIT_STORY ? 'My nuuz' : 'Add nuuz'}
          </Link>
          <DropdownMenu
            title="Notifications"
            active={active === 'nav-notifications'}
            mainItems={dropDownItemsNotifications}
            dotText={notifications_feeds_unread_count_text(
              user.notifications_feeds_unread_count
            )}
          />
          <DropdownMenu
            title="Reading list"
            active={active === 'nav-reading-list'}
            mainItems={dropDownItemsReadingList}
          />
        </div>
      )

      drawer = (
        <Drawer
          openSecondary={true}
          docked={false}
          open={showNav}
          className="navbar-drawer"
          onClose={open => setDrawerToggle(open)}
        >
          <MenuItem onClick={setDrawerToggle}>
            <Link to="/" onClick={e => setCurrentFeedPosition(null)}>
              Feed
            </Link>
          </MenuItem>

          <MenuItem onClick={setDrawerToggle}>
            <Link to="/search">Search</Link>
          </MenuItem>

          <MenuItem onClick={setDrawerToggle}>
            <Link
              to={`/users/${user.id}`}
              onClick={e => setMyNuuzCurrentPosition(null)}
            >
              My nuuz
            </Link>
          </MenuItem>

          <MenuItem onClick={setDrawerToggle}>
            <Link to="/addstory">Add nuuz</Link>
          </MenuItem>

          <MenuItem onClick={setDrawerToggle}>
            <Link
              to={`/users/${user.id}/stories/nuuz_archive`}
              onClick={e => setArchiveCurrentPosition(null)}
            >
              Archive
            </Link>
          </MenuItem>

          <MenuItem onClick={setDrawerToggle}>
            <Link to="/alerts">My notifications</Link>
          </MenuItem>

          <MenuItem onClick={setDrawerToggle}>
            <Link to={`/users/${user.id}/followers/requests`}>
              Follow requests
            </Link>
          </MenuItem>
          <MenuItem onClick={setDrawerToggle}>
            <Link
              to="/add_reading_list"
              onClick={() => setCurrentReadingListPosition(null)}
            >
              Add to reading list
            </Link>
          </MenuItem>
          <MenuItem onClick={setDrawerToggle}>
            <Link
              to="/reading_list"
              onClick={() => setCurrentReadingListPosition(null)}
            >
              Reading list
            </Link>
          </MenuItem>

          <MenuItem onClick={setDrawerToggle}>
            <Link to="/activity_history">Activity history</Link>
          </MenuItem>

          <MenuItem onClick={setDrawerToggle}>
            <Link to="/editprofile">Settings</Link>
          </MenuItem>

          <hr />

          <MenuItem onClick={setDrawerToggle}>
            <a onClick={this.logout}>Logout</a>
          </MenuItem>
        </Drawer>
      )
    }

    return (
      <div className="header">
        <div className="arrow-container">
          <i
            className="fa fa-bars arrow"
            aria-hidden="true"
            onClick={setDrawerToggle}
          />
        </div>
        <NavbarLogos />
        <ReactCSSTransitionGroup
          transitionName="nav-block"
          transitionEnterTimeout={700}
          transitionLeaveTimeout={700}
        >
          <div className="nav-block">{links}</div>
        </ReactCSSTransitionGroup>
        {drawer}
      </div>
    )
  }
}

Navbar.propTypes = {
  auth:   PropTypes.object.isRequired,
  logout: PropTypes.func.isRequired,
}

function mapStateToProps(state) {
  return {
    auth:    state.auth,
    showNav: state.navbar.showNav,
    active:  state.navbar.active,
    story:   state.editStory,
  }
}

export default connect(mapStateToProps, {
  logout,
  setCurrentFeedPosition,
  setArchiveCurrentPosition,
  signupReload,
  setMyNuuzCurrentPosition,
  setDrawerToggle,
  setActiveItem,
  setCurrentReadingListPosition,
})(Navbar)

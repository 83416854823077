/**
 * Created by borysyudin on 19.02.17.
 */
import React, { Component } from 'react'
import Loading from 'react-loading'
import classnames from 'classnames'

import '../css/ContentLoading.css'

export default class ContentLoading extends Component {
  render() {
    const { small, list } = this.props
    return (
      <div
        className={classnames('content-loading', {
          'small-loading': small,
          'list-loading':  list,
        })}
      >
        <Loading type="spinningBubbles" color="#183054" />
      </div>
    )
  }
}

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import UserPage from './UserPage'
import { connect } from 'react-redux'
import {
  loadMyNuuz,
  initialMyNuuz,
  setMyNuuzCurrentPosition,
} from '../actions/MyNuuzActions'
import { browserHistory } from 'react-router'
import { submitEditStory } from '../actions/EditStoryActions'
import Scroll from 'react-scroll'
import Button from '@material-ui/core/Button'
import { setArchiveCurrentPosition } from '../actions/ArchiveActions'
import {
  searchUserStories,
  loadNextSearchUserStories,
} from '../actions/SearchActions'
import { setActiveItem } from '../actions/NavbarActions'

import registerMyNuuzPageRealtimeUpdatesEffect from '../realtime-updates/per-page/myNuuz'
import { Box, Card, CardActions } from '@material-ui/core'

class MyNuuz extends Component {
  constructor(props) {
    super(props)

    this.handleStoriesSearch = this.handleStoriesSearch.bind(this)
    this.handleNextStoriesSearch = this.handleNextStoriesSearch.bind(this)
  }

  handleStoriesSearch(event) {
    const { searchUserStories, auth } = this.props
    searchUserStories(auth.user.id, event.target.value)
  }

  handleNextStoriesSearch() {
    const { loadNextSearchUserStories, searchStories } = this.props
    loadNextSearchUserStories(searchStories.pagination.next_url)
  }

  componentWillMount() {
    const { loadMyNuuz, myNuuzCurrentPosition, initialMyNuuz } = this.props

    if (myNuuzCurrentPosition == null) {
      initialMyNuuz()
      loadMyNuuz()
    }

    const { setActiveItem } = this.props
    setActiveItem('nav-my-nuuz')
  }

  componentDidMount() {
    const { myNuuzCurrentPosition } = this.props
    if (myNuuzCurrentPosition != null) {
      Scroll.animateScroll.scrollTo(myNuuzCurrentPosition, { duration: 0 })
    }

    this.feedPageRealtimeUpdatesEffectUnsubscribe = registerMyNuuzPageRealtimeUpdatesEffect()
  }

  componentWillUnmount() {
    const { setMyNuuzCurrentPosition } = this.props
    setMyNuuzCurrentPosition(window.pageYOffset)

    this.feedPageRealtimeUpdatesEffectUnsubscribe()
  }

  moveToArchive(story) {
    const { submitEditStory, loadMyNuuz } = this.props
    const { text, id } = story
    submitEditStory({ rank: '', text }, id).then(loadMyNuuz)
  }

  render() {
    const { stories, isLoading, auth, searchStories } = this.props
    const { user } = auth

    let userPageProps = {
      followingsLink:    `/users/${user.id}/followings`,
      followersLink:     `/users/${user.id}/followers`,
      moveToArchive:     this.moveToArchive.bind(this),
      isLoading:         isLoading,
      user:              user,
      actionsURL:        '/stories',
      linkTo:            `/users/${user.id}`,
      buttonTitle:       'EDIT PROFILE',
      handleButtonClick: () => browserHistory.push('/editprofile'),
      stories:           [...stories.values()],
      loadNextData:      this.handleNextStoriesSearch,
    }

    if (searchStories.text) {
      userPageProps.stories = [...searchStories.stories.values()]
      userPageProps.isLoading = searchStories.isLoading
      userPageProps.pagination = searchStories.pagination
    }

    return (
      <Box>
        <UserPage {...userPageProps} />
        <Card className="btn-archive" elevation={0}>
          <CardActions>
            <Button
              href={`/users/${user.id}/stories/nuuz_archive`}
              onClick={e => setArchiveCurrentPosition(null)}
              variant="contained"
              color="primary"
            >
              Archive
            </Button>
          </CardActions>
        </Card>
      </Box>
    )
  }
}

MyNuuz.propTypes = {
  auth: PropTypes.object.isRequired,
}

function mapStateToProps(state) {
  return {
    isLoading:             state.myNuuz.isLoading,
    auth:                  state.auth,
    stories:               state.myNuuz.stories,
    pagination:            state.myNuuz.pagination,
    myNuuzCurrentPosition: state.myNuuz.currentPosition,
    searchStories:         state.search.currentUserStories,
  }
}

export default connect(mapStateToProps, {
  loadMyNuuz,
  initialMyNuuz,
  submitEditStory,
  setMyNuuzCurrentPosition,
  setArchiveCurrentPosition,
  searchUserStories,
  loadNextSearchUserStories,
  setActiveItem,
})(MyNuuz)

/**
 * Created by boris on 4/21/17.
 */
import React from 'react'
import { Link } from 'react-router'
import { setMyNuuzCurrentPosition } from '../actions/MyNuuzActions'
import { store } from '../Store'

import classnames from 'classnames'

class DropdownMenu extends React.Component {
  render() {
    const {
      mainItems,
      title,
      titleLink,
      active,
      dataActiveNav,
      onClick,
      dotText,
    } = this.props
    let titleProps = {}
    let dropDownProps = {}
    if (dataActiveNav) titleProps['data-active-nav'] = dataActiveNav

    if (onClick) dropDownProps['onClick'] = onClick
    return (
      <div
        className={classnames('dropdown-menu-items', { active: active })}
        {...dropDownProps}
      >
        {dotText ? <mark>{dotText}</mark> : null}
        <Link
          {...titleProps}
          to={titleLink}
          className="dropdown-menu-title"
          onClick={e => store.dispatch(setMyNuuzCurrentPosition(null))}
        >
          {title}
          <i className="fa fa-caret-down" aria-hidden="true" />
        </Link>
        <ul className="dropdown-menu-list">
          {mainItems.map((item, index) => (
            <li key={index} className="dropdown-menu-item">
              {item}
            </li>
          ))}
        </ul>
      </div>
    )
  }
}

export default DropdownMenu

/**
 * Created by boris on 2/27/17.
 */
import axiosClient from '../axiosClient'
import { MAIN_URL, SHOW_SIGNUP_FORM, SIGNUP_RELOAD } from '../actions/constants'
import { login } from '../actions/AuthActions'

export function checkEmail(email) {
  return dispatch => {
    return axiosClient.get(MAIN_URL + '/registration/email', {
      params: { email: email },
    })
  }
}

export function checkUsername(username) {
  return dispatch => {
    return axiosClient.get(MAIN_URL + '/registration/username', {
      params: { username: username },
    })
  }
}

export function signup(data) {
  return dispatch => {
    return axiosClient.post(MAIN_URL + '/users', data).then(response => {
      const { username, password, token } = data
      if (token) dispatch(login({ token }))
      else dispatch(login({ username, password }))
    })
  }
}

export function signupReload() {
  return {
    type: SIGNUP_RELOAD,
  }
}

export function showSignupForm() {
  return {
    type: SHOW_SIGNUP_FORM,
  }
}

/**
 * Created by boris on 3/31/17.
 */
import axiosClient from '../axiosClient'
import { MAIN_URL, SET_DELETE_FOLLOWER } from '../actions/constants'
import { getCurrentUser } from '../actions/AuthActions'

export function followUser(user_id, following_id) {
  return dispatch => {
    return axiosClient
      .post(`${MAIN_URL}/users/${user_id}/followings`, { following_id })
      .then(response => {
        dispatch(getCurrentUser())
        return response.data
      })
  }
}

export function unfollowUser(user_id, following_id) {
  return dispatch => {
    return axiosClient
      .delete(`${MAIN_URL}/users/${user_id}/followings/${following_id}`)
      .then(response => {
        dispatch(getCurrentUser())
        return response.data
      })
  }
}

export function approveFollowing(user_id, following_id) {
  return dispatch => {
    return axiosClient
      .patch(`${MAIN_URL}/users/${user_id}/followers/${following_id}`, {
        status: 'approve',
      })
      .then(response => {
        console.log(response)
      })
  }
}

export function rejectFollowing(user_id, following_id) {
  return dispatch => {
    return axiosClient
      .patch(`${MAIN_URL}/users/${user_id}/followers/${following_id}`, {
        status: 'reject',
      })
      .then(response => {
        console.log(response)
      })
  }
}

export function setDeleteFollower(user_id) {
  return {
    type: SET_DELETE_FOLLOWER,
    user_id,
  }
}

export function deleteFollower(user_id, following_id) {
  return dispatch => {
    return axiosClient
      .delete(`${MAIN_URL}/users/${user_id}/followers/${following_id}`)
      .then(response => {
        response && dispatch(setDeleteFollower(response.data.id))
      })
  }
}

/**
 * Created by boris on 5/3/17.
 */

export default function(state = { isLoading: false }, action) {
  switch (action.type) {
    case 'RESET_PASSWORD_INITIAL': {
      return { isLoading: false }
    }
    case 'RESET_PASSWORD_LOADING': {
      return { isLoading: true }
    }
    default:
      return state
  }
}

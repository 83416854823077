import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'
import Header from './components/Navbar/Navbar'
import Footer from './components/Footer'
import { connect } from 'react-redux'
import Page404 from './components/Page404'
import DeactivatedAccountPage from './components/Account/DeactivatedAccountPage'
import NavbarLogos from './components/Navbar/NavbarLogos'

import Alert from 'react-s-alert'
import 'react-s-alert/dist/s-alert-default.css'
import 'react-s-alert/dist/s-alert-css-effects/slide.css'

import './css/App.css'
// import { indigo } from '@material-ui/core/colors'

const rootTheme = createMuiTheme({
  palette: {
    primary: {
      main: '#183054',
      // },
      // secondary: {
      //   main: indigo[900],
    },
  },
  typography: {
    // fontFamily: '"Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',
    fontFamily: 'Roboto, sans-serif',
    // fontFamily: [
    //   '-apple-system',
    //   'BlinkMacSystemFont',
    //   '"Segoe UI"',
    //   'Roboto',
    //   '"Helvetica Neue"',
    //   'Arial',
    //   'sans-serif',
    //   '"Apple Color Emoji"',
    //   '"Segoe UI Emoji"',
    //   '"Segoe UI Symbol"',
    // ].join(','),
    fontSize:   19.6,
  },
  overrides: {
    MuiDropzoneArea: {
      root: {
        height:          200,
        width:           200,
        top:             0,
        bottom:          0,
        'min-height':    200,
        'border-radius': 100,
      },
      text: {
        display:      'block',
        position:     'absolute',
        bottom:       24,
        width:        '100%',
        'text-align': 'center',
        'font-size':  17,
        opacity:      10,
        color:        '#d0d0d0',
      },
      icon: {
        position: 'absolute',
        top:      25,
        left:     35,
        width:    35,
        height:   35,
        opacity:  90,
        color:    '#e0e0e0',
      },
      textContainer: {
        width: '100%',
      },
    },
    MuiDropzonePreviewList: {
      image: {
        height: '200px !important',
      },
    },
  },
})

class App extends Component {
  componentDidUpdate() {
    window.scrollTo(0, 0)
  }

  render() {
    const { isLoaded, notFound } = this.props.app
    const { isAuthenticated, user } = this.props.auth
    let container = (
      <div className="container">
        <Header />
        {this.props.children}
        <Footer />
        <Alert stack={{ limit: 3 }} />
      </div>
    )

    if (isAuthenticated && user && !user.is_active) {
      container = (
        <div className="container">
          <NavbarLogos />
          <DeactivatedAccountPage />
          <Footer />
          <Alert stack={{ limit: 3 }} />
        </div>
      )
    }

    if (notFound) {
      return (
        <div className="not-found-container">
          {this.props.children}
          <Page404 />
        </div>
      )
    }

    if (!isLoaded) {
      return null
    }

    return <ThemeProvider theme={rootTheme}>{container}</ThemeProvider>
  }
}

App.propTypes = {
  app: PropTypes.object.isRequired,
}

function mapStateToProps(state) {
  return {
    app:  state.app,
    auth: state.auth,
  }
}

export default connect(mapStateToProps)(App)

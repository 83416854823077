import axios from 'axios'
import currentUserAccessTokenObservable from './observables/currentUserAccessToken'
import { auth_url } from './config.js'
import * as R from 'ramda'

const axiosClient = axios.create()

// TODO: dont use interceptors
axiosClient.interceptors.response.use(undefined, error => {
  // error handler, https://github.com/axios/axios#interceptors

  // Unauthorized
  if (R.path(['response', 'status'], error) !== 401) {
    throw error // throw as ordinary
  }

  console.log('error', error)
  console.log('error.response.status', error.response.status)
  console.log('error.config', error.config)

  if (!localStorage.refresh_token) {
    throw error
  }

  const data = {
    client_id:     process.env.REACT_APP__FRONTEND_OAUTH_UID,
    client_secret: process.env.REACT_APP__FRONTEND_OAUTH_SECRET,
    grant_type:    'refresh_token',
    refresh_token: localStorage.refresh_token,
  }

  return axios
    .post(auth_url + '/oauth/token', data)
    .then(response => {
      const { access_token, refresh_token } = response.data

      if (access_token) {
        localStorage.setItem('access_token', access_token)
        axiosClient.defaults.headers.common[
          'Authorization'
        ] = `Bearer ${access_token}`
        currentUserAccessTokenObservable.next(access_token)
      } else {
        localStorage.removeItem('access_token')
        delete axiosClient.defaults.headers.common['Authorization']
        currentUserAccessTokenObservable.next(null)
      }

      if (refresh_token) localStorage.setItem('refresh_token', refresh_token)
      else localStorage.removeItem('refresh_token')

      // retry original request
      error.config.headers.Authorization = `Bearer ${localStorage.access_token}`

      return axiosClient(error.config)
    })
    .catch(function(error) {
      localStorage.removeItem('refresh_token')
      localStorage.removeItem('access_token')
      console.log('Refresh login error: ', error)
      throw error
    })
})

export default axiosClient

/**
 * Created by boris on 3/1/17.
 */
import axiosClient from '../axiosClient'
import {
  SET_STORY_PAGE_COMMENTS,
  SET_NEXT_STORY_PAGE_COMMENTS,
  SET_DELETE_COMMENT,
  SET_STORY_COMMENTS_INITIAL,
  SET_ADD_COMMENT,
  SET_UPDATE_COMMENT,
  SET_COMMENT_LIKE,
  MAIN_URL,
  SET_STORY_INITIAL,
  SET_COMMENT_LIKES,
  SET_COMMENT_LIKES_INITIAL,
} from '../actions/constants'

export function setStoryPageComments(story_id, comments) {
  return {
    type: SET_STORY_PAGE_COMMENTS,
    story_id,
    comments,
  }
}

export function setDeleteComment(id) {
  return {
    type: SET_DELETE_COMMENT,
    id,
  }
}

export function reportComment(target, reason) {
  return dispatch =>
    axiosClient.post(`${MAIN_URL}/complaints`, {
      reason,
      type: 'Comment',
      target,
    })
}

export function setAddComment(comment) {
  return {
    type: SET_ADD_COMMENT,
    comment,
  }
}

export function setUpdateComment(comment) {
  return {
    type: SET_UPDATE_COMMENT,
    comment,
  }
}

export function setCommentLike(comment) {
  return {
    type: SET_COMMENT_LIKE,
    comment,
  }
}

export function setStoryCommentsStoryInitial() {
  return {
    type: SET_STORY_INITIAL,
  }
}
export function setStoryCommentsPageInitial() {
  return {
    type: SET_STORY_COMMENTS_INITIAL,
  }
}

export function setCommentLikesInitial() {
  return {
    type: SET_COMMENT_LIKES_INITIAL,
  }
}

export function setCommentLikes(commentLikes) {
  return {
    type: SET_COMMENT_LIKES,
    commentLikes,
  }
}

export function deleteComment(story_id, comment_id) {
  return dispatch =>
    axiosClient
      .delete(`${MAIN_URL}/stories/${story_id}/comments/${comment_id}`)
      .then(response => dispatch(setDeleteComment(comment_id)))
}

export function likeComment(story_id, comment_id) {
  return dispatch => {
    dispatch({ type: 'SET_IMIDIATE_LIKE', comment_id })
    axiosClient
      .post(`${MAIN_URL}/stories/${story_id}/comments/${comment_id}/like`)
      .then(response => dispatch(setCommentLike(response.data)))
  }
}

export function unlikeComment(story_id, comment_id) {
  return dispatch => {
    dispatch({ type: 'SET_IMIDIATE_UNLIKE', comment_id })
    axiosClient
      .delete(`${MAIN_URL}/stories/${story_id}/comments/${comment_id}/like`)
      .then(response => dispatch(setCommentLike(response.data)))
  }
}

export function loadComments(story_id) {
  return dispatch =>
    axiosClient
      .get(`${MAIN_URL}/stories/${story_id}/comments`)
      .then(response => dispatch(setStoryPageComments(story_id, response.data)))
}

export function setNextStoryPageComments(data) {
  return {
    type: SET_NEXT_STORY_PAGE_COMMENTS,
    data,
  }
}

export function loadNextComments(url) {
  return dispatch => {
    return axiosClient.get(url).then(response => {
      dispatch(setNextStoryPageComments(response.data))
    })
  }
}

export function addComment(story_id, data) {
  return dispatch =>
    axiosClient
      .post(`${MAIN_URL}/stories/${story_id}/comments`, data)
      .then(response => dispatch(setAddComment(response.data)))
}

export function updateComment(story_id, comment_id, data) {
  return dispatch =>
    axiosClient
      .patch(`${MAIN_URL}/stories/${story_id}/comments/${comment_id}`, data)
      .then(response => dispatch(setUpdateComment(response.data)))
}

export function setCommentsPageInitial() {
  return dispatch => {
    dispatch(setStoryCommentsStoryInitial())
    dispatch(setStoryCommentsPageInitial())
  }
}

export function loadCommentLikes(story_id, comment_id) {
  return dispatch => {
    const URL = `${MAIN_URL}/stories/${story_id}/comments/${comment_id}/likes`
    return axiosClient
      .get(URL)
      .then(response => {
        dispatch(setCommentLikes(response.data))
      })
      .catch(err => {
        console.log('loadCommentLikes', err)
      })
  }
}

/**
 * Created by boris on 4/25/17.
 */
import { arrayMove } from 'react-sortable-hoc'

import {
  ACTION_LIST_INITIAL,
  SET_ACTIONS_LIST,
  ACTION_LIST_LOADING,
  SET_PUBLICATIONS_ORDER,
  DELETE_PUBLICATION,
} from '../actions/constants'

const initialState = {
  isLoading:  false,
  items:      new Map(),
  pagination: {},
}

export default function(state = initialState, action) {
  switch (action.type) {
    case ACTION_LIST_INITIAL: {
      return {
        isLoading:  false,
        items:      new Map(),
        pagination: {},
      }
    }

    case ACTION_LIST_LOADING: {
      return {
        isLoading:  true,
        items:      new Map(),
        pagination: {},
      }
    }

    case SET_ACTIONS_LIST: {
      let newState = {
        isLoading:  false,
        items:      new Map(),
        pagination: {},
      }

      if (action.data) {
        const { data, pagination } = action.data
        data.forEach(item => newState.items.set(item.id, item))
        newState.pagination = Object.assign({}, pagination)
      }

      return newState
    }

    case SET_PUBLICATIONS_ORDER: {
      let newState = Object.assign({}, state, {
        items:      new Map(),
        pagination: Object.assign({}, state.pagination),
      })
      const { oldPosition, newPosition } = action
      let newItems = [...state.items.values()]
      newItems = arrayMove(newItems, oldPosition, newPosition)
      newItems.forEach((item, index) => (item.position = index))
      newItems.forEach(item => newState.items.set(item.id, item))

      return newState
    }

    case DELETE_PUBLICATION: {
      let newState = Object.assign({}, state, {
        items:      new Map(),
        pagination: Object.assign({}, state.pagination),
      })
      let oldMap = new Map(state.items)
      oldMap.delete(action.id)
      let newItems = [...oldMap.values()]
      newItems.forEach((item, index) => (item.position = index))
      newItems.forEach(item => newState.items.set(item.id, item))

      return newState
    }

    default:
      return state
  }
}

/**
 * Created by boris on 3/31/17.
 */
import axiosClient from '../axiosClient'
import {
  MAIN_URL,
  SET_FOLLOWINGS_LIST,
  INITIAL_FOLLOWINGS_LIST,
  SET_NEXT_FOLLOWINGS_LIST,
  SET_FOLLOWINGS_LIST_USER,
  REMOVE_FOLLOWINGS_LIST_USER,
} from '../actions/constants'

export function initialFollowingsList() {
  return {
    type: INITIAL_FOLLOWINGS_LIST,
  }
}

export function setFollowingsList(data) {
  return {
    type: SET_FOLLOWINGS_LIST,
    data,
  }
}

export function loadFollowingsList(user_id) {
  return dispatch =>
    axiosClient
      .get(`${MAIN_URL}/users/${user_id}/followings`)
      .then(response => dispatch(setFollowingsList(response.data)))
}

export function setNextFollowingsList(data) {
  return {
    type: SET_NEXT_FOLLOWINGS_LIST,
    data,
  }
}

export function loadNextFollowingsList(url) {
  return dispatch =>
    axiosClient
      .get(url)
      .then(response => dispatch(setNextFollowingsList(response.data)))
}

export function setFollowingsListUser(user) {
  return {
    type: SET_FOLLOWINGS_LIST_USER,
    user,
  }
}

export function removeFollowingsListUser(user_id) {
  return {
    type: REMOVE_FOLLOWINGS_LIST_USER,
    user_id,
  }
}

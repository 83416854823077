import { pairwise, distinct } from 'rxjs/operators'
import currentUserIdObservable from '../observables/currentUserId'

export default function handleCurrentUserIdChange({
  onUserLogin,
  onUserLogout,
}) {
  currentUserIdObservable
    .pipe(pairwise(), distinct())
    .subscribe(([lastUserId, currentUserId]) => {
      if (lastUserId) {
        if (currentUserId) {
          onUserLogout(lastUserId)
          onUserLogin(currentUserId)
        } else {
          onUserLogout(lastUserId)
        }
      } else {
        if (currentUserId) {
          onUserLogin(currentUserId)
        } else {
          // do nothing
        }
      }
    })
}

import React from 'react'
import { Link } from 'react-router'
import Typography from '@material-ui/core/Typography'
import _ from 'lodash'

// helpers
function linkToUser({ id, username }) {
  const link = `/users/${id}`
  return <Link to={link}>{`@${username}`}</Link>
}

function alertUserLink(alert) {
  return linkToUser(alert.user)
}

// makers
function makeMentionStoryContent(alert) {
  return <Typography>{alertUserLink(alert)} mentioned you</Typography>
}

function makeMentionCommentContent(alert) {
  return (
    <Typography>{alertUserLink(alert)} mentioned you in a comment</Typography>
  )
}

function makeMentionOtherContent(alert) {
  return <Typography>{alertUserLink(alert)} mentioned you</Typography>
}

function makeStoryContent(alert) {
  return <Typography>{alertUserLink(alert)} created a nuuz</Typography>
}

function makeCommentContent(alert) {
  return <Typography>{alertUserLink(alert)} commented on your nuuz</Typography>
}

function makeRelationshipContent(alert) {
  return <Typography>{alertUserLink(alert)} started following you</Typography>
}

function makeStoryLikeContent(alert) {
  return <Typography>{alertUserLink(alert)} liked your nuuz</Typography>
}

function makeCommentLikeContent(alert) {
  return <Typography>{alertUserLink(alert)} liked your comment</Typography>
}

function makeOtherContent(alert) {
  console.warn('makeOtherContent: unknown type', alert)
  return 'Unknown alert'
}

// main
export function messageContent(alert) {
  const feedableType = alert.feedable_type

  switch (feedableType) {
    case 'Mention': {
      const mentionableType = _.get(alert, 'feedable.mentionable_type')

      if (mentionableType === 'Story') {
        return makeMentionStoryContent(alert)
      } else if (mentionableType === 'Comment') {
        return makeMentionCommentContent(alert)
      } else {
        return makeMentionOtherContent(alert)
      }
    }
    case 'Story':
      return makeStoryContent(alert)
    case 'Comment':
      return makeCommentContent(alert)
    case 'Relationship':
      return makeRelationshipContent(alert)
    case 'StoryLike':
      return makeStoryLikeContent(alert)
    case 'CommentLike':
      return makeCommentLikeContent(alert)
    default:
      return makeOtherContent(alert)
  }
}

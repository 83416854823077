/**
 * Created by boris on 2/28/17.
 */
import axiosClient from '../axiosClient'
import { MAIN_URL, SET_CURRENT_USER } from '../actions/constants'

export function setProfile(user) {
  return {
    type: SET_CURRENT_USER,
    user,
  }
}

export function editProfile(data) {
  return dispatch =>
    axiosClient
      .put(`${MAIN_URL}/users/current`, data)
      .then(response => dispatch(setProfile(response.data)))
}

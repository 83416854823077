/**
 * Created by borysyudin on 15.02.17.
 */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { browserHistory } from 'react-router'
import { likeStory } from '../../actions/StoryActions'
import { connect } from 'react-redux'
import { setEditStory } from '../../actions/EditStoryActions'
import { SET_SHARE_STORY } from '../../actions/constants'
import {
  addToReadingList,
  deleteFromReadingList,
} from '../../actions/ReadingListActions'

import Dialog from '@material-ui/core/Dialog'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'

import activeReadingList from '../../img/bookmark-active.png'
import disabledReadingList from '../../img/bookmark-disabled.png'
import replyIcon from '../../img/reply-icon.png'

import '../../css/Activities.css'
import {
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core'

class Activities extends Component {
  constructor(props) {
    super(props)
    this.state = {
      openConfirmWindow: false,
    }

    this.handleOpenDialog = this.handleOpenDialog.bind(this)
    this.handleCloseDialog = this.handleCloseDialog.bind(this)
    this.deleteEvent = this.deleteEvent.bind(this)
  }

  getAmount(amount) {
    return amount < 1000 ? amount : amount / 1000 + 'k'
  }

  likeClick(event) {
    const { story, actionsURL, likeStory } = this.props
    likeStory(`${actionsURL}/${story.id}/like`, story.liked)
  }

  shareEvent() {
    const { story, setEditStory } = this.props
    setEditStory(story, SET_SHARE_STORY)
    browserHistory.push('/addstory')
  }

  handleOpenDialog() {
    this.setState({
      openConfirmWindow: true,
    })
  }

  handleCloseDialog() {
    this.setState({
      openConfirmWindow: false,
    })
  }

  deleteEvent() {
    const { story, deleteFromReadingList } = this.props
    this.handleCloseDialog()
    deleteFromReadingList('Story', story.id)
  }

  render() {
    const { story, isAuthenticated, addToReadingList } = this.props
    const { openConfirmWindow } = this.state

    let content = null
    const actions = [
      <Button color="primary" onClick={this.handleCloseDialog}>
        Cancel
      </Button>,
      <Button color="primary" focusVisible={true} onClick={this.deleteEvent}>
        Remove
      </Button>,
    ]

    const confirmDelete = (
      <Dialog
        aria-labelledby="Remove story"
        modal={false}
        open={openConfirmWindow}
        onClose={this.handleClose}
      >
        <DialogTitle>Remove story</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want remove story from reading list?
          </DialogContentText>
        </DialogContent>
        <DialogActions>{actions}</DialogActions>
      </Dialog>
    )

    content = (
      <div className="activities">
        {isAuthenticated && (
          <div className="reading_list">
            {story.read_later ? (
              <img
                src={activeReadingList}
                alt="Reading list"
                onClick={this.handleOpenDialog}
              />
            ) : (
              <img
                src={disabledReadingList}
                alt="Reading list"
                onClick={() => addToReadingList('Story', story.id)}
              />
            )}
          </div>
        )}
        {isAuthenticated && confirmDelete}
        <Typography
          className="activities-info"
          style={{ marginBottom: '15px' }}
        >
          <img
            className="activity"
            src={replyIcon}
            alt="Reply Icon"
            onClick={this.shareEvent.bind(this)}
            style={{ marginLeft: '22px', marginTop: '5px' }}
          />
        </Typography>
      </div>
    )

    return content
  }
}

Activities.propTypes = {
  likeStory: PropTypes.func.isRequired,
}

function mapStateToProps(state) {
  return {
    user:            state.auth.user,
    isAuthenticated: state.auth.isAuthenticated,
  }
}

export default connect(mapStateToProps, {
  likeStory,
  setEditStory,
  addToReadingList,
  deleteFromReadingList,
})(Activities)

/**
 * Created by boris on 3/23/17.
 */

import {
  MAIN_URL,
  SET_EDIT_STORY,
  FORBIDDEN,
  SET_EDIT_STORY_INITIAL,
} from '../actions/constants'
import axiosClient from '../axiosClient'

export function setEditStory(story, actionType) {
  return {
    type: SET_EDIT_STORY,
    story,
    actionType,
  }
}

export function setEditStoryInitial() {
  return {
    type: SET_EDIT_STORY_INITIAL,
  }
}

export function submitEditStory(data, id) {
  return dispatch => {
    return axiosClient
      .put(`${MAIN_URL}/stories/${id}`, data)
      .then(response => dispatch(setEditStory()))
      .catch(err => {
        if (err.response.status === FORBIDDEN) dispatch(setEditStory())
      })
  }
}

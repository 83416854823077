/**
 * Created by boris on 2/27/17.
 */
import * as RA from 'ramda-adjunct'
import { SET_CURRENT_USER, REALTIME_USER_UPDATED } from '../actions/constants'

const initialState = {
  isAuthenticated: false,
  user:            {},
}

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_CURRENT_USER: {
      return {
        isAuthenticated: !RA.isNilOrEmpty(action.user),
        user:            action.user,
      }
    }
    case REALTIME_USER_UPDATED: {
      const { user_data } = action.payload

      return {
        isAuthenticated: !RA.isNilOrEmpty(user_data),
        user:            user_data,
      }
    }
    default:
      return state
  }
}

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { likeStoryAndReloadCurrentStoryLikes } from '../../actions/StoryActions'
import { connect } from 'react-redux'
import classnames from 'classnames'
import { browserHistory } from 'react-router'
import { setEditStory } from '../../actions/EditStoryActions'
import { SET_SHARE_STORY } from '../../actions/constants'
import {
  addToReadingList,
  deleteFromReadingList,
} from '../../actions/ReadingListActions'

import Dialog from '@material-ui/core/Dialog'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'

import activeReadingList from '../../img/bookmark-active.png'
import disabledReadingList from '../../img/bookmark-disabled.png'
import commentsIcon from '../../img/comments-icon.png'
import replyIcon from '../../img/reply-icon.png'
import likeIcon from '../../img/like-icon.png'
import likeSelectedIcon from '../../img/like-selected-icon.png'

import '../../css/StoryCommentsPage/StoryActivities.css'
import {
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core'

class StoryActivities extends Component {
  constructor(props) {
    super(props)

    this.state = {
      openConfirmWindow: false,
    }

    this.handleOpenDialog = this.handleOpenDialog.bind(this)
    this.handleCloseDialog = this.handleCloseDialog.bind(this)
    this.deleteEvent = this.deleteEvent.bind(this)
  }

  getAmount(amount) {
    return amount < 1000 ? amount : amount / 1000 + 'k'
  }

  likeClick(event) {
    const {
      story,
      actionsURL,
      likeStoryAndReloadCurrentStoryLikes,
    } = this.props
    likeStoryAndReloadCurrentStoryLikes(
      `${actionsURL}/${story.id}/like`,
      story.liked,
      story.id
    )
  }

  shareEvent() {
    const { story, setEditStory } = this.props
    setEditStory(story, SET_SHARE_STORY)
    browserHistory.push('/addstory')
  }

  handleOpenDialog() {
    this.setState({
      openConfirmWindow: true,
    })
  }

  handleCloseDialog() {
    this.setState({
      openConfirmWindow: false,
    })
  }

  deleteEvent() {
    const { story, deleteFromReadingList } = this.props
    this.handleCloseDialog()
    deleteFromReadingList('Story', story.id)
  }

  render() {
    const {
      story,
      isAuthenticated,
      addToReadingList,
      onLikesInfoClick,
      onCommentsInfoClick,
    } = this.props
    const { openConfirmWindow } = this.state

    const actions = [
      <Button color="primary" onClick={this.handleCloseDialog}>
        Cancel
      </Button>,
      <Button color="primary" focusVisible={true} onClick={this.deleteEvent}>
        Remove
      </Button>,
    ]

    const confirmDelete = (
      <Dialog
        aria-labelledby="Remove story"
        modal={false}
        open={openConfirmWindow}
        onClose={this.handleClose}
      >
        <DialogTitle>Remove story</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want remove story from reading list?
          </DialogContentText>
        </DialogContent>
        <DialogActions>{actions}</DialogActions>
      </Dialog>
    )

    const content = (
      <div className="story_comments_page__story_activities__activities">
        {isAuthenticated && (
          <img
            className={classnames(
              'story_comments_page__story_activities__activity',
              'story_comments_page__story_activities__like',
              { story_comments_page__story_activities__liked: story.liked }
            )}
            src={story.liked ? likeSelectedIcon : likeIcon}
            alt="Like Icon"
            onClick={this.likeClick.bind(this)}
          />
        )}
        <span
          className={classnames(
            'story_comments_page__story_activities__activity',
            {
              'story_comments_page__story_activities__unauth-chat-bubble': !isAuthenticated,
            }
          )}
        >
          <img
            className={'story_comments_page__story_activities__activities_svg'}
            src={commentsIcon}
            alt="Comments Icon"
          />
        </span>
        {isAuthenticated && (
          <img
            className="story_comments_page__story_activities__activity"
            src={replyIcon}
            alt="Reply Icon"
            onClick={this.shareEvent.bind(this)}
          />
        )}
        {isAuthenticated && (
          <div className="story_comments_page__story_activities__reading_list">
            {story.read_later ? (
              <img
                src={activeReadingList}
                alt="Reading list"
                onClick={this.handleOpenDialog}
              />
            ) : (
              <img
                src={disabledReadingList}
                alt="Reading list"
                onClick={() => addToReadingList('Story', story.id)}
              />
            )}
          </div>
        )}
        {isAuthenticated && confirmDelete}
        <Typography className="story_comments_page__story_activities__activities-info">
          <span
            onClick={onLikesInfoClick}
            className="story_comments_page__story_activities__likes-info"
          >
            {this.getAmount(story.likes)} likes
          </span>

          <span
            onClick={onCommentsInfoClick}
            className="story_comments_page__story_activities__comments-info"
          >
            {this.getAmount(story.comments_count)} comments
          </span>
        </Typography>
      </div>
    )
    return content
  }
}

StoryActivities.propTypes = {
  likeStoryAndReloadCurrentStoryLikes: PropTypes.func.isRequired,
}

function mapStateToProps(state) {
  return {
    user:            state.auth.user,
    isAuthenticated: state.auth.isAuthenticated,
  }
}

export default connect(mapStateToProps, {
  likeStoryAndReloadCurrentStoryLikes,
  setEditStory,
  addToReadingList,
  deleteFromReadingList,
})(StoryActivities)

/**
 * Created by boris on 3/31/17.
 */
import axiosClient from '../axiosClient'
import {
  MAIN_URL,
  SET_REJECTS,
  INITIAL_REJECTS,
  SET_NEXT_REJECTS,
} from '../actions/constants'

export function initialRejects() {
  return {
    type: INITIAL_REJECTS,
  }
}

export function setRejects(data) {
  return {
    type: SET_REJECTS,
    data,
  }
}
export function loadRejects(user_id) {
  return dispatch =>
    axiosClient
      .get(`${MAIN_URL}/users/${user_id}/followers/rejected`)
      .then(response => dispatch(setRejects(response.data)))
}

export function setNextRejects(data) {
  return {
    type: SET_NEXT_REJECTS,
    data,
  }
}

export function loadNextRejects(url) {
  return dispatch =>
    axiosClient
      .get(url)
      .then(response => dispatch(setNextRejects(response.data)))
}

/**
 * Created by boris on 2/24/17.
 */

import React, { Component } from 'react'
import UserPage from './ShowUserPage/UserPage'
import ContentLoading from './ContentLoading'
import { connect } from 'react-redux'
import {
  getShowUser,
  setShowUser,
  getShowUserStories,
  setShowUserStories,
  showUserInitial,
  showUserStoriesInitial,
  setShowUserCurrentPosition,
  loadNextUserStories,
  unblockUser,
} from '../actions/ShowUserActions'
import {
  searchUserStories,
  loadNextSearchUserStories,
  setSearchUserStoriesInitial,
} from '../actions/SearchActions'
import { browserHistory, Link } from 'react-router'
import isEmpty from 'lodash/isEmpty'
import { followUser, unfollowUser } from '../actions/FollowUnfollowActions'
import Scroll from 'react-scroll'
import { setArchiveCurrentPosition } from '../actions/ArchiveActions'
import Button from '@material-ui/core/Button'
import { setActiveItem } from '../actions/NavbarActions'
import Dialog from '@material-ui/core/Dialog'
import UserBlacklistedCurrentUserPageContent from './UserBlacklistedCurrentUserPageContent'

class ShowUserPage extends Component {
  state = {
    isOpenCantFollowErrorWindow: false,
  }

  constructor(props) {
    super(props)

    this.handleStoriesSearch = this.handleStoriesSearch.bind(this)
    this.handleNextStoriesSearch = this.handleNextStoriesSearch.bind(this)
    this.handleFollowClick = this.handleFollowClick.bind(this)
    this.loadNextData = this.loadNextData.bind(this)
  }

  componentWillMount() {
    const {
      isAuthenticated,
      userLoading,
      storiesLoading,
      storiesSuccess,
      user,
      searchStories,
      setSearchUserStoriesInitial,
      setActiveItem,
    } = this.props

    if (
      !isAuthenticated &&
      !userLoading &&
      !storiesLoading &&
      isEmpty(user) &&
      !storiesSuccess
    ) {
      browserHistory.push('/login')
    }

    const {
      getShowUser,
      getShowUserStories,
      showUserInitial,
      showUserStoriesInitial,
      currentShowUserPosition,
    } = this.props

    if (currentShowUserPosition === null || currentShowUserPosition === 0) {
      const { user_id } = this.props.params
      showUserInitial()
      showUserStoriesInitial()
      getShowUser(user_id)
      getShowUserStories(user_id)
    }

    if (
      searchStories.user_id &&
      searchStories.user_id !== parseInt(this.props.params.user_id, 10)
    ) {
      setSearchUserStoriesInitial(false)
    }

    setActiveItem(null)
  }

  componentWillUpdate(nextProps) {
    const {
      isAuthenticated,
      userLoading,
      storiesLoading,
      storiesSuccess,
      user,
    } = nextProps

    if (
      !isAuthenticated &&
      !userLoading &&
      !storiesLoading &&
      isEmpty(user) &&
      !storiesSuccess
    ) {
      browserHistory.push('/login')
    }
  }

  componentDidMount() {
    const { currentShowUserPosition } = this.props
    if (currentShowUserPosition != null) {
      Scroll.animateScroll.scrollTo(currentShowUserPosition, { duration: 0 })
    }
  }

  componentWillUnmount() {
    const { setShowUserCurrentPosition } = this.props
    setShowUserCurrentPosition(window.pageYOffset)
  }

  loadNextData() {
    const { loadNextUserStories, pagination } = this.props
    loadNextUserStories(pagination.next_url)
  }

  handleFollowClick() {
    const {
      followUser,
      unfollowUser,
      setShowUser,
      auth,
      user,
      unblockUser,
    } = this.props

    const thisUserIsBlacklistedByCurrentUser = user.is_blacklisted

    if (thisUserIsBlacklistedByCurrentUser) {
      unblockUser(user.id).then(data => {
        setShowUser(data)
      })
      return
    }

    if (user.followed_by_me_status === 'none') {
      followUser(auth.user.id, user.id)
        .then(data => setShowUser(data))
        .catch(error => {
          if (error.response.status === 403) {
            this.setState({ isOpenCantFollowErrorWindow: true })
          } else {
            throw error
          }
        })
    } else unfollowUser(auth.user.id, user.id).then(data => setShowUser(data))
  }

  handleStoriesSearch(event) {
    const { searchUserStories, user } = this.props
    searchUserStories(user.id, event.target.value, false)
  }

  handleNextStoriesSearch() {
    const { loadNextSearchUserStories, searchStories } = this.props
    loadNextSearchUserStories(searchStories.pagination.next_url, false)
  }

  render() {
    const {
      user,
      stories,
      userLoading,
      storiesLoading,
      pagination,
      searchStories,
    } = this.props
    const { user_id } = this.props.params

    const user_ = user || {}

    if (user_.is_blacklisted_current_user) {
      return <UserBlacklistedCurrentUserPageContent username={user_.username} />
    }

    const thisUserIsBlacklistedByCurrentUser = user_.is_blacklisted
    const status = user_.followed_by_me_status || 'none'

    let buttonTitle
    if (status === 'none') {
      buttonTitle = 'FOLLOW'
    } else if (status === 'approved') {
      buttonTitle = 'UNFOLLOW'
    } else {
      buttonTitle = status.toUpperCase()
    }
    if (thisUserIsBlacklistedByCurrentUser) {
      buttonTitle = 'UNBLOCK'
    }

    const contentLoading = userLoading || storiesLoading || isEmpty(user)

    if (contentLoading) {
      return <ContentLoading />
    }

    let userPageProps = {
      followingsLink:    `/users/${user_id}/followings`,
      followersLink:     `/users/${user_id}/followers`,
      pagination:        pagination,
      isLoading:         false,
      loadNextData:      this.loadNextData,
      user:              user,
      actionsURL:        `/users/${user_id}/stories`,
      linkTo:            `/users/${user_id}`,
      buttonTitle:       buttonTitle,
      handleButtonClick: this.handleFollowClick,
      stories:           [...stories.values()],
      searchText:        searchStories.text,
      // handleStoriesSearch: this.handleStoriesSearch,
      showUnblock:       thisUserIsBlacklistedByCurrentUser,
    }

    if (searchStories.text) {
      userPageProps.stories = [...searchStories.stories.values()]
      userPageProps.isLoading = searchStories.isLoading
      userPageProps.pagination = searchStories.pagination
      userPageProps.loadNextData = this.handleNextStoriesSearch
    }

    return (
      <div>
        <Dialog
          title="Can't follow this user"
          actions={[
            <Button
              color="primary"
              onClick={() =>
                this.setState({ isOpenCantFollowErrorWindow: false })
              }
            >
              Cancel
            </Button>,
          ]}
          modal={false}
          open={this.state.isOpenCantFollowErrorWindow}
          onClose={() => this.setState({ isOpenCantFollowErrorWindow: false })}
        >
          {'This user have blacklisted you. You can\'t follow this user.'}
        </Dialog>

        <UserPage {...userPageProps} />
        <div className="btn-archive">
          <Link
            to={`/users/${user_id}/stories/nuuz_archive`}
            onClick={e => setArchiveCurrentPosition(null)}
          >
            <Button variant="contained" color="primary">
              Archive
            </Button>
          </Link>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    auth:                    state.auth,
    isAuthenticated:         state.auth.isAuthenticated,
    userLoading:             state.showUser.isLoading,
    storiesLoading:          state.showUserStories.isLoading,
    user:                    state.showUser.user,
    stories:                 state.showUserStories.stories,
    storiesSuccess:          state.showUserStories.success,
    pagination:              state.showUserStories.pagination,
    currentShowUserPosition: state.showUserStories.currentPosition,
    searchStories:           state.search.otherUserStories,
  }
}

export default connect(mapStateToProps, {
  getShowUser,
  getShowUserStories,
  setShowUser,
  setShowUserStories,
  loadNextUserStories,
  showUserInitial,
  showUserStoriesInitial,
  followUser,
  unfollowUser,
  setShowUserCurrentPosition,
  searchUserStories,
  loadNextSearchUserStories,
  setSearchUserStoriesInitial,
  setArchiveCurrentPosition,
  setActiveItem,
  unblockUser,
})(ShowUserPage)

/**
 * Created by boris on 3/17/17.
 */

import axiosClient from '../axiosClient'

import {
  MAIN_URL,
  SET_READING_LIST_ITEM,
  SET_REQUEST_SENT,
} from '../actions/constants'

export function setRequestSent() {
  return {
    type: SET_REQUEST_SENT,
  }
}

export function setReadingListItem(item) {
  return {
    type: SET_READING_LIST_ITEM,
    item,
  }
}

export function submitAddReadingList(data) {
  return dispatch => {
    return axiosClient
      .post(MAIN_URL + '/reading_list', data)
      .then(response => response && dispatch(setReadingListItem(response.data)))
  }
}

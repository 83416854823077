/**
 * Created by boris on 4/27/17.
 */
import axiosClient from '../axiosClient'

import { MAIN_URL } from '../actions/constants'

export function resetPasswordInitial() {
  return {
    type: 'RESET_PASSWORD_INITIAL',
  }
}
export function resetPassword(data) {
  return dispatch => {
    dispatch({ type: 'RESET_PASSWORD_LOADING' })
    return axiosClient
      .post(`${MAIN_URL}/users/reset_password`, data)
      .then(response => {
        dispatch(resetPasswordInitial())
        return response
      })
      .catch(error => {
        dispatch(resetPasswordInitial())
        throw error
      })
  }
}

import subscribe from '../lib/subscribe'

import handleCurrentUserIdChange from '../../utils/handleCurrentUserIdChange'
import { REALTIME_USER_UPDATED } from '../../actions/constants'

let subscription = null

handleCurrentUserIdChange({
  onUserLogin: currentUserId => {
    // listen
    subscription = subscribe(
      { channel: 'UserUpdatedChannel' },
      REALTIME_USER_UPDATED
    )
  },
  onUserLogout: lastUserId => {
    // unlisten
    subscription.complete()

    subscription = null
  },
})

/**
 * Created by boris on 4/9/17.
 */
import React from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import TagsStoryItem from './TagsStoryItem'
import Typography from '@material-ui/core/Typography'
import ContentLoading from '../ContentLoading'

class TagsStoryItemsList extends React.Component {
  render() {
    const { currentSearchedTag, stories, subheader, loadNextData } = this.props

    console.log('stories', stories, [...stories.stories.values()])

    const storiesList = [...stories.stories.values()].map(story => (
      <TagsStoryItem
        key={story.id}
        story={story}
        currentSearchedTag={currentSearchedTag}
      />
    ))

    console.log('storiesList', storiesList)

    return (
      <div className="users-list-container">
        <Typography>{subheader}</Typography>
        <InfiniteScroll
          next={loadNextData}
          hasMore={stories.pagination && Boolean(stories.pagination.next_url)}
          loader={' '}
          scrollThreshold={0.5}
          endMessage=" "
          dataLength={storiesList.length}
          style={{ overflow: 'none' }}
        >
          <ReactCSSTransitionGroup
            transitionName="add-delete-story"
            transitionAppearTimeout={700}
            transitionEnterTimeout={700}
            transitionLeaveTimeout={500}
          >
            {storiesList}
          </ReactCSSTransitionGroup>
          {stories.pagination && stories.pagination.next_url ? (
            <ContentLoading small={true} list={true} />
          ) : null}
        </InfiniteScroll>
      </div>
    )
  }
}

export default TagsStoryItemsList

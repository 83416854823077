/**
 * Created by borysyudin on 14.02.17.
 */

import React, { Component } from 'react'
import Box from '@material-ui/core/Box'

import '../../css/Page.css'
import { Link } from '@material-ui/core'

export default class StoryBody extends Component {
  render() {
    const { page } = this.props
    return (
      <div className="page">
        <Link href={page.link} rel="noopener noreferrer" _target="_blank">
          <Box className="page-image">
            <img src={page.logo} alt="" />
          </Box>
        </Link>
        <Box fontWeight="fontWeightBold" className="title">
          <Link href={page.link} rel="noopener noreferrer" _target="_blank">
            {page.title}
          </Link>
        </Box>
        <Box fontSize={13} className="site">
          <Link href={page.link} rel="noopener noreferrer" _target="_blank">
            {page.site}
          </Link>
        </Box>
      </div>
    )
  }
}

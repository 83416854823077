/**
 * Created by borysyudin on 16.02.17.
 */
import React, { Component } from 'react'
import classnames from 'classnames'
import { connect } from 'react-redux'

import moment from 'moment'

import { likeComment, unlikeComment } from '../actions/CommentsActions'
import '../css/CommentActivities.css'
import likeIcon from '../img/like-icon.png'
import likeSelectedIcon from '../img/like-selected-icon.png'

class CommentActivities extends Component {
  constructor(props) {
    super(props)
    this.likeClick = this.likeClick.bind(this)
  }

  getAmount(amount) {
    return amount < 1000 ? amount : Math.floor(amount / 1000) + 'k'
  }

  getCreatinInfo(date) {
    let SEC_IN_DAY = 86399 // Seconds in day - 1 second
    if (Math.abs(moment().diff(date)) < SEC_IN_DAY * 1000) {
      // 1000 milliseconds
      return moment(date).fromNow()
    }
    return `${date.getDate()} ${date.toLocaleString('en-us', {
      month: 'short',
    })} ${date.getFullYear()}`
  }

  likeClick(event) {
    const { comment, likeComment, unlikeComment } = this.props

    if (comment.liked) unlikeComment(comment.story_id, comment.id)
    else likeComment(comment.story_id, comment.id)
  }

  render() {
    const {
      comment,
      handleReplyClick,
      isAuthenticated,
      hideActions,
    } = this.props
    const { likes, liked } = comment
    const dot = String.fromCharCode(183)
    let commentActivities = (
      <div className="comment-activities">
        <span className="created-info">
          {this.getCreatinInfo(new Date(comment.created_at))}
        </span>
        {hideActions ? null : (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span className="comment-dot">{dot}</span>

            <img
              className={classnames('activity', 'like', 'comment-like', {
                liked: liked,
              })}
              src={liked ? likeSelectedIcon : likeIcon}
              alt="Like Icon"
              onClick={this.likeClick}
            />
          </div>
        )}
        <span className="comment-dot">{dot}</span>
        <span className="created-info">{likes + ' likes '}</span>
        {hideActions ? null : (
          <div>
            <span className="comment-dot">{dot}</span>
            <span className="reply-comment" onClick={handleReplyClick}>
              {'reply'}
            </span>
          </div>
        )}
      </div>
    )
    if (!isAuthenticated) {
      commentActivities = (
        <div className="comment-activities">
          <span className="created-info">
            {this.getCreatinInfo(comment.created_at)}
            <span className="activities-dot">{dot}</span>
          </span>
          <span className="created-info">{' ' + likes + ' likes '}</span>
        </div>
      )
    }
    return commentActivities
  }
}

function mapStateToProps(state) {
  return {
    isAuthenticated: state.auth.isAuthenticated,
  }
}

export default connect(mapStateToProps, { likeComment, unlikeComment })(
  CommentActivities
)

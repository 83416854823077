/**
 * Created by boris on 3/28/17.
 */
import axiosClient from '../axiosClient'
import {
  MAIN_URL,
  SET_BLACKLIST,
  INITIAL_BLACKLIST,
  SET_NEXT_BLACKLIST,
} from '../actions/constants'

export function initialBlacklist() {
  return {
    type: INITIAL_BLACKLIST,
  }
}

export function setBlacklist(data) {
  return {
    type: SET_BLACKLIST,
    data,
  }
}
export function loadBlacklist() {
  return dispatch =>
    axiosClient
      .get(`${MAIN_URL}/blacklisted_users`)
      .then(response => dispatch(setBlacklist(response.data)))
}

export function setNextBlacklist(data) {
  return {
    type: SET_NEXT_BLACKLIST,
    data,
  }
}

export function loadNextBlacklist(url) {
  return dispatch =>
    axiosClient
      .get(url)
      .then(response => dispatch(setNextBlacklist(response.data)))
}

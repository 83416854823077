/**
 * Created by boris on 3/6/17.
 */
/**
 * Created by boris on 2/27/17.
 */
/**
 * Created by boris on 2/27/17.
 */
import React from 'react'
import { Link } from 'react-router'
import { connect } from 'react-redux'
import isEmpty from 'lodash/isEmpty'
import validator from 'validator'

import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'

import { checkEmail } from '../../actions/SignUpActions'
import { getSignupToken } from '../../actions/AuthActions'
import { BAD_REQUEST, UNAUTHORIZED } from '../../actions/constants'
import classnames from 'classnames'

import '../../css/SignUp/EmailForm.css'
import { Card, CardActions } from '@material-ui/core'

class EmailForm extends React.Component {
  constructor(props) {
    super(props)
    const { email } = this.props
    this.state = {
      email:     email || '',
      errors:    {},
      isLoading: false,
      isValid:   true,
    }

    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleChange = this.handleChange.bind(this)
  }

  isValid() {
    let errors = {}
    let { email } = this.state
    if (isEmpty(email)) errors.email = 'Email is required'
    else if (!validator.isEmail(email.trim())) {
      errors.email = 'Email is not valid'
    }

    return {
      errors,
      isValid: isEmpty(errors),
    }
  }

  handleSubmit(event) {
    event.preventDefault()
    let { errors, isValid } = this.isValid(this.state)
    const { handleNextClick, checkEmail } = this.props
    let { email } = this.state
    email = email.trim()

    if (isValid) {
      this.setState({ errors: {} })
      checkEmail(email)
        .then(response => {
          handleNextClick({ email })
        })
        .catch(err => {
          if (err.response.status === BAD_REQUEST) {
            this.setState({
              errors: { email: err.response.data.error },
            })
          } else if (err.response.status === UNAUTHORIZED) {
            getSignupToken().then(response => {
              this._submitButton.refs.container.refs.enhancedButton.click()
            })
          }
        })
    } else this.setState({ errors })
  }

  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }

  render() {
    let { email, errors, isLoading } = this.state
    return (
      <form onSubmit={this.handleSubmit} className="signup-email-form">
        <TextField
          label="Email"
          name="email"
          type="text"
          value={email}
          className={classnames({
            'invalid-input': errors.email,
          })}
          onChange={this.handleChange}
        />
        {errors.email && (
          <span className="signup-email-error">{errors.email}</span>
        )}
        <div className="submit-button">
          <Typography className="login-link">
            <Link to="/login" className="forgot-password">
              Already have an account?
            </Link>
          </Typography>
          <Card elevation={0}>
            <CardActions>
              <Button
                variant="contained"
                ref={input => {
                  this._submitButton = input
                }}
                disabled={!this.state.isValid || isLoading}
                color="primary"
                onClick={this.handleSubmit}
                className="send-comment"
              >
                Next
              </Button>
            </CardActions>
          </Card>
        </div>
      </form>
    )
  }
}

function mapStateToProps(state) {
  return {
    isAuthenticated: state.auth.isAuthenticated,
  }
}

export default connect(mapStateToProps, { checkEmail })(EmailForm)

/**
 * Created by boris on 3/7/17.
 */
import {
  SET_MY_NUUZ_STORIES,
  MAIN_URL,
  INITIAL_MY_NUUZ,
  SET_MYNUUZ_CURRENT_POSITION,
  SET_TWITTER_IMAGE,
} from './constants'
import axiosClient from '../axiosClient'

export function initialMyNuuz() {
  return {
    type: INITIAL_MY_NUUZ,
  }
}

export function setMyNuuz(data) {
  return {
    type: SET_MY_NUUZ_STORIES,
    data,
  }
}

export function loadMyNuuz() {
  return dispatch =>
    axiosClient
      .get(`${MAIN_URL}/stories/nuuz`)
      .then(response => dispatch(setMyNuuz(response.data)))
}

export function setMyNuuzCurrentPosition(position) {
  return {
    type: SET_MYNUUZ_CURRENT_POSITION,
    position,
  }
}

export function setTwitterImage(data) {
  return {
    type: SET_TWITTER_IMAGE,
    data,
  }
}

export function generateTwitterImage(id) {
  return dispatch =>
    axiosClient
      .post(`${MAIN_URL}/generate_images`, {
        id,
      })
      .then(response => dispatch(setTwitterImage(response.data)))
}

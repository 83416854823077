/**
 * Created by boris on 3/29/17.
 */
import React from 'react'

import InfiniteScroll from 'react-infinite-scroll-component'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import ContentLoading from './ContentLoading'
import { connect } from 'react-redux'
import { followUser, unfollowUser } from '../actions/FollowUnfollowActions'
import { setFollowersListUser } from '../actions/FollowersActions'
import {
  setFollowingsListUser,
  removeFollowingsListUser,
} from '../actions/FollowingsActions'
import { setSearchPeopleListUser } from '../actions/SearchActions'
import { setShowUserCurrentPosition } from '../actions/ShowUserActions'
import UserItem from './UserItem'

import '../css/UsersList.css'

class UsersList extends React.Component {
  handleFollowClick(user) {
    const {
      followUser,
      unfollowUser,
      setFollowersListUser,
      setFollowingsListUser,
      removeFollowingsListUser,
      currentUser,
      followings,
      showUser,
      setSearchPeopleListUser,
    } = this.props

    if (user.followed_by_me_status === 'none') {
      followUser(currentUser.id, user.id).then(data => {
        setFollowersListUser(data)
        setFollowingsListUser(data)
        setSearchPeopleListUser(data)
      })
    } else {
      unfollowUser(currentUser.id, user.id).then(data => {
        setFollowersListUser(data)
        setSearchPeopleListUser(data)
        followings && currentUser.id === showUser.id
          ? removeFollowingsListUser(data.id)
          : setFollowingsListUser(data)
      })
    }
  }

  render() {
    let {
      users,
      loadNextData,
      pagination,
      menuItems,
      currentUser,
      followers,
      followings,
      showUser,
      setShowUserCurrentPosition,
    } = this.props

    let usersList = users.map(user => {
      let items = []
      if (menuItems) items = [...menuItems]
      if (followers || followings) {
        let buttonTitle =
          user.followed_by_me_status === 'none' ? 'Follow' : 'Unfollow'

        if (user.followed_by_me_status === 'pending') {
          buttonTitle = 'Cancel pending'
        }

        items = [
          ...items,
          {
            handler: this.handleFollowClick.bind(this, user),
            label:   buttonTitle,
          },
        ]
      }
      return (
        <UserItem
          setShowUserCurrentPosition={setShowUserCurrentPosition}
          showUser={showUser}
          currentUser={currentUser}
          key={user.id}
          menuItems={items}
          user={user}
        />
      )
    })

    return (
      <div className="users-list-container">
        <InfiniteScroll
          next={loadNextData}
          hasMore={pagination && Boolean(pagination.next_url)}
          loader={' '}
          scrollThreshold={0.5}
          endMessage=" "
          dataLength={usersList.length}
        >
          <ReactCSSTransitionGroup
            transitionName="add-delete-story"
            transitionAppearTimeout={700}
            transitionEnterTimeout={700}
            transitionLeaveTimeout={500}
          >
            {usersList}
          </ReactCSSTransitionGroup>
          {pagination && pagination.next_url ? (
            <ContentLoading small={true} list={true} />
          ) : null}
        </InfiniteScroll>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    currentUser: state.auth.user,
    showUser:    state.showUser.user,
  }
}

export default connect(mapStateToProps, {
  followUser,
  unfollowUser,
  setFollowersListUser,
  setFollowingsListUser,
  removeFollowingsListUser,
  setSearchPeopleListUser,
  setShowUserCurrentPosition,
})(UsersList)

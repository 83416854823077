/**
 * Created by boris on 3/31/17.
 */
import axiosClient from '../axiosClient'
import {
  MAIN_URL,
  SET_FOLLOWINGS_REQUEST_LIST,
  INITIAL_FOLLOWINGS_REQUESTS_LIST,
  SET_NEXT_FOLLOWINGS_REQUESTS_LIST,
  SET_FOLLOWING_REQUEST_DELETE,
} from '../actions/constants'

export function initialFollowingsRequestsList() {
  return {
    type: INITIAL_FOLLOWINGS_REQUESTS_LIST,
  }
}

export function setFollowingsRequestsList(data) {
  return {
    type: SET_FOLLOWINGS_REQUEST_LIST,
    data,
  }
}

export function loadFollowingsRequestsList(user_id) {
  return dispatch =>
    axiosClient
      .get(`${MAIN_URL}/users/${user_id}/followings/requests`)
      .then(response => dispatch(setFollowingsRequestsList(response.data)))
}

export function setNextFollowingsRequestsList(data) {
  return {
    type: SET_NEXT_FOLLOWINGS_REQUESTS_LIST,
    data,
  }
}

export function loadNextFollowingsRequestsList(url) {
  return dispatch => {
    return axiosClient
      .get(url)
      .then(response => dispatch(setNextFollowingsRequestsList(response.data)))
  }
}

export function setFollowingRequestDelete(user_id) {
  return {
    type: SET_FOLLOWING_REQUEST_DELETE,
    user_id,
  }
}

/**
 * Created by borysyudin on 14.02.17.
 */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Story from './Story'
import ContentLoading from '../ContentLoading'
import InfiniteScroll from 'react-infinite-scroll-component'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import { MAIN_URL } from '../../actions/constants'

import '../../css/StoryList.css'

class StoryList extends Component {
  render() {
    const { pagination, loadNextData, moveToArchive, stories } = this.props

    const stories_ = stories.map((story, index) => (
      <Story
        showUnblock={this.props.showUnblock}
        moveToArchive={moveToArchive}
        key={index}
        story={story}
        actionsURL={MAIN_URL + this.props.actionsURL}
        comments={true}
      />
    ))

    const stories__ =
      stories_.length === 0 ? (
        <div className="no-stories">Nothing to display</div>
      ) : (
        <InfiniteScroll
          next={loadNextData}
          hasMore={pagination && Boolean(pagination.next_url)}
          loader={' '}
          scrollThreshold={0.5}
          endMessage=" "
          dataLength={stories_.length}
        >
          <ReactCSSTransitionGroup
            transitionName="add-delete-story"
            transitionAppearTimeout={700}
            transitionEnterTimeout={700}
            transitionLeaveTimeout={500}
          >
            {stories_}
          </ReactCSSTransitionGroup>
          {pagination && pagination.next_url ? (
            <ContentLoading small={true} list={true} />
          ) : null}
        </InfiniteScroll>
      )

    return <div className="page-content">{stories__}</div>
  }
}

StoryList.propTypes = {
  loadNextData: PropTypes.func,
  showUnblock:  PropTypes.bool.isRequired,
}

export default StoryList

/**
 * Created by boris on 2/27/17.
 */

import { main_url, auth_url, public_url } from '../config.js'

export const MAIN_URL = main_url
export const AUTH_URL = auth_url
export const PUBLIC_URL = public_url

// const
export const UNAUTHORIZED = 401
export const BAD_REQUEST = 400
export const FORBIDDEN = 403
export const NOT_FOUND = 404

// Actions
export const SET_CURRENT_USER = 'SET_CURRENT_USER'
export const SET_FEED = 'SET_FEED'
export const SET_SHOW_USER = 'SET_SHOW_USER'
export const SET_SHOW_USER_STORIES = 'SET_SHOW_USER_STORIES'
export const SET_STORY_LIKE = 'SET_STORY_LIKE'
export const SET_STORY_COMMENTS_STORY = 'SET_STORY_COMMENTS_STORY'
export const SET_NEXT_STORY_PAGE_COMMENTS = 'SET_NEXT_STORY_PAGE_COMMENTS'
export const SET_STORY_PAGE_COMMENTS = 'SET_STORY_PAGE_COMMENTS'
export const SET_DELETE_COMMENT = 'SET_DELETE_COMMENT'
export const SET_STORY_COMMENTS_INITIAL = 'SET_STORY_COMMENTS_INITIAL'
export const SET_ADD_COMMENT = 'SET_ADD_COMMENT'
export const SET_UPDATE_COMMENT = 'SET_UPDATE_COMMENT'
export const SET_COMMENT_LIKE = 'SET_COMMENT_LIKE'
export const SET_MY_NUUZ_STORIES = 'SET_MY_NUUZ_STORIES'
export const SET_ADD_STORY_PAGE = 'SET_ADD_STORY_PAGE'
export const SET_REQUEST_SENT = 'SET_REQUEST_SENT'
export const SET_DELETE_STORY = 'SET_DELETE_STORY'
export const SET_EDIT_STORY = 'SET_EDIT_STORY'
export const INITIAL_FEED = 'INITIAL_FEED'
export const SET_ARCHIVE = 'SET_ARCHIVE'
export const INITIAL_ARCHIVE = 'INITIAL_ARCHIVE'
export const SET_NEXT_FEED = 'SET_NEXT_FEED'
export const INITIAL_MY_NUUZ = 'INITIAL_MY_NUUZ'
export const SET_NEXT_ARCHIVE = 'SET_NEXT_ARCHIVE'
export const SET_BLOCK_USER = 'SET_BLOCK_USER'
export const SET_BLACKLIST = 'SET_BLACKLIST'
export const INITIAL_BLACKLIST = 'INITIAL_BLACKLIST'
export const SET_NEXT_BLACKLIST = 'SET_NEXT_BLACKLIST'
export const SET_UNBLOCK_USER = 'SET_UNBLOCK_USER'
export const SET_SHARE_STORY = 'SET_SHARE_STORY'
export const INITIAL_FOLLOWERS_LIST = 'INITIAL_FOLLOWERS_LIST'
export const SET_FOLLOWERS_LIST = 'SET_FOLLOWERS_LIST'
export const SET_NEXT_FOLLOWERS_LIST = 'SET_NEXT_FOLLOWERS_LIST'
export const SET_FOLLOWINGS_LIST = 'SET_FOLLOWINGS_LIST'
export const INITIAL_FOLLOWINGS_LIST = 'INITIAL_FOLLOWINGS_LIST'
export const SET_NEXT_FOLLOWINGS_LIST = 'SET_NEXT_FOLLOWINGS_LIST'
export const SHOW_USER_INITIAL = 'SHOW_USER_INITIAL'
export const SET_NEXT_SHOW_USER_STORIES = 'SET_NEXT_SHOW_USER_STORIES'
export const SHOW_USER_STORIES_INITIAL = 'SHOW_USER_STORIES_INITIAL'
export const SET_FOLLOWINGS_REQUEST_LIST = 'SET_FOLLOWINGS_REQUEST_LIST'
export const INITIAL_FOLLOWINGS_REQUESTS_LIST =
  'INITIAL_FOLLOWINGS_REQUESTS_LIST'
export const SET_NEXT_FOLLOWINGS_REQUESTS_LIST =
  'SET_NEXT_FOLLOWINGS_REQUESTS_LIST'
export const SET_FOLLOWERS_REQUEST_LIST = 'SET_FOLLOWERS_REQUEST_LIST'
export const INITIAL_FOLLOWERS_REQUESTS_LIST = 'INITIAL_FOLLOWERS_REQUESTS_LIST'
export const SET_NEXT_FOLLOWERS_REQUESTS_LIST =
  'SET_NEXT_FOLLOWERS_REQUESTS_LIST'
export const SET_REJECTS = 'SET_REJECTS'
export const INITIAL_REJECTS = 'INITIAL_REJECTS'
export const SET_NEXT_REJECTS = 'SET_NEXT_REJECTS'
export const SET_SEARCH_PEOPLE = 'SET_SEARCH_PEOPLE'
export const SET_SEARCH_PEOPLE_LOADING = 'SET_SEARCH_PEOPLE_LOADING'
export const INITIAL_SEARCH_PEOPLE = 'INITIAL_SEARCH_PEOPLE'
export const SET_NEXT_SEARCH_PEOPLE = 'SET_NEXT_SEARCH_PEOPLE'
export const SET_FOLLOWER_LIST_USER = 'SET_FOLLOWER_LIST_USER'
export const SET_FOLLOWINGS_LIST_USER = 'SET_FOLLOWINGS_LIST_USER'
export const REMOVE_FOLLOWINGS_LIST_USER = 'REMOVE_FOLLOWINGS_LIST_USER'
export const SET_SEARCH_TAG_STORIES = 'SET_SEARCH_TAG_STORIES'
export const SET_SEARCH_TAG_STORIES_LOADING = 'SET_SEARCH_TAG_STORIES_LOADING'
export const SET_NEXT_SEARCH_TAG_STORIES = 'SET_NEXT_SEARCH_TAG_STORIES'
export const INITIAL_SEARCH_TAG_STORIES = 'INITIAL_SEARCH_TAG_STORIES'
export const SET_SEARCH_PEOPLE_LIST_USER = 'SET_SEARCH_PEOPLE_LIST_USER'
export const SET_SEARCH_LOCATION_STORIES = 'SET_SEARCH_LOCATION_STORIES'
export const INITIAL_SEARCH_LOCATION_STORIES = 'INITIAL_SEARCH_LOCATION_STORIES'
export const SET_SEARCH_LOCATION_STORIES_LOADING =
  'SET_SEARCH_LOCATION_STORIES_LOADING'
export const SET_NEXT_SEARCH_LOCATION_STORIES =
  'SET_NEXT_SEARCH_LOCATION_STORIES'
export const SET_SEARCH_TAB = 'SET_SEARCH_TAB'
export const SET_APPROVE_REQUEST = 'SET_APPROVE_REQUEST'
export const SET_REJECT_REQUEST = 'SET_REJECT_REQUEST'
export const SET_STORY_INITIAL = 'SET_STORY_INITIAL'
export const SET_EDIT_STORY_INITIAL = 'SET_EDIT_STORY_INITIAL'
export const SET_DELETE_FOLLOWER = 'SET_DELETE_FOLLOWER'
export const SET_FOLLOWING_REQUEST_DELETE = 'SET_FOLLOWING_REQUEST_DELETE'
export const SET_CURRENT_POSITION = 'SET_CURRENT_POSITION'
export const SET_READING_LIST = 'SET_READING_LIST'
export const SET_READING_LIST_INITIAL = 'SET_READING_LIST_INITIAL'
export const SET_READING_LIST_LOADING = 'SET_READING_LIST_LOADING'
export const SET_READING_LIST_ITEM = 'SET_READING_LIST_ITEM'
export const DELETE_READING_LIST_ITEM = 'DELETE_READING_LIST_ITEM'
export const SET_NEXT_READING_LIST = 'SET_NEXT_READING_LIST'
export const ACTION_LIST_INITIAL = 'ACTION_LIST_INITIAL'
export const SET_ACTIONS_LIST = 'SET_ACTIONS_LIST'
export const ACTION_LIST_LOADING = 'ACTION_LIST_LOADING'
export const SET_PUBLICATIONS_ORDER = 'SET_PUBLICATIONS_ORDER'
export const DELETE_PUBLICATION = 'DELETE_PUBLICATION'
export const SET_SEARCH_FOLLOWERS_LOADING = 'SET_SEARCH_FOLLOWERS_LOADING'
export const SET_SEARCH_FOLLOWINGS_LOADING = 'SET_SEARCH_FOLLOWINGS_LOADING'
export const SIGNUP_RELOAD = 'SIGNUP_RELOAD'
export const SHOW_SIGNUP_FORM = 'SHOW_SIGNUP_FORM'
export const SET_ARCHIVE_CURRENT_POSITION = 'SET_ARCHIVE_CURRENT_POSITION'
export const SET_SHOWUSER_CURRENT_POSITION = 'SET_SHOWUSER_CURRENT_POSITION'
export const SET_MYNUUZ_CURRENT_POSITION = 'SET_MYNUUZ_CURRENT_POSITION'
export const SET_READINGLIST_CURRENT_POSITION =
  'SET_READINGLIST_CURRENT_POSITION'
export const SET_DRAWER_TOGGLE = 'SET_DRAWER_TOGGLE'
export const SET_ACTIVE_ITEM = 'SET_ACTIVE_ITEM'

// Current Search User Stories
export const SET_CURRENT_SEARCH_USER_STORIES = 'SET_CURRENT_SEARCH_USER_STORIES'
export const INITIAL_CURRENT_SEARCH_USER_STORIES =
  'INITIAL_CURRENT_SEARCH_USER_STORIES'
export const SET_CURRENT_SEARCH_USER_STORIES_LOADING =
  'SET_CURRENT_SEARCH_USER_STORIES_LOADING'
export const SET_NEXT_CURRENT_SEARCH_USER_STORIES =
  'SET_NEXT_CURRENT_SEARCH_USER_STORIES'
export const SET_CURRENT_SEARCH_USER_TEXT = 'SET_CURRENT_SEARCH_USER_TEXT'

// Other Search User Stories
export const SET_OTHER_SEARCH_USER_STORIES = 'SET_OTHER_SEARCH_USER_STORIES'
export const INITIAL_OTHER_SEARCH_USER_STORIES =
  'INITIAL_OTHER_SEARCH_USER_STORIES'
export const SET_OTHER_SEARCH_USER_STORIES_LOADING =
  'SET_OTHER_SEARCH_USER_STORIES_LOADING'
export const SET_NEXT_OTHER_SEARCH_USER_STORIES =
  'SET_NEXT_OTHER_SEARCH_USER_STORIES'
export const SET_OTHER_SEARCH_USER_TEXT = 'SET_OTHER_SEARCH_USER_TEXT'
export const SET_OTHER_SEARCH_USER_ID = 'SET_OTHER_SEARCH_USER_ID'

// Activity history
export const SET_ACTIVITY_HISTORY_INITIAL = 'SET_ACTIVITY_HISTORY_INITIAL'
export const SET_ACTIVITY_HISTORY = 'SET_ACTIVITY_HISTORY'
export const SET_ACTIVITY_HISTORY_LOADING = 'SET_ACTIVITY_HISTORY_LOADING'
export const SET_NEXT_ACTIVITY_HISTORY = 'SET_NEXT_ACTIVITY_HISTORY'
export const SET_ACTIVITY_HISTORY_CURRENT_POSITION =
  'SET_ACTIVITY_HISTORY_CURRENT_POSITION'

// Alerts
export const SET_ALERTS_INITIAL = 'SET_ALERTS_INITIAL'
export const SET_ALERTS = 'SET_ALERTS'
export const SET_ALERTS_LOADING = 'SET_ALERTS_LOADING'
export const SET_NEXT_ALERTS = 'SET_NEXT_ALERTS'
export const SET_ALERTS_CURRENT_POSITION = 'SET_ALERTS_CURRENT_POSITION'

// Story likes list
export const SET_STORY_LIKES_INITIAL = 'SET_STORY_LIKES_INITIAL'
export const SET_STORY_LIKES = 'SET_STORY_LIKES'

// Comment likes list
export const SET_COMMENT_LIKES_INITIAL = 'SET_COMMENT_LIKES_INITIAL'
export const SET_COMMENT_LIKES = 'SET_COMMENT_LIKES'

export const SET_IMIDIATE_LIKE = 'SET_IMIDIATE_LIKE'
export const SET_IMIDIATE_UNLIKE = 'SET_IMIDIATE_UNLIKE'

// Realtime updates
export const REALTIME_FEED_PAGE_COMMENT_CREATED =
  'REALTIME_FEED_PAGE_COMMENT_CREATED'
export const REALTIME_FEED_PAGE_COMMENT_DELETED =
  'REALTIME_FEED_PAGE_COMMENT_DELETED'
export const REALTIME_FEED_PAGE_COMMENT_LIKED =
  'REALTIME_FEED_PAGE_COMMENT_LIKED'
export const REALTIME_FEED_PAGE_COMMENT_UNLIKED =
  'REALTIME_FEED_PAGE_COMMENT_UNLIKED'
export const REALTIME_FEED_PAGE_STORY_LIKED = 'REALTIME_FEED_PAGE_STORY_LIKED'
export const REALTIME_FEED_PAGE_STORY_UNLIKED =
  'REALTIME_FEED_PAGE_STORY_UNLIKED'

export const REALTIME_MY_NUUZ_PAGE_COMMENT_CREATED =
  'REALTIME_MY_NUUZ_PAGE_COMMENT_CREATED'
export const REALTIME_MY_NUUZ_PAGE_COMMENT_DELETED =
  'REALTIME_MY_NUUZ_PAGE_COMMENT_DELETED'
export const REALTIME_MY_NUUZ_PAGE_COMMENT_LIKED =
  'REALTIME_MY_NUUZ_PAGE_COMMENT_LIKED'
export const REALTIME_MY_NUUZ_PAGE_COMMENT_UNLIKED =
  'REALTIME_MY_NUUZ_PAGE_COMMENT_UNLIKED'
export const REALTIME_MY_NUUZ_PAGE_STORY_LIKED =
  'REALTIME_MY_NUUZ_PAGE_STORY_LIKED'
export const REALTIME_MY_NUUZ_PAGE_STORY_UNLIKED =
  'REALTIME_MY_NUUZ_PAGE_STORY_UNLIKED'

export const REALTIME_STORY_SHOW_PAGE_COMMENT_CREATED =
  'REALTIME_STORY_SHOW_PAGE_COMMENT_CREATED'
export const REALTIME_STORY_SHOW_PAGE_COMMENT_DELETED =
  'REALTIME_STORY_SHOW_PAGE_COMMENT_DELETED'
export const REALTIME_STORY_SHOW_PAGE_COMMENT_LIKED =
  'REALTIME_STORY_SHOW_PAGE_COMMENT_LIKED'
export const REALTIME_STORY_SHOW_PAGE_COMMENT_UNLIKED =
  'REALTIME_STORY_SHOW_PAGE_COMMENT_UNLIKED'
export const REALTIME_STORY_SHOW_PAGE_STORY_LIKED =
  'REALTIME_STORY_SHOW_PAGE_STORY_LIKED'
export const REALTIME_STORY_SHOW_PAGE_STORY_UNLIKED =
  'REALTIME_STORY_SHOW_PAGE_STORY_UNLIKED'

export const REALTIME_USER_UPDATED = 'REALTIME_USER_UPDATED'
export const SET_TWITTER_IMAGE = 'SET_TWITTER_IMAGE'
export const REPORT_MESSAGES = {
  spam:       'Unwanted commercial content or spam',
  sexually:   'Pornography or sexually explicit material',
  child:      'Child abuse',
  hate:       'Hate speech or graphic violence',
  harassment: 'Harassment or bullying',
}

import {
  SET_ACTIVITY_HISTORY_INITIAL,
  SET_ACTIVITY_HISTORY_LOADING,
  SET_ACTIVITY_HISTORY,
  SET_NEXT_ACTIVITY_HISTORY,
  SET_ACTIVITY_HISTORY_CURRENT_POSITION,
} from '../actions/constants'

const initialState = {
  isLoading:       false,
  activities:      new Map(),
  pagination:      {},
  currentPosition: null,
}

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_ACTIVITY_HISTORY_INITIAL: {
      return Object.assign({}, initialState, {
        activities: new Map(),
        pagination: {},
      })
    }

    case SET_ACTIVITY_HISTORY_LOADING: {
      return Object.assign({}, initialState, {
        activities: new Map(),
        pagination: {},
        isLoading:  true,
      })
    }

    case SET_ACTIVITY_HISTORY: {
      const newState = Object.assign({}, initialState, {
        activities: new Map(),
        pagination: {},
      })

      if (action.data) {
        const { data, pagination } = action.data
        data.forEach(activity => newState.activities.set(activity.id, activity))
        newState.pagination = Object.assign({}, pagination)
      }

      return newState
    }

    case SET_NEXT_ACTIVITY_HISTORY: {
      let newState = Object.assign({}, state, {
        activities: new Map(state.activities),
        pagination: Object.assign({}, state.pagination),
      })

      if (action.data) {
        const { data, pagination } = action.data
        data.forEach(activity => newState.activities.set(activity.id, activity))
        newState.pagination = Object.assign({}, pagination)
      }

      return newState
    }

    case SET_ACTIVITY_HISTORY_CURRENT_POSITION: {
      return Object.assign({}, state, {
        activities:      new Map(state.activities),
        pagination:      Object.assign({}, state.pagination),
        currentPosition: action.position,
      })
    }

    default:
      return state
  }
}

/**
 * Created by borysyudin on 16.02.17.
 */
import React, { Component } from 'react'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import InfiniteScroll from 'react-infinite-scroll-component'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import { connect } from 'react-redux'
import { Link } from 'react-router'
import scrollToComponent from 'react-scroll-to-component'
import _ from 'lodash'

import Comment from './Comment'
import AddComment from './AddComment'
import ContentLoading from './ContentLoading'
import CommentLikesList from './StoryCommentsPage/CommentLikesList'
import {
  loadNextComments,
  loadComments,
  addComment,
  updateComment,
} from '../actions/CommentsActions'
import { searchPeople } from '../actions/SearchActions'

import '../css/CommentsList.css'

function getCommentIdScrollTo() {
  const match = window.location.hash.match(/comment_id=(\d+)/)
  if (!match) return null
  const commentIdScrollTo = match[1]
  if (!commentIdScrollTo) return null
  return commentIdScrollTo
}

class CommentsList extends Component {
  constructor(props) {
    super(props)

    this.handleAddComment = this.handleAddComment.bind(this)
    this.loadNextData = this.loadNextData.bind(this)

    this.commentRefs = {}
    this.scrollToComponentIdOnNextLoad = getCommentIdScrollTo()
  }

  componentDidMount() {
    const { story_id, loadComments, isAuthenticated, searchPeople } = this.props

    loadComments(story_id, !isAuthenticated).then(() =>
      this.scrollToComponent()
    )
    searchPeople('')
  }

  scrollToComponent() {
    const componentId = this.scrollToComponentIdOnNextLoad
    if (!componentId) return

    const commentScrollTo = this.commentRefs[componentId]

    if (commentScrollTo) {
      this.scrollToComponentIdOnNextLoad = null
      scrollToComponent(commentScrollTo)
    } else {
      this.loadNextData() // and scroll if needed
    }
  }

  handleAddComment(text, parentId = null) {
    const { story_id, addComment } = this.props
    const data = {
      text:      text,
      parent_id: parentId,
    }
    addComment(story_id, data)
  }

  loadNextData() {
    const { loadNextComments, storyComments } = this.props
    const nextUrl = _.get(storyComments, 'pagination.next_url')

    loadNextComments(nextUrl).then(() => this.scrollToComponent())
  }

  render() {
    const {
      storyComments,
      isAuthenticated,
      allUsers,
      commentLikes,
      commentLikesLoading,
      opennedCommentLikes,
      handleLoadCommentLikes,
      currentComment,
    } = this.props

    let comments = null
    let commentLikesList = null

    let addComment = (
      <AddComment
        addComment={this.handleAddComment}
        hint="Comment..."
        initialValue=""
        setFocus={false}
        parent_id={null}
        allUsers={allUsers}
      />
    )
    if (!isAuthenticated) {
      addComment = (
        <Typography className="unauth-add-comment">
          Please,{' '}
          <Link to="/login" className="login-link">
            login
          </Link>{' '}
          to add comment.
        </Typography>
      )
    }

    if (commentLikes && opennedCommentLikes) {
      commentLikesList = (
        <CommentLikesList
          commentLikesLoading={commentLikesLoading}
          commentLikes={commentLikes}
          currentComment={currentComment}
          handleAddComment={this.handleAddComment}
          handleDeleteComment={this.handleDeleteComment}
          handleLoadCommentLikes={handleLoadCommentLikes}
        />
      )
    } else if (storyComments) {
      const { data, pagination } = storyComments

      let commentList = [...data.values()]

      comments = commentList.map(comment => (
        <Comment
          ref={comp => {
            this.commentRefs[comment.id] = comp
          }}
          key={comment.id}
          comment={comment}
          initialValue={comment.text}
          story_id={this.props.story_id}
          handleAddComment={this.handleAddComment}
          handleDeleteComment={this.handleDeleteComment}
          handleLoadCommentLikes={handleLoadCommentLikes}
        />
      ))

      const commentsEmpty = comments.length === 0

      comments = commentsEmpty ? (
        <div className="no-comments">No comments</div>
      ) : (
        <InfiniteScroll
          next={this.loadNextData}
          hasMore={Boolean(pagination.next_url)}
          loader={' '}
          scrollThreshold={0.5}
          endMessage=" "
          dataLength={comments.length}
        >
          <ReactCSSTransitionGroup
            transitionName="add-delete-comment"
            transitionEnterTimeout={700}
            transitionAppearTimeout={700}
            transitionLeaveTimeout={500}
          >
            {comments}
          </ReactCSSTransitionGroup>
          {pagination.next_url ? (
            <ContentLoading small={true} list={true} />
          ) : null}
        </InfiniteScroll>
      )
    }

    let commentsContent = (
      <ReactCSSTransitionGroup
        transitionName="add-delete-comment"
        transitionAppearTimeout={700}
        transitionEnterTimeout={700}
        transitionLeaveTimeout={500}
      >
        {addComment}
        {comments}
      </ReactCSSTransitionGroup>
    )

    return (
      <Paper elevation={3} className="comments-container">
        {commentLikesList ||
          (storyComments ? commentsContent : <ContentLoading small={true} />)}
      </Paper>
    )
  }
}

function mapStateToProps(state) {
  return {
    storyComments:       state.storyCommentsComments.comments,
    isAuthenticated:     state.auth.isAuthenticated,
    allUsers:            state.search.people.users,
    commentLikesLoading: state.commentLikes.isLoading,
    commentLikes:        state.commentLikes.likes,
  }
}

export default connect(mapStateToProps, {
  loadComments,
  addComment,
  updateComment,
  loadNextComments,
  searchPeople,
})(CommentsList)

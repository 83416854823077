/**
 * Created by boris on 4/9/17.
 */
import React from 'react'
import Avatar from '@material-ui/core/Avatar'
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
import { Link } from 'react-router'
import { setShowUserCurrentPosition } from '../../actions/ShowUserActions'
import { connect } from 'react-redux'
import textExcerpt from './lib/textExcerpt'

class TagsStoryItem extends React.Component {
  render() {
    const { currentSearchedTag, story, setShowUserCurrentPosition } = this.props
    const { user } = story
    return (
      <div className="user-item story-item">
        <div className="user-item-content">
          <Link
            to={`/users/${user.id}`}
            className="user-info-avatar"
            onClick={e => setShowUserCurrentPosition(null)}
          >
            <Avatar
              src={user.avatar.medium}
              style={{ width: '45px', height: '45px' }}
            />
          </Link>
          <Link
            to={`/users/${user.id}/stories/${story.id}`}
            className="user-info-name"
          >
            <div className="container">
              <Typography className="user-item-info story-item-info">
                <span className="story-item-excerpt story-item-title">
                  {story.page.title}
                </span>
              </Typography>
              <Typography className="user-item-info story-item-info">
                <span className="story-item-excerpt story-item-description">
                  {textExcerpt(story.text, currentSearchedTag)}
                </span>
              </Typography>
            </div>
          </Link>
          <Link to={`/users/${user.id}/stories/${story.id}`}>
            <div className="story-item-image">
              <img src={story.page.logo} alt="" />
            </div>
          </Link>
        </div>
        <Divider inset="true" className="story-item-divider" />
      </div>
    )
  }
}

export default connect(null, { setShowUserCurrentPosition })(TagsStoryItem)

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import UserPageInfo from '../UserPageInfo'
import StoryList from './StoryList'
import TextField from '@material-ui/core/TextField'

import ContentLoading from '../ContentLoading'

import '../../css/UserPage.css'
import { Box } from '@material-ui/core'

class UserPage extends Component {
  render() {
    const {
      buttonTitle,
      linkTo,
      user,
      handleButtonClick,
      stories,
      actionsURL,
      loadNextData,
      isLoading,
      pagination,
      moveToArchive,
      followersLink,
      followingsLink,
      handleStoriesSearch,
      searchText,
    } = this.props

    return (
      <div className="page-content">
        <Box margin={1.25}>
          <Paper elevation={5} className="user-page">
            <UserPageInfo
              followingsLink={followingsLink}
              followersLink={followersLink}
              buttonTitle={buttonTitle}
              handleButtonClick={handleButtonClick}
              linkTo={linkTo}
              user={user}
            />
          </Paper>
        </Box>

        {handleStoriesSearch ? (
          <TextField
            placeholder="Search..."
            name="storySearchText"
            className="search-page-searchfield"
            onChange={e => handleStoriesSearch(e)}
            value={searchText}
          />
        ) : (
          <div />
        )}

        {isLoading ? (
          <ContentLoading />
        ) : user.can_see_user_info === false ? (
          <div className="no-stories">
            This account is private. Stories are available only for approved
            followers.
          </div>
        ) : (
          <StoryList
            showUnblock={this.props.showUnblock}
            moveToArchive={moveToArchive}
            pagination={pagination}
            loadNextData={loadNextData}
            stories={stories}
            actionsURL={actionsURL}
            archived={true}
          />
        )}
      </div>
    )
  }
}

UserPage.propTypes = {
  editProfile:       PropTypes.object,
  editPassword:      PropTypes.object,
  buttonTitle:       PropTypes.string.isRequired,
  linkTo:            PropTypes.string.isRequired,
  user:              PropTypes.object.isRequired,
  handleButtonClick: PropTypes.func.isRequired,
  stories:           PropTypes.array.isRequired,
  actionsURL:        PropTypes.string.isRequired,
  loadNextData:      PropTypes.func,
  showUnblock:       PropTypes.bool.isRequired,
}

export default UserPage

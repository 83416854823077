export default function mk_following_users_that_liked_this_story_(
  following_users_that_liked_this_story
) {
  if (following_users_that_liked_this_story.length === 0) {
    return []
  }

  const following_users_that_liked_this_story_ = []

  if (following_users_that_liked_this_story.length === 1) {
    following_users_that_liked_this_story_.push({
      type: 'user',
      user: following_users_that_liked_this_story[0],
    })

    following_users_that_liked_this_story_.push({
      type: 'text',
      text: ' liked this',
    })
  } else if (following_users_that_liked_this_story.length === 2) {
    following_users_that_liked_this_story_.push({
      type: 'user',
      user: following_users_that_liked_this_story[0],
    })

    following_users_that_liked_this_story_.push({
      type: 'text',
      text: ' ',
    })

    following_users_that_liked_this_story_.push({
      type: 'user',
      user: following_users_that_liked_this_story[1],
    })

    following_users_that_liked_this_story_.push({
      type: 'text',
      text: ' liked this',
    })
  } else {
    following_users_that_liked_this_story_.push({
      type: 'user',
      user: following_users_that_liked_this_story[0],
    })

    following_users_that_liked_this_story_.push({
      type: 'text',
      text: ' ',
    })

    following_users_that_liked_this_story_.push({
      type: 'user',
      user: following_users_that_liked_this_story[1],
    })

    following_users_that_liked_this_story_.push({
      type: 'text',
      text: ' and others liked this',
    })
  }

  return following_users_that_liked_this_story_
}

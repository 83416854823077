/**
 * Created by borysyudin on 14.02.17.
 */

import React, { Component } from 'react'
import Box from '@material-ui/core/Box'
import TextTruncate from './TextTruncate'
import TextEnhancedWithLinks from './TextEnhancedWithLinks'

import '../css/Page.css'
import { Link } from '@material-ui/core'

export default class Page extends Component {
  render() {
    const { page, text, mentioned_active_usernames } = this.props

    return (
      <div className="page">
        <div className="text">
          <TextTruncate lines={3} moreText="More" lessText="Show less">
            <TextEnhancedWithLinks
              text={text}
              mentioned_active_usernames={mentioned_active_usernames}
            />
          </TextTruncate>
        </div>
        <Link href={page.link} rel="noopener noreferrer" _target="_blank">
          <div className="page-image">
            <img src={page.logo} alt="" />
          </div>
        </Link>
        <Box fontWeight="fontWeightBold" className="title">
          <Link href={page.link} rel="noopener noreferrer" _target="_blank">
            {page.title}
          </Link>
        </Box>
        <Box fontSize={13} className="site">
          <Link href={page.link} rel="noopener noreferrer" _target="_blank">
            {page.site}
          </Link>
        </Box>
      </div>
    )
  }
}

/**
 * Created by boris on 3/17/17.
 */
import React, { Component } from 'react'
import TextField from '@material-ui/core/TextField'
import { addReadingListValidation } from '../../utils/Validations'
import { connect } from 'react-redux'
import { submitAddReadingList } from '../../actions/AddReadingListActions'
import Button from '@material-ui/core/Button'
import classnames from 'classnames'
import isEmpty from 'lodash/isEmpty'
import { setActiveItem } from '../../actions/NavbarActions'
import textToError from '../../utils/textToError'

import { browserHistory } from 'react-router'

class AddReadingList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      errors:        {},
      isValid:       false,
      submitRequest: false,
    }
    this.handleInput = this.handleInput.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleInput(event) {
    const { value, name } = event.target
    this.setState({ [name]: value }, () => {
      const { isValid, errors } = addReadingListValidation(this.state)
      this.setState({ isValid, errors })
    })
  }

  handleSubmit() {
    const { link } = this.state
    const { submitAddReadingList, setActiveItem } = this.props
    const data = {
      url: link,
    }
    this.setState({ submitRequest: true })
    submitAddReadingList(data).then(() => {
      setActiveItem('nav-reading-list')
      browserHistory.push('/reading_list')
    })
  }

  componentWillMount() {
    const { setActiveItem } = this.props
    setActiveItem('nav-reading-list')
  }

  render() {
    const { link, errors, isValid, submitRequest } = this.state
    const { story } = this.props

    let submitButton = (
      <Button
        variant="contained"
        disabled={!story && (!isValid || submitRequest)}
        color="primary"
        onClick={this.handleSubmit}
        className="send-comment edit-profile-submit"
      >
        Add
      </Button>
    )

    return (
      <div className="add-reading-list-container">
        <div className="add-reading-list-text-fields">
          <TextField
            label="Link*"
            name="link"
            value={link}
            onChange={this.handleInput}
            {...textToError(errors.link)}
            disabled={!isEmpty(story)}
            className={classnames({
              'invalid-input': errors.link,
            })}
          />
          <div className="button-container">{submitButton}</div>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    user: state.auth.user,
  }
}

// Prototyped from stories, note to implement everything below
// ..ReadingList.. => ..ReadingList..
export default connect(mapStateToProps, {
  submitAddReadingList,
  setActiveItem,
})(AddReadingList)

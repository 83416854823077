import React, { Component } from 'react'
import PropTypes from 'prop-types'
import StoryList from './StoryList'
import TextField from '@material-ui/core/TextField'

import ContentLoading from './ContentLoading'

import '../css/UserPage.css'

class UserPage extends Component {
  render() {
    const {
      stories,
      actionsURL,
      loadNextData,
      isLoading,
      pagination,
      moveToArchive,
      handleStoriesSearch,
      searchText,
    } = this.props

    return (
      <div className="page-content">
        <div className="nuuz-archive-content__title">Archive</div>

        {handleStoriesSearch ? (
          <TextField
            placeholder="Search..."
            name="storySearchText"
            className="search-page-searchfield"
            onChange={e => handleStoriesSearch(e)}
            value={searchText}
          />
        ) : (
          <div />
        )}

        {isLoading ? (
          <ContentLoading />
        ) : (
          <StoryList
            moveToArchive={moveToArchive}
            pagination={pagination}
            loadNextData={loadNextData}
            stories={stories}
            actionsURL={actionsURL}
            archived={false}
          />
        )}
      </div>
    )
  }
}

UserPage.propTypes = {
  editProfile:       PropTypes.object,
  editPassword:      PropTypes.object,
  buttonTitle:       PropTypes.string.isRequired,
  linkTo:            PropTypes.string.isRequired,
  user:              PropTypes.object.isRequired,
  handleButtonClick: PropTypes.func.isRequired,
  stories:           PropTypes.array.isRequired,
  actionsURL:        PropTypes.string.isRequired,
  loadNextData:      PropTypes.func,
}

export default UserPage

/**
 * Created by boris on 2/27/17.
 */
import { combineReducers } from 'redux'
import auth from './reducers/auth'
import app from './reducers/app'
import feed from './reducers/feed'
import { showUser, showUserStories } from './reducers/showUser'
import storyCommentsComments from './reducers/storyComments/comments'
import storyCommentsStory from './reducers/storyComments/story'
import commentLikes from './reducers/storyComments/commentLikes'
import addStory from './reducers/addStory'
import storyLikes from './reducers/storyLikes'
import editStory from './reducers/editStory'
import nuuzArchive from './reducers/nuuzArchive'
import myNuuz from './reducers/myNuuz'
import blacklist from './reducers/blacklist'
import followers from './reducers/followers'
import followings from './reducers/followings'
import followingsRequests from './reducers/followingsRequests'
import followersRequests from './reducers/followersRequests'
import rejects from './reducers/rejects'
import search from './reducers/search'
import readingList from './reducers/readingList'
import publications from './reducers/publications'
import signup from './reducers/signup'
import resetPassword from './reducers/resetPassword'
import navbar from './reducers/navbar'
import activityHistory from './reducers/activityHistory'
import alerts from './reducers/alerts'

export default combineReducers({
  auth,
  app,
  feed,
  showUser,
  showUserStories,
  storyCommentsStory,
  storyCommentsComments,
  storyLikes,
  commentLikes,
  myNuuz,
  addStory,
  editStory,
  nuuzArchive,
  blacklist,
  followers,
  followings,
  followingsRequests,
  followersRequests,
  rejects,
  search,
  readingList,
  publications,
  signup,
  resetPassword,
  navbar,
  activityHistory,
  alerts,
})

/**
 * Created by boris on 3/27/17.
 */
import axiosClient from '../axiosClient'

import {
  SET_ARCHIVE,
  INITIAL_ARCHIVE,
  SET_NEXT_ARCHIVE,
  MAIN_URL,
  SET_ARCHIVE_CURRENT_POSITION,
} from '../actions/constants'

export function initialArchive() {
  return {
    type: INITIAL_ARCHIVE,
  }
}

export function setArchive(data) {
  return {
    type: SET_ARCHIVE,
    data,
  }
}

export function loadArchive(user_id) {
  return dispatch => {
    return axiosClient
      .get(`${MAIN_URL}/users/${user_id}/stories/nuuz_archive`)
      .then(response => {
        dispatch(setArchive(response.data))
      })
  }
}

export function setNextArchive(data) {
  return {
    type: SET_NEXT_ARCHIVE,
    data,
  }
}

export function loadNextArchive(url) {
  return dispatch => {
    return axiosClient.get(url).then(response => {
      dispatch(setNextArchive(response.data))
    })
  }
}

export function setArchiveCurrentPosition(position) {
  return {
    type: SET_ARCHIVE_CURRENT_POSITION,
    position,
  }
}

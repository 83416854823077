/**
 * Created by rudenskyi on 27/1/21.
 */
import {
  SET_COMMENT_LIKES_INITIAL,
  SET_COMMENT_LIKES,
} from '../../actions/constants'

const likesInitialState = {
  isLoading: true,
  likes:     null,
}

export default function commentLikes(state = likesInitialState, action) {
  switch (action.type) {
    case SET_COMMENT_LIKES_INITIAL: {
      return {
        isLoading: true,
        likes:     null,
      }
    }

    case SET_COMMENT_LIKES: {
      const { data } = action.commentLikes

      const newState = {
        isLoading: false,
        likes:     data,
      }

      return newState
    }
    default:
      return state
  }
}

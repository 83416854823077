/**
 * Created by boris on 3/28/17.
 */
import {
  SET_BLACKLIST,
  INITIAL_BLACKLIST,
  SET_NEXT_BLACKLIST,
  SET_UNBLOCK_USER,
} from '../actions/constants'

const initialState = {
  isLoading:  true,
  users:      new Map(),
  pagination: {},
}

export default function(state = initialState, action) {
  switch (action.type) {
    case INITIAL_BLACKLIST: {
      return {
        isLoading:  true,
        users:      new Map(),
        pagination: {},
      }
    }

    case SET_BLACKLIST: {
      let newState = {
        isLoading:  false,
        users:      new Map(),
        pagination: {},
      }
      if (action.data) {
        const { data, pagination } = action.data
        data.forEach(user => newState.users.set(user.id, user))
        newState.pagination = Object.assign({}, pagination)
      }

      return newState
    }

    case SET_NEXT_BLACKLIST: {
      let newState = Object.assign({}, state, {
        users:      new Map(state.users),
        pagination: Object.assign({}, state.pagination),
      })
      if (action.data) {
        const { data, pagination } = action.data
        data.forEach(user => newState.users.set(user.id, user))
        newState.pagination = Object.assign({}, pagination)
      }

      return newState
    }

    case SET_UNBLOCK_USER: {
      let newState = Object.assign({}, state, {
        users:      new Map(state.users),
        pagination: Object.assign({}, state.pagination),
      })
      newState.users.delete(action.user_id)
      return newState
    }

    default:
      return state
  }
}

/**
 * Created by boris on 3/28/17.
 */

import React from 'react'
import Paper from '@material-ui/core/Paper'
import UserPageInfo from '../UserPageInfo'
import UsersList from '../UsersList'
import ContentLoading from '../ContentLoading'
import { browserHistory } from 'react-router'
import { connect } from 'react-redux'
import {
  loadBlacklist,
  initialBlacklist,
  loadNextBlacklist,
} from '../../actions/BlacklistActions'
import { unblockUser } from '../../actions/ShowUserActions'
import Typography from '@material-ui/core/Typography'

import '../../css/Blacklist/Blacklist.css'
import { Box } from '@material-ui/core'

class BlacklistPage extends React.Component {
  componentDidMount() {
    this.props.loadBlacklist()
  }

  componentWillUnmount() {
    this.props.initialBlacklist()
  }

  loadNextData() {
    const { pagination, loadNextBlacklist } = this.props
    loadNextBlacklist(pagination.next_url)
  }

  unblockUserEvent(id) {
    const { unblockUser } = this.props
    unblockUser(id)
  }

  render() {
    const { blacklist, auth } = this.props
    const { user } = auth
    let { users, pagination, isLoading } = blacklist
    users = [...users.values()]

    return (
      <Box className="page-content page-content-full-height">
        <Paper elevation={1} className="user-page">
          <div>
            <UserPageInfo
              followingsLink={`/users/${user.id}/followings`}
              followersLink={`/users/${user.id}/followers`}
              buttonTitle={'MY NUUZ'}
              handleButtonClick={() => browserHistory.push(`/users/${user.id}`)}
              linkTo={`/users/${user.id}`}
              user={user}
            />
            <div className="blacklist-content">
              <div className="follows-list-top">
                <Typography className="subheader-20">Blacklist</Typography>
              </div>
              {isLoading ? (
                <ContentLoading small={true} />
              ) : (
                <div className="blacklist-container">
                  {users.length === 0 ? (
                    <Typography className="no-stories">
                      No users to display
                    </Typography>
                  ) : (
                    <UsersList
                      menuItems={[
                        {
                          handler: this.unblockUserEvent.bind(this),
                          label:   'Unblock user',
                        },
                      ]}
                      users={users}
                      loadNextData={this.loadNextData.bind(this)}
                      pagination={pagination}
                    />
                  )}
                </div>
              )}
            </div>
          </div>
        </Paper>
      </Box>
    )
  }
}

function mapStateToProps(state) {
  return {
    auth:      state.auth,
    blacklist: state.blacklist,
  }
}

export default connect(mapStateToProps, {
  loadBlacklist,
  initialBlacklist,
  loadNextBlacklist,
  unblockUser,
})(BlacklistPage)

/**
 * Created by boris on 4/25/17.
 */
import {
  SET_READING_LIST,
  SET_READING_LIST_INITIAL,
  SET_READING_LIST_LOADING,
  SET_READING_LIST_ITEM,
  DELETE_READING_LIST_ITEM,
  SET_NEXT_READING_LIST,
  SET_READINGLIST_CURRENT_POSITION,
  SET_DELETE_STORY,
} from '../actions/constants'

const initialState = {
  isLoading:       false,
  items:           new Map(),
  pagination:      {},
  currentPosition: null,
}

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_READING_LIST_INITIAL: {
      return {
        isLoading:  false,
        items:      new Map(),
        pagination: {},
      }
    }

    case SET_READING_LIST_LOADING: {
      return {
        isLoading:  true,
        items:      new Map(),
        pagination: {},
      }
    }

    case SET_READING_LIST: {
      let newState = {
        isLoading:  false,
        items:      new Map(),
        pagination: {},
      }

      if (action) {
        const { data, pagination } = action.data
        data.forEach(item => newState.items.set(item.item.id, item))
        newState.pagination = Object.assign({}, pagination)
      }

      return newState
    }

    case SET_READING_LIST_ITEM: {
      let newState = Object.assign({}, state, {
        items:      new Map(state.items),
        pagination: Object.assign({}, state.pagination),
      })
      newState.items.set(action.item.item.id, action.item)
      return newState
    }

    case SET_DELETE_STORY: {
      let newState = Object.assign({}, state, {
        items:      new Map(state.items),
        pagination: Object.assign({}, state.pagination),
      })
      newState.items.delete(action.id)
      return newState
    }

    case DELETE_READING_LIST_ITEM: {
      let newState = Object.assign({}, state, {
        items:      new Map(state.items),
        pagination: Object.assign({}, state.pagination),
      })
      newState.items.delete(action.item_id)
      return newState
    }

    case SET_NEXT_READING_LIST: {
      let newState = Object.assign({}, state, {
        items:      new Map(state.items),
        pagination: Object.assign({}, state.pagination),
      })
      if (action.data) {
        const { data, pagination } = action.data
        data.forEach(item => newState.items.set(item.item.id, item))
        newState.pagination = Object.assign({}, pagination)
      }

      return newState
    }

    case SET_READINGLIST_CURRENT_POSITION: {
      let newState = Object.assign({}, state, {
        items:      new Map(state.items),
        pagination: Object.assign({}, state.pagination),
      })
      newState.currentPosition = action.position

      return newState
    }

    default:
      return state
  }
}

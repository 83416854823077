/**
 * Created by boris on 3/7/17.
 */
import {
  SET_MY_NUUZ_STORIES,
  SET_STORY_LIKE,
  SET_DELETE_STORY,
  INITIAL_MY_NUUZ,
  SET_READING_LIST_ITEM,
  DELETE_READING_LIST_ITEM,
  SET_MYNUUZ_CURRENT_POSITION,
  REALTIME_MY_NUUZ_PAGE_COMMENT_CREATED,
  REALTIME_MY_NUUZ_PAGE_COMMENT_DELETED,
  REALTIME_MY_NUUZ_PAGE_COMMENT_LIKED,
  REALTIME_MY_NUUZ_PAGE_COMMENT_UNLIKED,
  REALTIME_MY_NUUZ_PAGE_STORY_LIKED,
  REALTIME_MY_NUUZ_PAGE_STORY_UNLIKED,
} from '../actions/constants'

import updateStoryOnRealtimeUpdate from './lib/updateStoryOnRealtimeUpdate'

const initialState = {
  isLoading:       true,
  stories:         new Map(),
  pagination:      {},
  currentPosition: null,
}

export default function(state = initialState, action) {
  switch (action.type) {
    case INITIAL_MY_NUUZ: {
      return {
        isLoading:       true,
        stories:         new Map(),
        pagination:      {},
        currentPosition: null,
      }
    }

    case SET_MY_NUUZ_STORIES: {
      let newState = {
        isLoading:       false,
        stories:         new Map(),
        pagination:      {},
        currentPosition: null,
      }
      if (action.data) {
        const { data, pagination } = action.data
        data.forEach(story => newState.stories.set(story.id, story))
        newState.pagination = pagination
      }

      return newState
    }

    case SET_DELETE_STORY: {
      let newState = Object.assign({}, state, {
        pagination: Object.assign({}, state.pagination),
      })
      newState.stories = new Map(state.stories)
      newState.stories.delete(action.id)
      return newState
    }

    case SET_STORY_LIKE: {
      let newState = Object.assign({}, state, {
        pagination: Object.assign({}, state.pagination),
      })
      newState.stories = new Map(state.stories)
      newState.stories.set(action.story.id, action.story)
      return newState
    }

    case SET_READING_LIST_ITEM: {
      const { item } = action.item
      let newState = Object.assign({}, state, {
        stories:    new Map(state.stories),
        pagination: Object.assign({}, state.pagination),
      })
      newState.stories.set(item.id, item)

      return newState
    }

    case DELETE_READING_LIST_ITEM: {
      let newState = Object.assign({}, state, {
        stories:    new Map(state.stories),
        pagination: Object.assign({}, state.pagination),
      })
      let story = Object.assign({}, newState.stories.get(action.item_id))
      story.read_later = false
      newState.stories.set(story.id, story)

      return newState
    }

    case SET_MYNUUZ_CURRENT_POSITION: {
      let newState = Object.assign({}, state, {
        stories:    new Map(state.stories),
        pagination: Object.assign({}, state.pagination),
      })
      newState.currentPosition = action.position

      return newState
    }

    case REALTIME_MY_NUUZ_PAGE_COMMENT_CREATED: {
      return updateStoryOnRealtimeUpdate(action, state)
    }
    case REALTIME_MY_NUUZ_PAGE_COMMENT_DELETED: {
      return updateStoryOnRealtimeUpdate(action, state)
    }
    case REALTIME_MY_NUUZ_PAGE_COMMENT_LIKED: {
      return updateStoryOnRealtimeUpdate(action, state)
    }
    case REALTIME_MY_NUUZ_PAGE_COMMENT_UNLIKED: {
      return updateStoryOnRealtimeUpdate(action, state)
    }
    case REALTIME_MY_NUUZ_PAGE_STORY_LIKED: {
      return updateStoryOnRealtimeUpdate(action, state)
    }
    case REALTIME_MY_NUUZ_PAGE_STORY_UNLIKED: {
      return updateStoryOnRealtimeUpdate(action, state)
    }

    default:
      return state
  }
}

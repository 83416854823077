import React, { Component } from 'react'
import PropTypes from 'prop-types'
import FeedPageList from './FeedPageList'
import { connect } from 'react-redux'
import {
  loadFeed,
  setFeed,
  loadNextFeed,
  initialFeed,
  setCurrentFeedPosition,
} from '../actions/FeedActions'
import ContentLoading from './ContentLoading'
import { submitEditStory } from '../actions/EditStoryActions'
import Scroll from 'react-scroll'
import ProfileBlock from './ProfileBlock'
import { setMyNuuzCurrentPosition } from '../actions/MyNuuzActions'
import { setShowUserCurrentPosition } from '../actions/ShowUserActions'
import { browserHistory } from 'react-router'
import Paper from '@material-ui/core/Paper'
import UserPageInfo from './UserPageInfo'
import { setActiveItem } from '../actions/NavbarActions'
import registerFeedPageRealtimeUpdatesEffect from '../realtime-updates/per-page/feedPage'
import { Box } from '@material-ui/core'

class FeedPage extends Component {
  componentWillMount() {
    const { initialFeed, loadFeed, currentPosition } = this.props
    if (currentPosition == null) {
      initialFeed()
      loadFeed()
    }
  }

  componentDidMount() {
    const { currentPosition, auth } = this.props
    if (currentPosition != null) {
      Scroll.animateScroll.scrollTo(currentPosition, { duration: 0 })
    }

    this.feedPageRealtimeUpdatesEffectUnsubscribe = registerFeedPageRealtimeUpdatesEffect()
    if (auth.user.ios_mobile_device) {
      window.location.assign('nuuz://')
    }
  }

  componentWillUnmount() {
    const { setCurrentFeedPosition } = this.props
    setCurrentFeedPosition(window.pageYOffset)

    this.feedPageRealtimeUpdatesEffectUnsubscribe()
  }

  loadNextData() {
    const { pagination, loadNextFeed } = this.props
    loadNextFeed(pagination.next_url)
  }

  moveToArchive(story) {
    const { submitEditStory, loadFeed } = this.props
    const { text, id } = story
    submitEditStory({ rank: '', text }, id).then(loadFeed)
  }

  render() {
    const {
      data,
      pagination,
      isLoading,
      auth,
      setMyNuuzCurrentPosition,
      setActiveItem,
    } = this.props

    const { user } = auth

    if (isLoading) {
      return (
        <div className="feed-page-content">
          <ContentLoading></ContentLoading>
        </div>
      )
    }

    return (
      <div className="feed-page-content">
        <Box margin={1.25}>
          <Paper elevation={5} className="user-page feed-user-page-info">
            <UserPageInfo
              followingsLink={`/users/${user.id}/followings`}
              followersLink={`/users/${user.id}/followers`}
              buttonTitle="MY NUUZ"
              handleButtonClick={() => browserHistory.push(`/users/${user.id}`)}
              linkTo={`/users/${user.id}`}
              user={user}
            />
          </Paper>
        </Box>

        <ProfileBlock
          dataActiveNav="nav-my-nuuz"
          setActiveItem={setActiveItem}
          buttonTitle="MY NUUZ"
          linkTo={`/users/${user.id}`}
          user={user}
          handleButtonClick={() => browserHistory.push(`/users/${user.id}`)}
          isAuthenticated={auth.isAuthenticated}
          followingsLink={`/users/${user.id}/followings`}
          followersLink={`/users/${user.id}/followers`}
          setShowUserCurrentPosition={setShowUserCurrentPosition}
          setMyNuuzCurrentPosition={setMyNuuzCurrentPosition}
        />

        <FeedPageList
          moveToArchive={this.moveToArchive.bind(this)}
          loadNextData={this.loadNextData.bind(this)}
          actionsURL="/stories"
          pagination={pagination}
          data={data}
        />
      </div>
    )
  }
}

FeedPage.propTypes = {
  loadFeed: PropTypes.func.isRequired,
}

function mapStateToProps(state) {
  return {
    isLoading:       state.feed.isLoading,
    data:            state.feed.stories,
    pagination:      state.feed.pagination,
    currentPosition: state.feed.currentPosition,
    auth:            state.auth,
  }
}

export default connect(mapStateToProps, {
  setActiveItem,
  loadFeed,
  setFeed,
  submitEditStory,
  loadNextFeed,
  initialFeed,
  setCurrentFeedPosition,
  setMyNuuzCurrentPosition,
  setShowUserCurrentPosition,
})(FeedPage)

/**
 * Created by boris on 3/17/17.
 */
/**
 * Created by boris on 3/7/17.
 */
import React from 'react'
import Paper from '@material-ui/core/Paper'
// import UserPageInfo from '../UserPageInfo'
import AddReadingList from './AddReadingList'
// import { browserHistory } from 'react-router'
import { connect } from 'react-redux'

import '../../css/AddReadingList/AddReadingList.css'
import { Box } from '@material-ui/core'

class AddReadingListPage extends React.Component {
  render() {
    // const { user } = this.props.auth
    return (
      <Box className="page-content">
        <Paper elevation={5} className="user-page">
          <div>
            <div className="add-reading-list-content">
              <AddReadingList />
            </div>
          </div>
        </Paper>
      </Box>
    )
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
  }
}

export default connect(mapStateToProps)(AddReadingListPage)

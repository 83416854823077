/**
 * Created by boris on 3/1/17.
 */
import { SET_STORY_LIKES_INITIAL, SET_STORY_LIKES } from '../actions/constants'

const likesInitialState = {
  isLoading: true,
  likes:     null,
}

export default function storyLikes(state = likesInitialState, action) {
  switch (action.type) {
    case SET_STORY_LIKES_INITIAL: {
      return {
        isLoading: true,
        likes:     null,
      }
    }

    case SET_STORY_LIKES: {
      const { data } = action.storyLikes

      const newState = {
        isLoading: false,
        likes:     data,
      }

      return newState
    }
    default:
      return state
  }
}

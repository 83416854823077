/**
 * Created by boris on 3/1/17.
 */
import axiosClient from '../axiosClient'
import {
  MAIN_URL,
  SET_SHOW_USER,
  SET_SHOW_USER_STORIES,
  FORBIDDEN,
  SET_BLOCK_USER,
  SET_UNBLOCK_USER,
  SHOW_USER_INITIAL,
  SET_NEXT_SHOW_USER_STORIES,
  SHOW_USER_STORIES_INITIAL,
  SET_SHOWUSER_CURRENT_POSITION,
  NOT_FOUND,
} from '../actions/constants'

export function setShowUser(user) {
  return {
    type: SET_SHOW_USER,
    user,
  }
}

export function getShowUser(user_id) {
  return dispatch => {
    return axiosClient
      .get(`${MAIN_URL}/users/${user_id}`)
      .then(response => {
        dispatch(setShowUser(response.data))
      })
      .catch(err => {
        const { response } = err
        if (response && response.status === FORBIDDEN) dispatch(setShowUser())
        else if (response && response.status === NOT_FOUND) {
          dispatch(setShowUser())
          dispatch({ type: 'SET_NOT_FOUND', notFound: true })
        }
      })
  }
}

export function setShowUserStories(data) {
  return {
    type: SET_SHOW_USER_STORIES,
    data,
  }
}

export function getShowUserStories(user_id) {
  return dispatch => {
    const url = `${MAIN_URL}/users/${user_id}/stories/nuuz`
    return axiosClient
      .get(url)
      .then(response => {
        dispatch(setShowUserStories(response.data))
      })
      .catch(err => {
        const { response } = err
        if (response && response.status === FORBIDDEN) {
          dispatch(setShowUserStories())
        } else if (response && response.status === NOT_FOUND) {
          dispatch(setShowUserStories())
          dispatch({ type: 'SET_NOT_FOUND', notFound: true })
        }
      })
  }
}

export function setNextShowUserStories(data) {
  return {
    type: SET_NEXT_SHOW_USER_STORIES,
    data,
  }
}

export function loadNextUserStories(url) {
  return dispatch => {
    return axiosClient
      .get(url)
      .then(response => {
        dispatch(setNextShowUserStories(response.data))
      })
      .catch(err => {
        if (err.response && err.response.status === FORBIDDEN) {
          dispatch(setShowUserStories())
        }
      })
  }
}

export function setBlockUser(user_id) {
  return {
    type: SET_BLOCK_USER,
    user_id,
  }
}

export function blockUser(user_id) {
  return dispatch =>
    axiosClient
      .post(`${MAIN_URL}/blacklisted_users`, { user_id })
      .then(response => {
        dispatch(setBlockUser(user_id))
        return response.data
      })
}

export function setUnblockBlockUser(user_id) {
  return {
    type: SET_UNBLOCK_USER,
    user_id,
  }
}

export function unblockUser(user_id) {
  return dispatch =>
    axiosClient
      .delete(`${MAIN_URL}/blacklisted_users/${user_id}`)
      .then(response => {
        dispatch(setUnblockBlockUser(user_id))
        return response.data
      })
}

export function showUserInitial() {
  return {
    type: SHOW_USER_INITIAL,
  }
}

export function showUserStoriesInitial() {
  return {
    type: SHOW_USER_STORIES_INITIAL,
  }
}

export function setShowUserCurrentPosition(position) {
  return {
    type: SET_SHOWUSER_CURRENT_POSITION,
    position,
  }
}

import {
  SET_ALERTS_INITIAL,
  SET_ALERTS_LOADING,
  SET_ALERTS,
  SET_NEXT_ALERTS,
  SET_ALERTS_CURRENT_POSITION,
} from '../actions/constants'

const initialState = {
  isLoading:       false,
  alerts:          new Map(),
  pagination:      {},
  currentPosition: null,
}

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_ALERTS_INITIAL: {
      return Object.assign({}, initialState, {
        alerts:     new Map(),
        pagination: {},
      })
    }

    case SET_ALERTS_LOADING: {
      return Object.assign({}, initialState, {
        alerts:     new Map(),
        pagination: {},
        isLoading:  true,
      })
    }

    case SET_ALERTS: {
      const newState = Object.assign({}, initialState, {
        alerts:     new Map(),
        pagination: {},
      })

      if (action.data) {
        const { data, pagination } = action.data
        data.forEach(activity => newState.alerts.set(activity.id, activity))
        newState.pagination = Object.assign({}, pagination)
      }

      return newState
    }

    case SET_NEXT_ALERTS: {
      let newState = Object.assign({}, state, {
        alerts:     new Map(state.alerts),
        pagination: Object.assign({}, state.pagination),
      })

      if (action.data) {
        const { data, pagination } = action.data
        data.forEach(activity => newState.alerts.set(activity.id, activity))
        newState.pagination = Object.assign({}, pagination)
      }

      return newState
    }

    case SET_ALERTS_CURRENT_POSITION: {
      return Object.assign({}, state, {
        alerts:          new Map(state.alerts),
        pagination:      Object.assign({}, state.pagination),
        currentPosition: action.position,
      })
    }

    default:
      return state
  }
}

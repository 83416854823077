import React, { Component } from 'react'
import UserPage from './UserPage'
import { connect } from 'react-redux'
import {
  loadArchive,
  initialArchive,
  loadNextArchive,
  setArchiveCurrentPosition,
} from '../actions/ArchiveActions'

import { setActiveItem } from '../actions/NavbarActions'

import isEmpty from 'lodash/isEmpty'
import ContentLoading from './ContentLoading'

import { followUser, unfollowUser } from '../actions/FollowUnfollowActions'

import {
  getShowUser,
  setShowUser,
  showUserInitial,
} from '../actions/ShowUserActions'

import { submitEditStory } from '../actions/EditStoryActions'
import {
  searchUserStories,
  loadNextSearchUserStories,
} from '../actions/SearchActions'
import Scroll from 'react-scroll'

class NuuzArchive extends Component {
  constructor(props) {
    super(props)

    this.handleFollowClick = this.handleFollowClick.bind(this)
  }

  moveToArchive(story) {
    const { submitEditStory, loadArchive } = this.props
    const { user_id } = this.props.params
    const { text, id } = story
    submitEditStory({ rank: '', text }, id).then(response =>
      loadArchive(user_id)
    )
  }

  handleStoriesSearch(event) {
    const { searchUserStories } = this.props
    const { user_id } = this.props.params
    searchUserStories(user_id, event.target.value)
  }

  handleNextStoriesSearch() {
    const { loadNextSearchUserStories, searchStories } = this.props
    loadNextSearchUserStories(searchStories.pagination.next_url)
  }

  componentWillMount() {
    const { initialArchive, loadArchive, getShowUser } = this.props

    const { user_id } = this.props.params

    initialArchive()
    loadArchive(user_id)
    showUserInitial()
    getShowUser(user_id)
  }

  componentDidMount() {
    const { archiveCurrentPosition, setActiveItem } = this.props
    if (archiveCurrentPosition != null) {
      Scroll.animateScroll.scrollTo(archiveCurrentPosition, { duration: 0 })
    }
    setActiveItem('nav-search')
  }

  componentWillUnmount() {
    const { setArchiveCurrentPosition } = this.props
    setArchiveCurrentPosition(window.pageYOffset)
  }

  handleFollowClick() {
    const { followUser, unfollowUser, setShowUser, auth, user } = this.props

    if (user.followed_by_me_status === 'none') {
      followUser(auth.user.id, user.id).then(data => setShowUser(data))
    } else unfollowUser(auth.user.id, user.id).then(data => setShowUser(data))
  }

  loadNextData() {
    const { pagination, loadNextArchive } = this.props
    loadNextArchive(pagination.next_url)
  }

  render() {
    const { stories, isLoading, pagination, searchStories } = this.props
    const { user } = this.props

    const status = (user && user.followed_by_me_status) || 'none'

    let buttonTitle
    if (status === 'none') {
      buttonTitle = 'FOLLOW'
    } else if (status === 'approved') {
      buttonTitle = 'UNFOLLOW'
    } else {
      buttonTitle = status.toUpperCase()
    }

    const contentLoading = isLoading || isEmpty(user)

    if (contentLoading) {
      return <ContentLoading />
    }

    let userPageProps = {
      followingsLink:      `/users/${user.id}/followings`,
      followersLink:       `/users/${user.id}/followers`,
      loadNextData:        this.loadNextData.bind(this),
      pagination:          pagination,
      moveToArchive:       this.moveToArchive.bind(this),
      isLoading:           isLoading,
      user:                user,
      actionsURL:          `/users/${user.id}/stories`,
      linkTo:              `/users/${user.id}`,
      buttonTitle:         buttonTitle,
      handleButtonClick:   this.handleFollowClick,
      stories:             [...stories.values()],
      searchText:          searchStories.text,
      handleStoriesSearch: this.handleStoriesSearch.bind(this),
    }

    if (searchStories.text) {
      userPageProps.stories = [...searchStories.stories.values()]
      userPageProps.isLoading = searchStories.isLoading
      userPageProps.pagination = searchStories.pagination
      user.loadNextData = this.handleNextStoriesSearch.bind(this)
    }

    return <UserPage {...userPageProps} />
  }
}

function mapStateToProps(state) {
  return {
    isLoading:              state.nuuzArchive.isLoading,
    auth:                   state.auth,
    stories:                state.nuuzArchive.stories,
    pagination:             state.nuuzArchive.pagination,
    archiveCurrentPosition: state.nuuzArchive.currentPosition,
    searchStories:          state.search.currentUserStories,

    user:        state.showUser.user,
    userLoading: state.showUser.isLoading,
  }
}

export default connect(mapStateToProps, {
  loadArchive,
  initialArchive,
  submitEditStory,
  loadNextArchive,
  setArchiveCurrentPosition,
  searchUserStories,
  loadNextSearchUserStories,
  getShowUser,
  setShowUser,
  showUserInitial,
  followUser,
  unfollowUser,
  setActiveItem,
})(NuuzArchive)

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router'

import mk_following_users_comments_ from './mk_following_users_comments_'
import mk_following_users_that_liked_this_story_ from './mk_following_users_that_liked_this_story_'

import '../../css/FeedPage/LastFollowingUserCommented.css'

class LastFollowingUserCommented extends Component {
  render() {
    const {
      following_users_that_liked_this_story,
      following_users_comments,
    } = this.props

    const following_users_comments_ = mk_following_users_comments_(
      following_users_comments
    )
    const following_users_that_liked_this_story_ = mk_following_users_that_liked_this_story_(
      following_users_that_liked_this_story
    )

    if (
      following_users_comments_.length === 0 &&
      following_users_that_liked_this_story_.length === 0
    ) {
      return null
    }

    const users = []

    const following_users_comments__ = following_users_comments_.map(
      (data, index) => {
        if (data.type === 'user') {
          const { username } = data.user

          if (!users.includes(username)) {
            users.push(username)
            return (
              <Link
                to={`/users/${username}`}
                className="feed_page__last_following_user_commented__username"
                key={index}
              >
                @{username}
              </Link>
            )
          }
        } else if (data.type === 'text') {
          return <span key={index}>{data.text}</span>
        } else {
          throw new Error('unexpected type')
        }
      }
    )

    const following_users_that_liked_this_story__ = following_users_that_liked_this_story_.map(
      (data, index) => {
        if (data.type === 'user') {
          const { username } = data.user

          return (
            <Link
              to={`/users/${username}`}
              className="feed_page__last_following_user_commented__username"
              key={index}
            >
              @{username}
            </Link>
          )
        } else if (data.type === 'text') {
          return <span key={index}>{data.text}</span>
        } else {
          throw new Error('unexpected type')
        }
      }
    )

    return (
      <div className="feed_page__last_following_user_commented__container">
        {following_users_comments__.length === 0 ? null : (
          <div>{following_users_comments__}</div>
        )}

        {following_users_that_liked_this_story__.length === 0 ? null : (
          <div>{following_users_that_liked_this_story__}</div>
        )}
      </div>
    )
  }
}

LastFollowingUserCommented.propTypes = {
  following_users_that_liked_this_story: PropTypes.array.isRequired,
  following_users_comments:              PropTypes.array.isRequired,
}

export default LastFollowingUserCommented

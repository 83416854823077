/**
 * Created by boris on 3/31/17.
 */
import {
  SET_FOLLOWINGS_REQUEST_LIST,
  INITIAL_FOLLOWINGS_REQUESTS_LIST,
  SET_NEXT_FOLLOWINGS_REQUESTS_LIST,
  SET_FOLLOWING_REQUEST_DELETE,
} from '../actions/constants'

const initialState = {
  isLoading:  true,
  users:      new Map(),
  pagination: {},
}

export default function(state = initialState, action) {
  switch (action.type) {
    case INITIAL_FOLLOWINGS_REQUESTS_LIST: {
      return {
        isLoading:  true,
        users:      new Map(),
        pagination: {},
      }
    }

    case SET_FOLLOWING_REQUEST_DELETE: {
      let newState = Object.assign({}, state, {
        users:      new Map(state.users),
        pagination: Object.assign({}, state.pagination),
      })
      newState.users.delete(action.user_id)
      return newState
    }

    case SET_FOLLOWINGS_REQUEST_LIST: {
      let newState = {
        isLoading:  false,
        users:      new Map(),
        pagination: {},
      }
      if (action.data) {
        const { data, pagination } = action.data
        data.forEach(user => newState.users.set(user.id, user))
        newState.pagination = Object.assign({}, pagination)
      }
      return newState
    }

    case SET_NEXT_FOLLOWINGS_REQUESTS_LIST: {
      let newState = Object.assign({}, state, {
        users:      new Map(state.users),
        pagination: Object.assign({}, state.pagination),
      })
      if (action.data) {
        const { data, pagination } = action.data
        data.forEach(user => newState.users.set(user.id, user))
        newState.pagination = Object.assign({}, pagination)
      }

      return newState
    }
    default:
      return state
  }
}

/**
 * Created by borysyudin on 14.02.17.
 */
import React, { Component } from 'react'
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined'

import '../css/UserBlacklistedCurrentUserPageContent.css'

class UserBlacklistedCurrentUserPageContent extends Component {
  render() {
    const { username } = this.props

    return (
      <div className="page-content page-content-full-height">
        <div className="user_blacklisted_current_user_page_content__root">
          <ErrorOutlineOutlinedIcon
            color="error"
            className="user_blacklisted_current_user_page_content__icon"
          />
          <div className="user_blacklisted_current_user_page_content__header">
            Access denied
          </div>
          <div className="user_blacklisted_current_user_page_content__content">
            You are blacklisted by @{username}
          </div>
        </div>
      </div>
    )
  }
}

export default UserBlacklistedCurrentUserPageContent

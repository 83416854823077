import axiosClient from '../axiosClient'

import {
  SET_ACTIVITY_HISTORY_INITIAL,
  SET_ACTIVITY_HISTORY,
  SET_NEXT_ACTIVITY_HISTORY,
  SET_ACTIVITY_HISTORY_LOADING,
  SET_ACTIVITY_HISTORY_CURRENT_POSITION,
  MAIN_URL,
} from './constants'

export function setActivityHistoryInitial() {
  return {
    type: SET_ACTIVITY_HISTORY_INITIAL,
  }
}

export function setActivityHistory(data) {
  return {
    type: SET_ACTIVITY_HISTORY,
    data,
  }
}

export function setActivityHistoryLoading() {
  return {
    type: SET_ACTIVITY_HISTORY_LOADING,
  }
}

export function getActivityHistory() {
  return dispatch => {
    dispatch(setActivityHistoryLoading())
    return axiosClient.get(`${MAIN_URL}/feed/activities`).then(response => {
      dispatch(setActivityHistory(response.data))
    })
  }
}

export function setNextActivityHistory(data) {
  return {
    type: SET_NEXT_ACTIVITY_HISTORY,
    data,
  }
}

export function getNextActivityHistory(url) {
  return dispatch => {
    return axiosClient.get(url).then(response => {
      dispatch(setNextActivityHistory(response.data))
    })
  }
}

export function setActivityHistoryCurrentPosition(position) {
  return {
    type: SET_ACTIVITY_HISTORY_CURRENT_POSITION,
    position,
  }
}

/**
 * Created by boris on 4/9/17.
 */
import React from 'react'

import Typography from '@material-ui/core/Typography'
import InfiniteScroll from 'react-infinite-scroll-component'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'

import AlertsItem from './AlertsItem'
import ContentLoading from '../ContentLoading'

class AlertsList extends React.Component {
  render() {
    const { alerts, subheader, loadNextData } = this.props

    const alertsList = [...alerts.alerts.values()].map(alert => (
      <AlertsItem key={alert.id} alert={alert} />
    ))

    return (
      <div className="users-list-container">
        <Typography>{subheader}</Typography>
        <InfiniteScroll
          next={loadNextData}
          hasMore={alerts.pagination && Boolean(alerts.pagination.next_url)}
          loader={' '}
          scrollThreshold={0.5}
          endMessage=" "
          dataLength={alerts.alerts.size}
        >
          <ReactCSSTransitionGroup
            transitionName="add-delete-story"
            transitionAppearTimeout={700}
            transitionEnterTimeout={700}
            transitionLeaveTimeout={500}
          >
            {alertsList}
          </ReactCSSTransitionGroup>
          {alerts.pagination && alerts.pagination.next_url ? (
            <ContentLoading small={true} list={true} />
          ) : null}
        </InfiniteScroll>
      </div>
    )
  }
}

export default AlertsList

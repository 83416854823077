import React from 'react'
import Typography from '@material-ui/core/Typography'

class TextTruncate extends React.Component {
  defaultEllipsisStyle = {
    position:        'absolute',
    bottom:          0,
    right:           0,
    backgroundColor: 'white',
    paddingRight:    '30px',
  }

  defaultShadowStyle = {
    position:   'absolute',
    bottom:     0,
    right:      30,
    width:      '40px',
    background: 'linear-gradient(to right, transparent, #fff 60%)',
  }

  defaultContentStyle = {
    position: 'relative',
    wordWrap: 'break-word',
  }

  constructor(props) {
    super(props)
    this.state = {
      truncated: false,
      expanded:  false,
    }
  }

  componentDidMount() {
    const {
      props: { lines },
    } = this
    let contentHeight = this.contentHeight()
    let lineHeight = this.contentLineHeight()

    if (contentHeight > lineHeight * lines) {
      this.setState({
        truncated: true,
      })
    }
  }

  contentHeight() {
    return this.text.getBoundingClientRect().height
  }

  contentLineHeight() {
    return parseInt(window.getComputedStyle(this.text)['line-height'], 10)
  }

  toggleExpanded(e) {
    e.preventDefault()
    this.setState(state => ({ expanded: !state.expanded }))
  }

  render() {
    const {
      props: { children, moreText, lessText, lines, ellipsis },
      state: { truncated, expanded },
    } = this

    let contentStyle = { ...this.defaultContentStyle }
    if (truncated && !expanded) {
      contentStyle = {
        ...this.defaultContentStyle,
        height:       `${this.contentLineHeight() * lines}px`,
        marginBottom: '5px',
        overflow:     'hidden',
      }
    }

    return (
      <div
        className="truncate-container"
        ref={element => (this.container = element)}
      >
        <Typography
          className="truncate-text"
          ref={element => (this.text = element)}
          style={contentStyle}
        >
          {children}
          {truncated && !expanded && (
            <span
              style={{
                ...this.defaultShadowStyle,
                height: this.contentLineHeight(),
              }}
            />
          )}
          {truncated && !expanded && (
            <span style={this.defaultEllipsisStyle}>{ellipsis || '...'}</span>
          )}
        </Typography>
        {truncated && (
          <span
            ref={element => (this.detail = element)}
            onClick={e => this.toggleExpanded(e)}
          >
            {expanded ? lessText : moreText}
          </span>
        )}
      </div>
    )
  }
}

export default TextTruncate

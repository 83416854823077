/**
 * Created by boris on 2/27/17.
 */
import currentUserAccessTokenObservable from '../observables/currentUserAccessToken'
import axiosClient from '../axiosClient'

export default function setAuthorizationToken(token) {
  if (token) {
    axiosClient.defaults.headers.common['Authorization'] = `Bearer ${token}`
    currentUserAccessTokenObservable.next(token)
  } else {
    delete axiosClient.defaults.headers.common['Authorization']
    currentUserAccessTokenObservable.next(null)
  }
}

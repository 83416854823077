import React, { Component } from 'react'
import PropTypes from 'prop-types'
import NuuzArchiveContent from './NuuzArchiveContent'
import { connect } from 'react-redux'
import {
  loadArchive,
  initialArchive,
  loadNextArchive,
  setArchiveCurrentPosition,
} from '../actions/ArchiveActions'
import { setActiveItem } from '../actions/NavbarActions'
import { browserHistory } from 'react-router'
import { submitEditStory } from '../actions/EditStoryActions'
import Scroll from 'react-scroll'

class NuuzArchive extends Component {
  moveToArchive(story) {
    const { submitEditStory, loadArchive } = this.props
    const { user_id } = this.props.params
    const { text, id } = story
    submitEditStory({ rank: '', text }, id).then(response =>
      loadArchive(user_id)
    )
  }

  componentWillMount() {
    const { initialArchive, loadArchive, auth } = this.props
    initialArchive()
    loadArchive(auth.user.id)
  }

  componentDidMount() {
    const { archiveCurrentPosition, setActiveItem } = this.props
    if (archiveCurrentPosition != null) {
      Scroll.animateScroll.scrollTo(archiveCurrentPosition, { duration: 0 })
    }
    setActiveItem('nav-my-nuuz')
  }

  componentWillUnmount() {
    const { setArchiveCurrentPosition } = this.props
    setArchiveCurrentPosition(window.pageYOffset)
  }

  loadNextData() {
    const { pagination, loadNextArchive } = this.props
    loadNextArchive(pagination.next_url)
  }

  render() {
    const { stories, isLoading, pagination } = this.props
    const { user } = this.props.auth

    let userPageProps = {
      followingsLink:    `/users/${user.id}/followings`,
      followersLink:     `/users/${user.id}/followers`,
      loadNextData:      this.loadNextData.bind(this),
      pagination:        pagination,
      moveToArchive:     this.moveToArchive.bind(this),
      isLoading:         isLoading,
      user:              user,
      actionsURL:        '/stories',
      linkTo:            `/users/${user.id}`,
      buttonTitle:       'EDIT MY PROFILE',
      handleButtonClick: () => browserHistory.push('/editpassword'),
      stories:           [...stories.values()],
    }

    return <NuuzArchiveContent {...userPageProps} />
  }
}

NuuzArchive.propTypes = {
  auth: PropTypes.object.isRequired,
}

function mapStateToProps(state) {
  return {
    isLoading:              state.nuuzArchive.isLoading,
    auth:                   state.auth,
    stories:                state.nuuzArchive.stories,
    pagination:             state.nuuzArchive.pagination,
    archiveCurrentPosition: state.nuuzArchive.currentPosition,
  }
}

export default connect(mapStateToProps, {
  loadArchive,
  initialArchive,
  submitEditStory,
  loadNextArchive,
  setArchiveCurrentPosition,
  setActiveItem,
})(NuuzArchive)

/**
 * Created by boris on 4/27/17.
 */
import React from 'react'
import classnames from 'classnames'

import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'

import { Link } from 'react-router'

import textToError from '../../utils/textToError'

class ResetPasswordEmail extends React.Component {
  render() {
    const {
      handleEmailNext,
      errors,
      email,
      isLoading,
      handleChange,
      isValid,
    } = this.props

    return (
      <form className="signup-email-form">
        <TextField
          label="Email"
          name="email"
          type="text"
          value={email || ''}
          className={classnames({
            'invalid-input': errors.email,
          })}
          {...textToError(errors.code)}
          helperText={classnames({
            'There\'s no user with that email address in nuuz': errors.email,
          })}
          onChange={handleChange}
        />

        <div className="submit-button">
          <Typography className="login-link">
            <Link to="/register" className="forgot-password">
              Create new account
            </Link>
          </Typography>
          <Button
            variant="contained"
            disabled={isLoading || !isValid}
            color="primary"
            onClick={handleEmailNext}
            className="send-comment"
          >
            Next
          </Button>
        </div>
      </form>
    )
  }
}

export default ResetPasswordEmail

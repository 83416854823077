export default function updateStoryOnRealtimeUpdate(action, state) {
  const { story_id, story_data } = action.payload

  const oldStory = state.stories.get(story_id)

  if (!oldStory) {
    return state
  }

  const newState = Object.assign({}, state, {
    stories:    new Map(state.stories),
    pagination: Object.assign({}, state.pagination),
  })

  const story = Object.assign({}, newState.stories.get(story_id), story_data)

  newState.stories.set(story.id, story)

  return newState
}

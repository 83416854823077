import {
  SET_STORY_COMMENTS_STORY,
  SET_STORY_LIKE,
  SET_STORY_COMMENTS_INITIAL,
  SET_DELETE_STORY,
  SET_BLOCK_USER,
  SET_STORY_INITIAL,
  SET_READING_LIST_ITEM,
  DELETE_READING_LIST_ITEM,
  REALTIME_STORY_SHOW_PAGE_COMMENT_CREATED,
  REALTIME_STORY_SHOW_PAGE_COMMENT_DELETED,
  REALTIME_STORY_SHOW_PAGE_COMMENT_LIKED,
  REALTIME_STORY_SHOW_PAGE_COMMENT_UNLIKED,
  REALTIME_STORY_SHOW_PAGE_STORY_LIKED,
  REALTIME_STORY_SHOW_PAGE_STORY_UNLIKED,
} from '../../actions/constants'
import lodash from 'lodash'

function updateStoryOnRealtimeUpdate(action, state) {
  const { story_id, story_data } = action.payload

  if (state.story.id !== story_id) {
    return state
  }

  const newState = Object.assign({}, state, {
    story: Object.assign({}, story_data),
  })

  return newState
}

const storyInitialState = {
  deletedStory: false,
  blockedUser:  false,
  isLoading:    true,
  story:        {},
}

export default function storyCommentsStory(state = storyInitialState, action) {
  switch (action.type) {
    case SET_STORY_INITIAL: {
      return {
        deletedStory: false,
        blockedUser:  false,
        isLoading:    true,
        story:        {},
      }
    }

    case SET_DELETE_STORY: {
      return {
        deletedStory: true,
        blockedUser:  false,
        isLoading:    false,
        story:        {},
      }
    }

    case SET_BLOCK_USER: {
      return {
        deletedStory: false,
        blockedUser:  true,
        isLoading:    false,
        story:        {},
      }
    }

    case SET_STORY_COMMENTS_INITIAL: {
      return {
        deletedStory: false,
        blockedUser:  false,
        isLoading:    true,
        story:        {},
      }
    }

    case SET_STORY_COMMENTS_STORY: {
      let newState = {
        deletedStory: false,
        blockedUser:  false,
        isLoading:    false,
        story:        {},
      }

      if (action.story) {
        newState.story = Object.assign({}, action.story)
      }

      return newState
    }

    case SET_STORY_LIKE: {
      return {
        deletedStory: false,
        blockedUser:  false,
        isLoading:    false,
        story:        action.story,
      }
    }

    case SET_READING_LIST_ITEM: {
      const { item } = action.item
      let newState = Object.assign({}, state)
      newState.story = Object.assign({}, item)

      return newState
    }

    case DELETE_READING_LIST_ITEM: {
      let newState = Object.assign({}, state, {
        story: Object.assign({}, state.story),
      })
      if (!lodash.isEmpty(state.story)) newState.story.read_later = false

      return newState
    }

    case REALTIME_STORY_SHOW_PAGE_COMMENT_CREATED: {
      return updateStoryOnRealtimeUpdate(action, state)
    }
    case REALTIME_STORY_SHOW_PAGE_COMMENT_DELETED: {
      return updateStoryOnRealtimeUpdate(action, state)
    }
    case REALTIME_STORY_SHOW_PAGE_COMMENT_LIKED: {
      return updateStoryOnRealtimeUpdate(action, state)
    }
    case REALTIME_STORY_SHOW_PAGE_COMMENT_UNLIKED: {
      return updateStoryOnRealtimeUpdate(action, state)
    }
    case REALTIME_STORY_SHOW_PAGE_STORY_LIKED: {
      return updateStoryOnRealtimeUpdate(action, state)
    }
    case REALTIME_STORY_SHOW_PAGE_STORY_UNLIKED: {
      return updateStoryOnRealtimeUpdate(action, state)
    }

    default:
      return state
  }
}

import React, { Component } from 'react'
import { Link } from 'react-router'
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import Avatar from '@material-ui/core/Avatar'
import Typography from '@material-ui/core/Typography'
import '../css/ProfileBlock.css'
import { Box } from '@material-ui/core'

class ProfileBlock extends Component {
  getFullName(user) {
    return user.first_name + ' ' + user.last_name
  }

  handleButtonClick(event) {
    let { handleButtonClick, setActiveItem, dataActiveNav } = this.props
    if (dataActiveNav) setActiveItem(dataActiveNav)
    handleButtonClick(event)
  }

  render() {
    const {
      buttonTitle,
      linkTo,
      user,
      isAuthenticated,
      followersLink,
      followingsLink,
      setShowUserCurrentPosition,
      setMyNuuzCurrentPosition,
    } = this.props

    let userPageButton = (
      <Button
        variant="contained"
        className="edit-profile"
        onClick={this.handleButtonClick.bind(this)}
      >
        {buttonTitle}
      </Button>
    )

    return isAuthenticated ? (
      <div className="main-block">
        <Paper elevation={5}>
          <div className="profile-block">
            <Link to={linkTo} onClick={e => setShowUserCurrentPosition(null)}>
              <Avatar
                src={user.avatar.medium}
                size={150}
                className="prof-avatar"
              />
            </Link>
            <div className="name-block">
              <Link to={linkTo} onClick={e => setShowUserCurrentPosition(null)}>
                <Box fontWeight="fontWeightBold" marginTop={1.25}>
                  {this.getFullName(user)}
                </Box>
              </Link>
              <Box className="user-userName">{'@' + user.username}</Box>
            </div>
            <div className="page-button"> {userPageButton} </div>
            <div className="additional-info">
              <Link
                to={linkTo}
                onClick={() => {
                  setShowUserCurrentPosition(null)
                  setMyNuuzCurrentPosition(null)
                }}
              >
                <Typography>
                  <span className="user-info-numbers">{user.posts_count}</span>
                  posts
                </Typography>
              </Link>
              <Link to={followersLink}>
                <Typography>
                  <span className="user-info-numbers">
                    {user.followers_count}
                  </span>
                  followers
                </Typography>
              </Link>
              <Link to={followingsLink}>
                <Typography>
                  <span className="user-info-numbers">
                    {user.followings_count}
                  </span>
                  following
                </Typography>
              </Link>
            </div>
          </div>
        </Paper>
      </div>
    ) : null
  }
}

export default ProfileBlock

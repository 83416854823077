import axios from 'axios'
import { MAIN_URL, SET_CURRENT_USER } from '../actions/constants'

export function setPassword(user) {
  return {
    type: SET_CURRENT_USER,
    user,
  }
}

export function editPassword(data) {
  return dispatch =>
    axios
      .put(`${MAIN_URL}/users/current/update_password`, data)
      .then(response => dispatch(setPassword(response.data)))
}

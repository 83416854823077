/**
 * Created by boris on 3/24/17.
 */

import {
  SET_ARCHIVE,
  INITIAL_ARCHIVE,
  SET_DELETE_STORY,
  SET_NEXT_ARCHIVE,
  SET_STORY_LIKE,
  SET_READING_LIST_ITEM,
  DELETE_READING_LIST_ITEM,
  SET_ARCHIVE_CURRENT_POSITION,
} from '../actions/constants'

const initialState = {
  isLoading:       true,
  stories:         new Map(),
  pagination:      {},
  currentPosition: null,
}

export default function(state = initialState, action) {
  switch (action.type) {
    case INITIAL_ARCHIVE: {
      return {
        isLoading:       true,
        stories:         new Map(),
        pagination:      {},
        currentPosition: null,
      }
    }

    case SET_ARCHIVE: {
      let newState = {
        isLoading:       false,
        stories:         new Map(),
        pagination:      {},
        currentPosition: null,
      }
      if (action.data) {
        const { data, pagination } = action.data
        data.forEach(story => newState.stories.set(story.id, story))
        newState.pagination = Object.assign({}, pagination)
      }
      return newState
    }

    case SET_NEXT_ARCHIVE: {
      let newState = Object.assign({}, state, {
        stories:    new Map(state.stories),
        pagination: Object.assign({}, state.pagination),
      })
      if (action.data) {
        const { data, pagination } = action.data
        data.forEach(story => newState.stories.set(story.id, story))
        newState.pagination = Object.assign({}, pagination)
      }

      return newState
    }

    case SET_DELETE_STORY: {
      let newState = Object.assign({}, state, {
        pagination: Object.assign({}, state.pagination),
      })
      newState.stories = new Map(state.stories)
      newState.stories.delete(action.id)
      return newState
    }

    case SET_STORY_LIKE: {
      let newState = Object.assign({}, state, {
        pagination: Object.assign({}, state.pagination),
      })
      newState.stories = new Map(state.stories)
      newState.stories.set(action.story.id, action.story)
      return newState
    }

    case SET_READING_LIST_ITEM: {
      const { item } = action.item
      let newState = Object.assign({}, state, {
        stories:    new Map(state.stories),
        pagination: Object.assign({}, state.pagination),
      })
      newState.stories.set(item.id, item)

      return newState
    }

    case DELETE_READING_LIST_ITEM: {
      let newState = Object.assign({}, state, {
        stories:    new Map(state.stories),
        pagination: Object.assign({}, state.pagination),
      })
      let story = Object.assign({}, newState.stories.get(action.item_id))
      story.read_later = false
      newState.stories.set(story.id, story)

      return newState
    }

    case SET_ARCHIVE_CURRENT_POSITION: {
      let newState = Object.assign({}, state, {
        stories:    new Map(state.stories),
        pagination: Object.assign({}, state.pagination),
      })
      newState.currentPosition = action.position

      return newState
    }

    default:
      return state
  }
}

/**
 * Created by boris on 4/25/17.
 */
import axios from 'axios'
import axiosClient from '../axiosClient'
import {
  MAIN_URL,
  SET_ACTIONS_LIST,
  ACTION_LIST_LOADING,
  SET_PUBLICATIONS_ORDER,
  DELETE_PUBLICATION,
  ACTION_LIST_INITIAL,
} from '../actions/constants'

export function setUserPublications(data) {
  return {
    type: SET_ACTIONS_LIST,
    data,
  }
}

export function getUserPublications() {
  return dispatch => {
    dispatch({ type: ACTION_LIST_LOADING })
    axiosClient
      .get(`${MAIN_URL}/publication_sites`)
      .then(
        response => response && dispatch(setUserPublications(response.data))
      )
  }
}

export function setPublicationsOrder(oldPosition, newPosition, id) {
  return dispatch => {
    dispatch({
      type: SET_PUBLICATIONS_ORDER,
      oldPosition,
      newPosition,
    })
    return axiosClient.put(`${MAIN_URL}/publication_sites/${id}`, {
      position: newPosition,
    })
  }
}

export function deletePublication(id) {
  return dispatch => {
    return axiosClient
      .delete(`${MAIN_URL}/publication_sites/${id}`)
      .then(response => dispatch({ type: DELETE_PUBLICATION, id }))
  }
}

const requestsSources = {
  searchPublications: null,
}

export function searchPublications(publication) {
  return dispatch => {
    dispatch({ type: ACTION_LIST_INITIAL })
    dispatch({ type: ACTION_LIST_LOADING })
    requestsSources.searchPublications &&
      requestsSources.searchPublications.cancel()

    if (publication.trim().length !== 0) {
      requestsSources.searchPublications = axios.CancelToken.source()
      const data = {
        params:      { name: publication.trim() },
        cancelToken: requestsSources.searchPublications.token,
      }
      return axiosClient
        .get(`${MAIN_URL}/publication_sites/all`, data)
        .then(
          response => response && dispatch(setUserPublications(response.data))
        )
        .catch(err => {
          console.log(err)
        })
    } else {
      dispatch(getUserPublications())
    }
  }
}

/**
 * Created by borysyudin on 22.02.17.
 */
import React, { Component } from 'react'
import { DropzoneArea } from 'material-ui-dropzone'
import CameraAlt from '@material-ui/icons/CameraAlt'
// import Avatar from '@material-ui/core/Avatar'

import '../css/ImageUpload.css'

export default class ImageUpload extends Component {
  constructor(props) {
    super(props)
    this.state = {
      file: null,
    }
    this.handleImageChange = this.handleImageChange.bind(this)
    this.cancelAvatarSelection = this.cancelAvatarSelection.bind(this)
  }

  handleImageChange(files) {
    const { handleAvatarInput } = this.props
    let reader = new FileReader()
    let file = files[0]
    console.log('file: ', file)
    if (!file) {
      return
    }
    reader.onloadend = () => {
      this.setState({
        file: file,
      })
    }
    reader.readAsDataURL(file)
    handleAvatarInput(file)
  }

  cancelAvatarSelection() {
    const { handleAvatarInput } = this.props
    this.setState({
      file: null,
    })
    handleAvatarInput(null)
  }

  render() {
    return (
      <form onSubmit={this.handleSubmit} className="image-form">
        <DropzoneArea
          filesLimit={1}
          acceptedFiles={['image/*']}
          Icon={CameraAlt}
          previewText=""
          dropzoneText="SELECT AVATAR"
          previewGridProps={{
            container: { spacing: 1, direction: 'column' },
            item:      { xs: 12 },
          }}
          showPreviewsInDropzone="true"
          onChange={this.handleImageChange}
          className="avatar-dropzone"
        ></DropzoneArea>
      </form>
    )
  }
}

import { SET_DRAWER_TOGGLE, SET_ACTIVE_ITEM } from '../actions/constants'

export default function(
  state = { showNav: false, active: 'nav-feed' },
  action
) {
  switch (action.type) {
    case SET_DRAWER_TOGGLE: {
      return Object.assign({}, state, {
        showNav: action.data ? action.data.open : !state.showNav,
      })
    }

    case SET_ACTIVE_ITEM: {
      return Object.assign({}, state, { active: action.active })
    }

    default:
      return state
  }
}

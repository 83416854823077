/**
 * Created by boris on 2/27/17.
 */
import React from 'react'
import PropTypes from 'prop-types'
import { browserHistory, Link } from 'react-router'
import { connect } from 'react-redux'

import { loginValidation } from '../../utils/Validations'

import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'

import { login } from '../../actions/AuthActions'
import { UNAUTHORIZED } from '../../actions/constants'
import textToError from '../../utils/textToError'
import classnames from 'classnames'

import '../../css/Login/LoginForm.css'

class LoginForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      username:  null,
      password:  null,
      errors:    {},
      isLoading: false,
      isValid:   true,
    }
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleChange = this.handleChange.bind(this)
  }

  componentWillUpdate(nextProps) {
    if (nextProps.isAuthenticated) browserHistory.push('/')
  }

  handleSubmit(event) {
    event.preventDefault()
    let { errors, isValid } = loginValidation(this.state, true)

    if (isValid) {
      this.setState({ errors: {}, isLoading: true })
      this.props.login(this.state).catch(err => {
        if (err.response && err.response.status === UNAUTHORIZED) {
          this.setState(prevState => {
            return {
              errors: {
                form: 'Your username/password combination is incorrect',
              },
              isLoading: false,
            }
          })
        }
      })
    } else this.setState({ errors })
  }

  handleChange(event) {
    this.setState(
      {
        [event.target.name]: event.target.value,
      },
      () => {
        let { errors, isValid } = loginValidation(this.state)
        this.setState({ errors, isValid })
      }
    )
  }

  render() {
    let { username, password, errors, isLoading, isValid } = this.state
    return (
      <form onSubmit={this.handleSubmit} className="login-form">
        <TextField
          label="Username"
          name="username"
          type="text"
          value={username || ''}
          {...textToError(errors.username)}
          onChange={this.handleChange}
          className={classnames({
            'invalid-input': errors.username,
          })}
        />
        <TextField
          label="Password"
          name="password"
          type="password"
          value={password || ''}
          {...textToError(errors.password || errors.form)}
          onChange={this.handleChange}
          className={classnames({
            'invalid-input': errors.password,
          })}
        />
        <div className="submit-button">
          <Typography>
            <Link to="/reset_password" className="forgot-password">
              Forgot password?
            </Link>
          </Typography>
          <Button
            variant="contained"
            disabled={isLoading || !isValid}
            color="primary"
            type="submit"
            className="send-comment"
          >
            Login
          </Button>
        </div>
      </form>
    )
  }
}

LoginForm.propTypes = {
  login: PropTypes.func.isRequired,
}

function mapStateToProps(state) {
  return {
    isAuthenticated: state.auth.isAuthenticated,
  }
}

export default connect(mapStateToProps, { login })(LoginForm)

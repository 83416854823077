/**
 * Created by borysyudin on 16.02.17.
 */

import React, { Component } from 'react'
import { connect } from 'react-redux'

import Dialog from '@material-ui/core/Dialog'
import Button from '@material-ui/core/Button'

import UserInfo from './UserInfo'
import CommentActivities from './CommentActivities'
import CommentReplies from './CommentReplies'
import TextField from '@material-ui/core/TextField'
import TextTruncate from './TextTruncate'
import TextEnhancedWithLinks from './TextEnhancedWithLinks'

import {
  deleteComment,
  updateComment,
  reportComment,
  loadCommentLikes,
} from '../actions/CommentsActions'
import FormControlLabel from '@material-ui/core/FormControlLabel'

import '../css/Comment.css'
import {
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Radio,
  RadioGroup,
} from '@material-ui/core'

function CommentText({ text, mentioned_active_usernames }) {
  return (
    <div className="comment-text">
      <TextTruncate lines={3} moreText="More" lessText="Show less">
        <TextEnhancedWithLinks
          text={text}
          mentioned_active_usernames={mentioned_active_usernames}
        />
      </TextTruncate>
    </div>
  )
}

function moveCaretAtEnd(event) {
  const tempValue = event.target.value
  event.target.value = ''
  event.target.value = tempValue
}

class EditableComment extends Component {
  constructor(props) {
    super(props)

    this.state = {
      replyClick:                false,
      openConfirmWindow:         false,
      editTextFieldCurrentValue: null,
      commentBeignEdited:        false,
    }

    this.handleReplyClick = this.handleReplyClick.bind(this)
    this.handleAddComment = this.handleAddComment.bind(this)
    this.handleDeleteEvent = this.handleDeleteEvent.bind(this)
    this.handleCloseDialog = this.handleCloseDialog.bind(this)
    this.handleUserInfoEvent = this.handleUserInfoEvent.bind(this)
    this.handleEditTextFieldInputUpdate = this.handleEditTextFieldInputUpdate.bind(
      this
    )
    this.handleEditSubmit = this.handleEditSubmit.bind(this)
    this.handleEditCancel = this.handleEditCancel.bind(this)
  }

  handleEditTextFieldInputUpdate(event) {
    const value = event.target.value

    this.setState({
      editTextFieldCurrentValue: value,
    })
  }

  async handleEditSubmit(event) {
    event.preventDefault()

    const { story_id, comment, updateComment } = this.props

    console.log(
      'this.state.editTextFieldCurrentValue',
      this.state.editTextFieldCurrentValue
    )
    await updateComment(story_id, comment.id, {
      text: this.state.editTextFieldCurrentValue,
    })

    this.setState(prevState => ({
      commentBeignEdited: false,
    }))
  }

  handleEditCancel(event) {
    event.preventDefault()

    this.setState(prevState => ({
      commentBeignEdited: false,
    }))
  }

  handleReplyClick() {
    this.setState(prevState => ({
      replyClick: !prevState.replyClick,
    }))
  }

  handleAddComment(text, cleanTextField, parentId = null) {
    this.props.handleAddComment(text, cleanTextField, parentId)

    this.setState(prevState => ({
      replyClick: !prevState.replyClick,
    }))
  }

  handleDeleteEvent() {
    const { story_id, comment, deleteComment } = this.props

    this.setState({
      openConfirmWindow: false,
    })

    deleteComment(story_id, comment.id)
  }

  handleUserInfoEvent(eventType) {
    if (eventType === 'DELETE_COMMENT') {
      this.setState({
        openConfirmWindow: true,
      })
    } else if (eventType === 'EDIT_COMMENT') {
      this.setState({
        editTextFieldCurrentValue: this.props.comment.text,
        commentBeignEdited:        true,
      })
    } else if (eventType === 'LOAD_COMMENT_LIKES') {
      const { comment, loadCommentLikes } = this.props

      loadCommentLikes(comment.story_id, comment.id)
      this.props.handleLoadCommentLikes(comment)
    } else {
      throw new Error(`[handleUserInfoEvent] Unknown event ${eventType}`)
    }
  }

  handleCloseDialog() {
    this.setState({
      openConfirmWindow: false,
    })
  }

  render() {
    const { openConfirmWindow, replyClick } = this.state
    const {
      comment,
      hideActions,
      allUsers,
      openedCommentActivities,
    } = this.props

    const actions = [
      <Button key="cancel" color="primary" onClick={this.handleCloseDialog}>
        Cancel
      </Button>,
      <Button key="delete" color="primary" onClick={this.handleDeleteEvent}>
        Delete
      </Button>,
    ]

    return (
      <div className="comment">
        <UserInfo
          storyUser={comment.user}
          handleEvent={this.handleUserInfoEvent}
        />

        {this.state.commentBeignEdited ? (
          <div className="edit-comment-container">
            <TextField
              placeholder="Write your comment here"
              multiline={true}
              name="comment_field"
              value={this.state.editTextFieldCurrentValue}
              onChange={this.handleEditTextFieldInputUpdate}
              className="text-field"
              autoFocus={true}
              onFocus={moveCaretAtEnd}
            />
            <Button
              variant="contained"
              disabled={
                this.state.editTextFieldCurrentValue === this.props.comment.text
              }
              color="primary"
              onClick={this.handleEditSubmit}
              className="update-comment"
            >
              Update
            </Button>
            <Button
              variant="contained"
              disabled={false}
              color="secondary"
              onClick={this.handleEditCancel}
              className="cancel-comment"
            >
              Cancel
            </Button>
          </div>
        ) : (
          <CommentText
            text={comment.text}
            mentioned_active_usernames={comment.mentioned_active_usernames}
          />
        )}

        <CommentActivities
          comment={comment}
          handleReplyClick={this.handleReplyClick}
          hideActions={openedCommentActivities ? true : hideActions}
        />

        <CommentReplies
          comment={comment}
          replyClick={replyClick}
          handleAddComment={this.handleAddComment}
          allUsers={allUsers}
        />

        <Dialog
          modal={false}
          open={openConfirmWindow}
          onClose={this.handleClose}
        >
          <DialogContent>
            <DialogContentText>
              Do you want to delete this comment?
            </DialogContentText>
          </DialogContent>
          <DialogActions>{actions}</DialogActions>
        </Dialog>
      </div>
    )
  }
}

class NonEditableComment extends Component {
  constructor(props) {
    super(props)

    this.state = {
      replyClick:        false,
      openConfirmWindow: false,
      windowType:        'REPORT_COMMENT',
      reportText:        '',
      errors:            {},
    }

    this.reportMessages = {
      spam:       'Unwanted commercial content or spam',
      sexually:   'Pornography or sexually explicit material',
      child:      'Child abuse',
      hate:       'Hate speech or graphic violence',
      harassment: 'Harassment or bullying',
    }

    this.handleAddComment = this.handleAddComment.bind(this)
    this.handleReplyClick = this.handleReplyClick.bind(this)
    this.reportCommentEvent = this.reportCommentEvent.bind(this)
    this.handleCloseDialog = this.handleCloseDialog.bind(this)
    this.handleOpenDialog = this.handleOpenDialog.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleUserInfoEvent = this.handleUserInfoEvent.bind(this)
  }

  handleReplyClick() {
    this.setState(prevState => ({ replyClick: !prevState.replyClick }))
  }

  handleAddComment(text, cleanTextField, parentId = null) {
    this.props.handleAddComment(text, cleanTextField, parentId)
    this.setState(prevState => ({ replyClick: !prevState.replyClick }))
  }

  reportCommentEvent() {
    const { comment, reportComment } = this.props
    const { reportText } = this.state
    this.handleCloseDialog()
    reportComment(comment.id, this.reportMessages[reportText])
  }

  handleOpenDialog(windowType) {
    this.setState({
      openConfirmWindow: true,
      windowType,
    })
  }

  handleCloseDialog() {
    this.setState({
      openConfirmWindow: false,
      windowType:        'REPORT_COMMENT',
      reportText:        '',
    })
  }

  handleChange(event) {
    this.setState({
      reportText: event.target.value,
    })
  }

  handleUserInfoEvent(eventType) {
    if (eventType === 'LOAD_COMMENT_LIKES') {
      const { comment, loadCommentLikes } = this.props

      loadCommentLikes(comment.story_id, comment.id)
      this.props.handleLoadCommentLikes(comment)
    } else {
      throw new Error(`[handleUserInfoEvent] Unknown event ${eventType}`)
    }
  }

  render() {
    const { openConfirmWindow, windowType, reportText, replyClick } = this.state
    const {
      comment,
      hideActions,
      currentUser,
      allUsers,
      openedCommentActivities,
    } = this.props

    const confirmWindows = {
      REPORT_COMMENT: {
        title:        'Report comment',
        label:        'Report',
        eventHandler: this.reportCommentEvent,
      },
    }

    const { title, label, eventHandler } = confirmWindows[windowType]

    const actions = [
      <Button key="cancel" color="primary" onClick={this.handleCloseDialog}>
        Cancel
      </Button>,
      <Button
        key="other"
        disabled={
          windowType === 'REPORT_COMMENT' && reportText.trim().length === 0
        }
        color="primary"
        focusvisible="true"
        onClick={eventHandler}
      >
        {label}
      </Button>,
    ]

    return (
      <div className="comment">
        <UserInfo
          storyUser={comment.user}
          handleEvent={this.handleOpenDialog}
          commentLikesEvent={this.handleUserInfoEvent}
        />

        <CommentText
          text={comment.text}
          mentioned_active_usernames={comment.mentioned_active_usernames}
        />

        {currentUser ? (
          <CommentReplies
            comment={comment}
            replyClick={replyClick}
            handleAddComment={this.handleAddComment}
            allUsers={allUsers}
          />
        ) : (
          ''
        )}
        <CommentActivities
          comment={comment}
          handleReplyClick={this.handleReplyClick}
          hideActions={openedCommentActivities ? true : hideActions}
        />
        <Dialog
          modal="false"
          open={openConfirmWindow}
          onClose={this.handleClose}
        >
          <DialogTitle>{title}</DialogTitle>
          <DialogContent>
            <RadioGroup onChange={this.handleChange}>
              <FormControlLabel
                value="spam"
                control={<Radio />}
                label={this.reportMessages['spam']}
              />
              <FormControlLabel
                value="sexually"
                control={<Radio />}
                label={this.reportMessages['sexually']}
              />
              <FormControlLabel
                value="child"
                control={<Radio />}
                label={this.reportMessages['child']}
              />
              <FormControlLabel
                value="hate"
                control={<Radio />}
                label={this.reportMessages['hate']}
              />
              <FormControlLabel
                value="harassment"
                control={<Radio />}
                label={this.reportMessages['harassment']}
              />
            </RadioGroup>
          </DialogContent>
          <DialogActions>{actions}</DialogActions>
        </Dialog>
      </div>
    )
  }
}

class Comment extends Component {
  render() {
    const {
      story_id,
      comment,
      currentUser,
      hideActions,
      deleteComment,
      handleAddComment,
      updateComment,
      reportComment,
      allUsers,
      loadCommentLikes,
      handleLoadCommentLikes,
      openedCommentActivities,
    } = this.props

    if (currentUser && currentUser.id === comment.user.id) {
      // editable IIF current user is logged in and is the author
      return (
        <EditableComment
          comment={comment}
          deleteComment={deleteComment}
          updateComment={updateComment}
          handleAddComment={handleAddComment}
          story_id={story_id}
          allUsers={allUsers}
          loadCommentLikes={loadCommentLikes}
          handleLoadCommentLikes={handleLoadCommentLikes}
          openedCommentActivities={openedCommentActivities}
        />
      )
    } else {
      // user cannot edit comment(only send report and see likes) or is unauthenticated
      return (
        <NonEditableComment
          comment={comment}
          hideActions={hideActions}
          currentUser={currentUser}
          reportComment={reportComment}
          handleAddComment={handleAddComment}
          allUsers={allUsers}
          loadCommentLikes={loadCommentLikes}
          handleLoadCommentLikes={handleLoadCommentLikes}
          openedCommentActivities={openedCommentActivities}
        />
      )
    }
  }
}

function mapStateToProps(state) {
  return {
    currentUser: state.auth.user,
    allUsers:    state.search.people.users,
  }
}

export default connect(mapStateToProps, {
  deleteComment,
  updateComment,
  reportComment,
  loadCommentLikes,
})(Comment)

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import { connect } from 'react-redux'
import { editPassword } from '../../actions/EditPasswordActions'
import classnames from 'classnames'
import { EditPasswordValidation } from '../../utils/Validations'
import { toggleActivation } from '../../actions/AuthActions'
// import isEmpty from 'lodash/isEmpty'

import Alert from 'react-s-alert'

import { browserHistory } from 'react-router'

import '../../css/EditPassword.css'

class EditPassword extends Component {
  constructor(props) {
    super(props)

    // const user = this.props.auth.user

    this.state = {
      current_password:    '',
      new_password:        '',
      new_password_repeat: '',
      isValid:             false,
      editedData:          {},
      formData:            null,
      submitingData:       false,
      open:                false,
      errors:              {},
    }

    this.handleSubmitEditedData = this.handleSubmitEditedData.bind(this)
    this.handleInput = this.handleInput.bind(this)
  }

  setInitialState() {
    let initialState = Object.assign({}, this.state)

    if (initialState.birth_date) {
      initialState.birth_date = new Date(initialState.birth_date).toISOString()
    }

    this.setState({ initialState })
  }

  componentDidMount() {
    this.setInitialState()
  }

  handleEditedData(name, value) {
    const { initialState } = this.state
    let editedData = Object.assign({}, this.state.editedData)
    if (value !== initialState[name]) editedData[name] = value
    else delete editedData[name]

    return editedData
  }

  handleInput(event) {
    const { value, name } = event.target
    const editedData = this.handleEditedData(name, value.trim())
    this.setState(
      {
        [name]: value,
        editedData,
      },
      this.validateEdit
    )
  }

  validateEdit() {
    const { isValid, errors } = EditPasswordValidation(this.state)
    this.setState({ isValid, errors })
  }

  _objectToFormData(obj, formdata) {
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        formdata.append(key, obj[key]) // Representation Object in form data
      }
    }
    return formdata
  }

  handleSubmitEditedData() {
    const { isValid, errors } = EditPasswordValidation(this.state)
    this.setState({ isValid, errors })

    if (!isValid) {
      return
    }

    let { editedData, formData } = this.state
    const { editPassword } = this.props
    let data = Object.assign({ password: editedData.new_password }, editedData)

    delete data['new_password_repeat']
    delete data['new_password']
    if (formData) data = this._objectToFormData(data, formData)

    editPassword(data)
      .then(response => {
        console.log(response)

        this.setState(
          {
            current_password:    '',
            new_password:        '',
            new_password_repeat: '',
            isValid:             true,
            editedData:          {},
            formData:            null,
            submitingData:       false,
            // open:                false,
            // errors:              {},
          },
          this.setInitialState
        )

        Alert.success('Password successfully edited', {
          position: 'top-right',
          effect:   'slide',
          beep:     false,
          timeout:  3000,
          offset:   100,
        })
      })
      .catch(error => {
        console.log('error.response', error.response)

        this.setState({
          isValid:       true,
          submitingData: false,
        })

        Alert.error('Wrong current password', {
          position: 'top-right',
          effect:   'slide',
          beep:     false,
          timeout:  3000,
          offset:   100,
        })
      })

    this.setState({
      isValid:       false,
      submitingData: true,
    })
  }

  render() {
    const {
      current_password,
      // formData,
      submitingData,
      isValid,
      errors,
      new_password,
      new_password_repeat,
      // editedData,
    } = this.state

    const { user } = this.props.auth

    return (
      <div className="profile-edit-container profile-change-password-container">
        <form onSubmit={e => e.preventDefault()}>
          <div className="profile-edit-textfields">
            <h4>Change password</h4>
            <TextField
              label="Current password"
              name="current_password"
              type="password"
              value={current_password}
              onChange={this.handleInput}
              autoComplete="off"
              className={classnames({
                'invalid-input': errors.current_password,
              })}
              fullWidth
            />
            <TextField
              label="New password (at least 6 characters)"
              name="new_password"
              type="password"
              value={new_password}
              onChange={this.handleInput}
              autoComplete="off"
              className={classnames({
                'invalid-input': errors.new_password,
              })}
              fullWidth
            />
            <TextField
              label="Repeat new password"
              name="new_password_repeat"
              type="password"
              value={new_password_repeat}
              onChange={this.handleInput}
              autoComplete="off"
              className={classnames({
                'invalid-input': errors.new_password_repeat,
              })}
              fullWidth
            />

            <div className="edit-profile-parent-submit-container">
              <div className="edit-profile-submit-container pull-right">
                <Button
                  variant="contained"
                  disabled={submitingData}
                  className="send-comment edit-profile-submit edit-profile-cancel"
                  onClick={() => browserHistory.push(`/users/${user.id}`)}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  disabled={!isValid || submitingData}
                  color="primary"
                  onClick={this.handleSubmitEditedData}
                  className="send-comment edit-profile-submit"
                >
                  Save
                </Button>
              </div>
            </div>
          </div>
        </form>
      </div>
    )
  }
}

EditPassword.propTypes = {
  auth:         PropTypes.object.isRequired,
  editPassword: PropTypes.func.isRequired,
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
  }
}

export default connect(mapStateToProps, {
  editPassword,
  toggleActivation,
})(EditPassword)

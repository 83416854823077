/**
 * Created by borysyudin on 16.02.17.
 */
import React, { Component } from 'react'
import { connect } from 'react-redux'

import { Link } from 'react-router'

import { setCurrentFeedPosition } from '../actions/FeedActions'

import logo from '../img/bottom_icons_logo.png'

import '../css/Footer.css'

class Footer extends Component {
  render() {
    const { setCurrentFeedPosition } = this.props

    return (
      <div className="footer-block">
        <div className="footer-content">
          <div className="footer-logo-block">
            <Link to="/" onClick={e => setCurrentFeedPosition(null)}>
              <img src={logo} alt="Logo" className="footer-logo" />
            </Link>
          </div>
        </div>
      </div>
    )
  }
}

export default connect(null, {
  setCurrentFeedPosition,
})(Footer)

/**
 * Created by borysyudin on 15.02.17.
 */
import React, { Component } from 'react'
import { browserHistory } from 'react-router'
import { connect } from 'react-redux'
import { setEditStory } from '../../actions/EditStoryActions'
import { SET_SHARE_STORY } from '../../actions/constants'
import {
  addToReadingList,
  deleteFromReadingList,
} from '../../actions/ReadingListActions'

import Dialog from '@material-ui/core/Dialog'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'

import activeReadingList from '../../img/bookmark-active.png'
import replyIcon from '../../img/reply-icon.png'

import '../../css/Activities.css'
import {
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core'

class Activities extends Component {
  constructor(props) {
    super(props)

    this.state = {
      openConfirmWindow: false,
    }

    this.handleOpenDialog = this.handleOpenDialog.bind(this)
    this.handleCloseDialog = this.handleCloseDialog.bind(this)
    this.deleteEvent = this.deleteEvent.bind(this)
  }

  getAmount(amount) {
    return amount < 1000 ? amount : amount / 1000 + 'k'
  }

  shareEvent() {
    const { page, setEditStory } = this.props
    setEditStory({ page: page.item }, SET_SHARE_STORY)
    browserHistory.push('/addstory')
  }

  handleOpenDialog() {
    console.log('asdf')
    this.setState({
      openConfirmWindow: true,
    })
  }

  handleCloseDialog() {
    this.setState({
      openConfirmWindow: false,
    })
  }

  deleteEvent() {
    const { page, deleteFromReadingList } = this.props
    this.handleCloseDialog()
    deleteFromReadingList('Page', page.item.id)
  }

  render() {
    const { openConfirmWindow } = this.state

    const actions = [
      <Button color="primary" onClick={this.handleCloseDialog}>
        No
      </Button>,
      <Button color="primary" focusVisible={true} onClick={this.deleteEvent}>
        Yes
      </Button>,
    ]

    const confirmDelete = (
      <Dialog modal={false} open={openConfirmWindow} onClose={this.handleClose}>
        <DialogTitle></DialogTitle>
        <DialogContent>
          <DialogContentText>Remove from reading list?</DialogContentText>
        </DialogContent>
        <DialogActions>{actions}</DialogActions>
      </Dialog>
    )

    const content = (
      <div className="activities">
        <div className="reading_list">
          <img
            src={activeReadingList}
            alt="Reading list"
            onClick={this.handleOpenDialog}
          />
        </div>

        {confirmDelete}

        <Typography className="activities-info">
          <img
            className="activity"
            src={replyIcon}
            alt="Reply Icon"
            onClick={this.shareEvent.bind(this)}
            style={{ margin: '10px 15px' }}
          />
        </Typography>
      </div>
    )

    return content
  }
}

function mapStateToProps(state) {
  return {
    user:            state.auth.user,
    isAuthenticated: state.auth.isAuthenticated,
  }
}

export default connect(mapStateToProps, {
  setEditStory,
  addToReadingList,
  deleteFromReadingList,
})(Activities)

/**
 * Created by boris on 2/27/17.
 */
import {
  MAIN_URL,
  SET_FEED,
  INITIAL_FEED,
  SET_NEXT_FEED,
  SET_CURRENT_POSITION,
} from './constants'

import axiosClient from '../axiosClient'

export function initialFeed() {
  return {
    type: INITIAL_FEED,
  }
}

export function setFeed(data) {
  return {
    type: SET_FEED,
    data,
  }
}

export function loadFeed() {
  return dispatch =>
    axiosClient
      .get(`${MAIN_URL}/feed`)
      .then(response => dispatch(setFeed(response.data)))
}

export function setNextFeed(data) {
  return {
    type: SET_NEXT_FEED,
    data,
  }
}

export function loadNextFeed(url) {
  return dispatch =>
    axiosClient.get(url).then(response => dispatch(setNextFeed(response.data)))
}

export function setCurrentFeedPosition(position) {
  return {
    type: SET_CURRENT_POSITION,
    position,
  }
}

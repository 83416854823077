/**
 * Created by boris on 3/31/17.
 */
import React from 'react'
import Paper from '@material-ui/core/Paper'
import UserPageInfo from '../UserPageInfo'
import ContentLoading from '../ContentLoading'
import { browserHistory } from 'react-router'
import { connect } from 'react-redux'
import {
  loadRejects,
  initialRejects,
  loadNextRejects,
} from '../../actions/RejectsActions'
import { approveRequest } from '../../actions/FollowersRequestsActions'
import { getShowUser, showUserInitial } from '../../actions/ShowUserActions'
import UsersList from '../UsersList'
import Typography from '@material-ui/core/Typography'
import { Box } from '@material-ui/core'

class RejectsPage extends React.Component {
  componentWillMount() {
    const { loadRejects, getShowUser, auth } = this.props
    const { user, isAuthenticated } = auth
    const { user_id } = this.props.params
    if (user_id !== user.id) getShowUser(user_id, !isAuthenticated)
    loadRejects(user_id)
  }

  componentWillUnmount() {
    this.props.showUserInitial()
    this.props.initialRejects()
  }

  loadNextData() {
    const { followings, loadNextRejects } = this.props
    loadNextRejects(followings.pagination.next_url)
  }

  approveRequest(user_id) {
    const { approveRequest, auth, loadRejects } = this.props
    approveRequest(auth.user.id, user_id).then(() => {
      loadRejects(auth.user.id)
    })
  }

  render() {
    const { followings, auth, showUser } = this.props
    let { user } = auth
    const { user_id } = this.props.params
    let { users, pagination, isLoading } = followings
    users = [...users.values()]
    let userPage = (
      <UserPageInfo
        followingsLink={`/users/${user.id}/followings`}
        followersLink={`/users/${user.id}/followers`}
        buttonTitle={'MY NUUZ'}
        handleButtonClick={() => browserHistory.push(`/users/${user.id}`)}
        linkTo={`/users/${user.id}`}
        user={user}
      />
    )

    let showUserPage = null
    if (user_id !== user.id && !showUser.isLoading) {
      showUserPage = (
        <UserPageInfo
          followingsLink={`/users/${showUser.user.id}/followings`}
          followersLink={`/users/${showUser.user.id}/followers`}
          buttonTitle={'MY NUUZ'}
          handleButtonClick={() =>
            browserHistory.push(`/users/${showUser.user.id}`)
          }
          linkTo={`/users/${showUser.user.id}`}
          user={showUser.user}
        />
      )
    }

    return (
      <Box className="page-content page-content-full-height">
        <Paper elevation={5} className="user-page">
          {user_id === user.id && userPage}
          {user_id !== user.id && !isLoading && showUserPage}
          <div className="blacklist-content">
            <div className="follows-list-top">
              <Typography className="subheader-20">Rejected</Typography>
            </div>
            {isLoading ? (
              <ContentLoading small={true} />
            ) : (
              <div className="blacklist-container">
                {users.length === 0 ? (
                  <Typography className="no-stories">
                    No users to display
                  </Typography>
                ) : (
                  <UsersList
                    menuItems={[
                      {
                        handler:     this.approveRequest.bind(this),
                        label:       'Approve',
                        currentUser: true,
                      },
                    ]}
                    users={users}
                    loadNextData={this.loadNextData.bind(this)}
                    pagination={pagination}
                    subheader="Rejected"
                  />
                )}
              </div>
            )}
          </div>
        </Paper>
      </Box>
    )
  }
}

function mapStateToProps(state) {
  return {
    showUser:   state.showUser,
    auth:       state.auth,
    followings: state.rejects,
  }
}

export default connect(mapStateToProps, {
  loadRejects,
  initialRejects,
  loadNextRejects,
  getShowUser,
  showUserInitial,
  approveRequest,
})(RejectsPage)

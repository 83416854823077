/**
 * Created by boris on 2/27/17.
 */
import axiosClient from '../axiosClient'
import { SET_CURRENT_USER, MAIN_URL, AUTH_URL } from '../actions/constants'
import { setShowUser, setShowUserStories } from '../actions/ShowUserActions'
import {
  setStoryCommentsPageInitial,
  setCommentsPageInitial,
} from '../actions/CommentsActions'
import { setSearchInitial } from '../actions/SearchActions'
import { initialBlacklist } from '../actions/BlacklistActions'
import { setEditStoryInitial } from '../actions/EditStoryActions'
import { initialFeed } from '../actions/FeedActions'
import { initialFollowersList } from '../actions/FollowersActions'
import { initialFollowersRequestsList } from '../actions/FollowersRequestsActions'
import { initialFollowingsList } from '../actions/FollowingsActions'
import { initialFollowingsRequestsList } from '../actions/FollowingsRequestsActions'
import { setProfile } from '../actions/EditProfileActions'
import { setActivityHistoryInitial } from '../actions/ActivityHistory'
import { setAlertsInitial } from '../actions/Alerts'
import setAuthorizationToken from '../utils/setAuthorizationToken'

import { initialMyNuuz } from '../actions/MyNuuzActions'
import { initialRejects } from '../actions/RejectsActions'
import { tokens } from '../config'
import { browserHistory } from 'react-router'
import currentUserIdObservable from '../observables/currentUserId'
import currentUserEmailObservable from '../observables/currentUserEmail'

export function getSignupToken() {
  return getRefreshToken().then(response => setTokens(response.data))
}

export function setTokens(data) {
  const { access_token, refresh_token } = data

  if (access_token) {
    localStorage.setItem('access_token', access_token)
  } else {
    localStorage.removeItem('access_token')
  }

  setAuthorizationToken(access_token)

  if (refresh_token) localStorage.setItem('refresh_token', refresh_token)
  else localStorage.removeItem('refresh_token')
}

export function getRefreshToken() {
  let data = Object.assign({}, tokens, {
    grant_type:    'refresh_token',
    refresh_token: localStorage.refresh_token,
  })

  console.log('localStorage.access_token', localStorage.access_token)
  console.log('localStorage.refresh_token', localStorage.refresh_token)

  if (!localStorage.access_token || !localStorage.refresh_token) {
    data = Object.assign({}, tokens, {
      grant_type: 'client_credentials',
      scope:      'registration',
    })
  }

  return axiosClient.post(AUTH_URL + '/oauth/token', data)
}

export function setCurrentUser(user) {
  return {
    type: SET_CURRENT_USER,
    user,
  }
}

export function getCurrentUser() {
  return dispatch =>
    axiosClient.get(`${MAIN_URL}/users/current`).then(response => {
      if (localStorage.access_token && localStorage.refresh_token) {
        dispatch(setCurrentUser(response.data))
        currentUserIdObservable.next(response.data.id)
        currentUserEmailObservable.next(response.data.email)
      }
    })
}

export function login(data) {
  let { username, password, token } = data

  data = Object.assign({}, tokens, {
    grant_type: 'password',
    scope:      'user_all',
  })

  if (token) data['token'] = token
  else {
    username = username.trim()
    data['password'] = password
    if (username.indexOf('@') === -1) data['username'] = username
    else data['email'] = username
  }

  return dispatch => {
    return axiosClient.post(AUTH_URL + '/oauth/token', data).then(response => {
      if (response) {
        setTokens(response.data)
        dispatch(getCurrentUser())

        currentUserIdObservable.next(null)
        currentUserEmailObservable.next(null)
      }
    })
  }
}

export function logout() {
  return dispatch => {
    if (window.FB) {
      window.FB.getLoginStatus(function(response) {
        if (response.status === 'connected') window.FB.logout()
      })
    }
    localStorage.removeItem('access_token')
    localStorage.removeItem('refresh_token')
    setAuthorizationToken(false)
    dispatch(setCurrentUser({}))
    getSignupToken()
    dispatch(setShowUser({}))
    dispatch(setShowUserStories({ data: [], pagination: {} }))
    dispatch(setStoryCommentsPageInitial())
    dispatch(setSearchInitial())
    dispatch(initialBlacklist())
    dispatch(setCommentsPageInitial())
    dispatch(setEditStoryInitial())
    dispatch(initialFeed())
    dispatch(initialFollowersList())
    dispatch(initialFollowersRequestsList())
    dispatch(initialFollowingsList())
    dispatch(initialFollowingsRequestsList())
    dispatch(initialMyNuuz())
    dispatch(initialRejects())
    dispatch(setActivityHistoryInitial())
    dispatch(setAlertsInitial())
    browserHistory.push('/login')
  }
}

export function toggleActivation(id) {
  return dispatch =>
    axiosClient
      .patch(`${MAIN_URL}/users/toggle_activation/${id}`)
      .then(response => {
        response.data.is_active
          ? dispatch(setProfile(response.data))
          : dispatch(logout())
      })
}

import React from 'react'
import { connect } from 'react-redux'

import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'

import ActivityHistoryList from './ActivityHistoryList'
import { setActiveItem } from '../../actions/NavbarActions'
import ContentLoading from '../ContentLoading'
import {
  getActivityHistory,
  getNextActivityHistory,
} from '../../actions/ActivityHistory'

import '../../css/ActivityHistory.css'

class ActivityHistoryPage extends React.Component {
  componentWillMount() {
    const { setActiveItem } = this.props
    setActiveItem('nav-my-nuuz')
  }

  componentDidMount() {
    const { getActivityHistory } = this.props
    getActivityHistory()
  }

  render() {
    const { getNextActivityHistory, activityHistory } = this.props

    if (activityHistory.isLoading) {
      return (
        <div className="page-content">
          <div className="nuuz-archive-content__title">Activity history</div>

          <ContentLoading />
        </div>
      )
    }

    return (
      <Paper elevation={5} className="page-content">
        <div className="nuuz-archive-content__title">Activity history</div>

        {activityHistory.activities.size === 0 ? (
          <Typography className="no-stories">
            No activities to display
          </Typography>
        ) : (
          <Paper elevation={1} className="activity-history-page">
            <ActivityHistoryList
              loadNextData={getNextActivityHistory.bind(
                null,
                activityHistory.pagination.next_url
              )}
              activityHistory={activityHistory}
            />
          </Paper>
        )}
      </Paper>
    )
  }
}

function mapStateToProps(state) {
  return {
    activityHistory: state.activityHistory,
    auth:            state.auth,
  }
}

export default connect(mapStateToProps, {
  getActivityHistory,
  getNextActivityHistory,
  setActiveItem,
})(ActivityHistoryPage)

/**
 * Created by boris on 3/31/17.
 */
import axiosClient from '../axiosClient'
import {
  MAIN_URL,
  SET_FOLLOWERS_LIST,
  INITIAL_FOLLOWERS_LIST,
  SET_NEXT_FOLLOWERS_LIST,
  SET_FOLLOWER_LIST_USER,
} from '../actions/constants'

export function initialFollowersList() {
  return {
    type: INITIAL_FOLLOWERS_LIST,
  }
}

export function setFollowersList(data) {
  return {
    type: SET_FOLLOWERS_LIST,
    data,
  }
}
export function loadFollowersList(user_id) {
  return dispatch =>
    axiosClient
      .get(`${MAIN_URL}/users/${user_id}/followers`)
      .then(response => dispatch(setFollowersList(response.data)))
}

export function setNextFollowersList(data) {
  return {
    type: SET_NEXT_FOLLOWERS_LIST,
    data,
  }
}

export function loadNextFollowersList(url) {
  return dispatch =>
    axiosClient
      .get(url)
      .then(response => dispatch(setNextFollowersList(response.data)))
}

export function setFollowersListUser(user) {
  return {
    type: SET_FOLLOWER_LIST_USER,
    user,
  }
}

import React from 'react'
import ReactDOM from 'react-dom'
import { Router, browserHistory } from 'react-router'
import { Provider } from 'react-redux'
import setAuthorizationToken from './utils/setAuthorizationToken'
import {
  getCurrentUser,
  setCurrentUser,
  getSignupToken,
} from './actions/AuthActions'

import { store } from './Store'
import routes from './routes'
import currentUserIdObservable from './observables/currentUserId'
import currentUserEmailObservable from './observables/currentUserEmail'

// start effects
import './realtime-updates/global/notifications-mark'
import './effects/googleAnalyticsAddEmailToConfig'

import './css/index.css'

if (localStorage.access_token && localStorage.refresh_token) {
  setAuthorizationToken(localStorage.access_token)
  store.dispatch(getCurrentUser())
  currentUserIdObservable.next(null)
  currentUserEmailObservable.next(null)
} else {
  store.dispatch(setCurrentUser())
  getSignupToken()
}

ReactDOM.render(
  <Provider store={store}>
    <Router history={browserHistory} routes={routes} />
  </Provider>,
  document.getElementById('root')
)

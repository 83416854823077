/**
 * Created by boris on 3/7/17.
 */
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { browserHistory } from 'react-router'

export default function(ComposeComponent) {
  class Unauthenticate extends React.Component {
    componentWillMount() {
      const { isAuthenticated } = this.props
      if (isAuthenticated) browserHistory.push('/')
    }

    // componentWillUpdate(nextProps) {
    //     if(nextProps.isAuthenticated)
    //         browserHistory.push('/');
    // }

    render() {
      // const { isAuthenticated } = this.props;
      return <ComposeComponent {...this.props} />
    }
  }

  Unauthenticate.propTypes = {
    isAuthenticated: PropTypes.bool.isRequired,
  }

  function mapStateToProps(state) {
    return {
      isAuthenticated: state.auth.isAuthenticated,
    }
  }

  Unauthenticate.contextTypes = {
    router: PropTypes.object,
  }

  return connect(mapStateToProps)(Unauthenticate)
}

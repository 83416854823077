/**
 * Created by boris on 3/17/17.
 */
import React, { Component } from 'react'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import { addStoryValidation } from '../../utils/Validations'
import { connect } from 'react-redux'
import {
  getAddStoryPage,
  setAddStoryPage,
  submitAddStory,
} from '../../actions/AddStoryActions'
import { submitEditStory, setEditStory } from '../../actions/EditStoryActions'
import Button from '@material-ui/core/Button'
import classnames from 'classnames'
import ContentLoading from '../ContentLoading'
import isEmpty from 'lodash/isEmpty'
import { SET_EDIT_STORY, SET_SHARE_STORY } from '../../actions/constants'
import { setActiveItem } from '../../actions/NavbarActions'
import { setMyNuuzCurrentPosition } from '../../actions/MyNuuzActions'
import CommentInput from './CommentInput'

import Paper from '@material-ui/core/Paper'
import UserInfo from '../UserInfo'
import Page from '../Page'
import Activities from '../Activities'

import { browserHistory } from 'react-router'
import { Select, InputLabel, Typography } from '@material-ui/core'

class AddStory extends Component {
  constructor(props) {
    super(props)
    const { text, rank, page } = this.props.story
    const { actionType } = this.props
    this.state = {
      link:          page ? page.link : '',
      rank:          actionType === SET_SHARE_STORY ? '' : rank || '',
      highlight:     text || '',
      errors:        {},
      isValid:       false,
      submitRequest: false,
      shareLink:     actionType === SET_SHARE_STORY ? page.link : undefined,
    }
    this.handleLinkInput = this.handleLinkInput.bind(this)
    this.handleRankInput = this.handleRankInput.bind(this)
    this.handleCommentInput = this.handleCommentInput.bind(this)
    this.getLinkPage = this.getLinkPage.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleEdit = this.handleEdit.bind(this)
  }

  handleLinkInput(value) {
    this.setState({ link: value }, () => {
      const { isValid, errors } = addStoryValidation(this.state)
      // console.log('addStoryValidation', { isValid, errors })
      this.props.setAddStoryPage()
      this.setState({ isValid, errors })
    })
  }

  handleRankInput(event) {
    const rank_ = event.target.value
    //
    // Callback implemented according to https://pm.nordicresults.com/issues/37167
    this.setState({ rank: rank_ }, () => {
      const { isValid, errors } = addStoryValidation(this.state)
      // console.log('addStoryValidation', { isValid, errors })
      if (!isValid) this.props.setAddStoryPage()
      this.setState({ isValid, errors })
    })
  }

  handleCommentInput(value) {
    this.setState({ highlight: value }, () => {
      const { isValid, errors } = addStoryValidation(this.state)
      // console.log('addStoryValidation', { isValid, errors })
      if (!isValid) this.props.setAddStoryPage()
      this.setState({ isValid, errors })
    })
  }

  handleSubmit() {
    const { rank, highlight } = this.state
    const { page } = this.props.page
    const {
      user,
      submitAddStory,
      setMyNuuzCurrentPosition,
      setActiveItem,
    } = this.props
    const data = {
      page_id: page.id,
      text:    highlight,
      rank,
    }
    this.setState({ submitRequest: true })
    submitAddStory(data).then(() => {
      setMyNuuzCurrentPosition(null)
      setActiveItem('nav-my-nuuz')
      browserHistory.push(`/users/${user.id}`)
    })
  }

  handleEdit() {
    const { rank, highlight } = this.state
    const {
      user,
      submitEditStory,
      setEditStory,
      setMyNuuzCurrentPosition,
      story,
    } = this.props
    this.setState({ submitRequest: true })
    submitEditStory({ rank, text: highlight }, story.id).then(() => {
      setEditStory()
      setMyNuuzCurrentPosition(null)
      browserHistory.push(`/users/${user.id}`)
    })
  }

  getLinkPage(link) {
    const { isValid, errors } = addStoryValidation(this.state)
    const { getAddStoryPage } = this.props
    // `!errors.link` condition is added to keep generating pages on valid link
    // disregarding other validations which on this moment lists only `rank`
    if (isValid || !errors.link) getAddStoryPage(link)
    this.setState({ isValid, errors })
  }

  componentWillUnmount() {
    this.props.setAddStoryPage()
    this.props.setEditStory()
  }

  componentWillMount() {
    const { shareLink } = this.state
    if (shareLink) this.getLinkPage(shareLink)

    const { setActiveItem } = this.props
    setActiveItem('nav-add-story')
  }

  render() {
    const { link, rank, highlight, errors, isValid, submitRequest } = this.state
    const { story, actionType } = this.props
    const { isLoading, page, requestSent } = this.props.page
    const { user } = this.props
    const rates = ['None', 1, 2, 3, 4, 5].map(rate => (
      <MenuItem key={rate} value={rate}>
        {rate || 'None'}
      </MenuItem>
    ))

    let previewMessage = <Typography className="invalid-link" />
    if (requestSent) previewMessage = <ContentLoading small={true} />
    let storyUser = user
    let created_at = page.created_at
    let storyPage = page
    let submitButton = (
      <Button
        variant="contained"
        disabled={!isValid || (!story && (isLoading || submitRequest))}
        color="primary"
        onClick={this.handleSubmit}
        className="send-comment edit-profile-submit"
      >
        Publish
      </Button>
    )
    if (!isEmpty(story)) {
      if (actionType === SET_EDIT_STORY) {
        created_at = story.page.created_at
        storyPage = story.page
        submitButton = (
          <Button
            variant="contained"
            color="primary"
            onClick={this.handleEdit}
            className="send-comment edit-profile-submit"
          >
            Edit story
          </Button>
        )
      }
    }

    return (
      <div className="add-story-container">
        <Paper elevation={0} className="add-story-text-fields">
          <TextField
            label="Link to your nuuz*"
            name="link"
            value={link}
            onChange={event => this.handleLinkInput(event.target.value)}
            error={!!errors.link}
            helperText={!errors.link ? ' ' : errors.link}
            onBlur={() => this.getLinkPage(link)}
            disabled={!isEmpty(story)}
            className={classnames({
              'invalid-input': errors.link,
            })}
          />
          <div className="underline-indent"></div>
          <InputLabel id="select-rank-field-label">Rank</InputLabel>
          <Select
            labelId="select-rank-field-label"
            value={rank || 'None'}
            onChange={this.handleRankInput}
            id={'select-rank-field'}
            selectedMenuItemStyle={{ color: '#6888ad' }}
          >
            {rates}
          </Select>
          <div className="underline-indent"></div>
          <CommentInput onChange={this.handleCommentInput} />
          <div className="button-container">{submitButton}</div>
        </Paper>
        <div className="story-preview">
          <h4>Preview</h4>
          {!isLoading || !isEmpty(story) ? (
            <Paper elevation={5} className="paper">
              <UserInfo
                preview={true}
                storyUser={storyUser}
                created_at={created_at}
                rank={rank || 0}
              />
              <Page
                page={storyPage}
                text={highlight}
                mentioned_active_usernames={[]}
              />
              <Activities preview={true} />
            </Paper>
          ) : (
            previewMessage
          )}
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    user:       state.auth.user,
    page:       state.addStory,
    story:      state.editStory.story,
    actionType: state.editStory.actionType,
  }
}

export default connect(mapStateToProps, {
  getAddStoryPage,
  setAddStoryPage,
  submitAddStory,
  submitEditStory,
  setEditStory,
  setActiveItem,
  setMyNuuzCurrentPosition,
})(AddStory)

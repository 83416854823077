/**
 * Created by boris on 3/8/17.
 */
import reducer from './rootReducer'
import thunk from 'redux-thunk'
import { createStore, applyMiddleware } from 'redux'

import { createLogger } from 'redux-logger'

const reducer_ =
  process.env.NODE_ENV === 'development' ? createLogger()(reducer) : reducer

export const store = createStore(reducer_, applyMiddleware(thunk))

/**
 * Created by boris on 2/28/17.
 */
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { browserHistory } from 'react-router'

export default function(ComposeComponent) {
  class Authenticate extends React.Component {
    componentWillMount() {
      const { isAuthenticated } = this.props

      if (!isAuthenticated) browserHistory.push('/login')
    }

    componentWillUpdate(nextProps) {
      const { isAuthenticated } = nextProps

      if (!isAuthenticated) browserHistory.push('/login')
    }

    render() {
      const { isAuthenticated } = this.props
      return isAuthenticated ? <ComposeComponent {...this.props} /> : null
    }
  }

  Authenticate.propTypes = {
    isAuthenticated: PropTypes.bool.isRequired,
  }

  function mapStateToProps(state) {
    return {
      isAuthenticated: state.auth.isAuthenticated,
    }
  }

  return connect(mapStateToProps)(Authenticate)
}

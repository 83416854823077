/**
 * Created by boris on 3/27/17.
 */
import { SET_DRAWER_TOGGLE, SET_ACTIVE_ITEM } from '../actions/constants'

export function setDrawerToggle(open) {
  return {
    type: SET_DRAWER_TOGGLE,
    open,
  }
}

export function setActiveItem(active) {
  return {
    type: SET_ACTIVE_ITEM,
    active,
  }
}

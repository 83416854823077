/**
 * Created by boris on 2/28/17.
 */
import axiosClient from '../axiosClient'
import {
  SET_STORY_LIKE,
  SET_STORY_COMMENTS_STORY,
  SET_STORY_LIKES_INITIAL,
  SET_STORY_LIKES,
  FORBIDDEN,
  MAIN_URL,
  SET_DELETE_STORY,
  NOT_FOUND,
} from '../actions/constants'

export function setStoryLike(story) {
  return {
    type: SET_STORY_LIKE,
    story,
  }
}

export function setStoryCommentsStory(story) {
  return {
    type: SET_STORY_COMMENTS_STORY,
    story,
  }
}

export function setStoryLikesInitial() {
  return {
    type: SET_STORY_LIKES_INITIAL,
  }
}

export function setStoryLikes(storyLikes) {
  return {
    type: SET_STORY_LIKES,
    storyLikes,
  }
}

export function likeStory(url, liked) {
  return dispatch => {
    if (liked) {
      return axiosClient
        .delete(url)
        .then(response => dispatch(setStoryLike(response.data)))
    } else {
      return axiosClient
        .post(url)
        .then(response => dispatch(setStoryLike(response.data)))
    }
  }
}

export const likeStoryAndReloadCurrentStoryLikes = (
  url,
  liked,
  story_id
) => dispatch => {
  const likeStoryP = likeStory(url, liked)(dispatch)
  return likeStoryP.then(() => loadStoryLikes(story_id)(dispatch))
}

export function loadCommentsStory(story_id) {
  return dispatch => {
    const URL = `${MAIN_URL}/stories/${story_id}`
    return axiosClient
      .get(URL)
      .then(response => {
        dispatch(setStoryCommentsStory(response.data))
      })
      .catch(err => {
        const { response } = err
        if (response && response.status === FORBIDDEN) {
          dispatch(setStoryCommentsStory())
        } else if (response && response.status === NOT_FOUND) {
          dispatch(setStoryCommentsStory())
          dispatch({ type: 'SET_NOT_FOUND', notFound: true })
        }
      })
  }
}

export function loadStoryLikes(story_id) {
  return dispatch => {
    const URL = `${MAIN_URL}/stories/${story_id}/likes`
    return axiosClient
      .get(URL)
      .then(response => {
        dispatch(setStoryLikes(response.data))
      })
      .catch(err => {
        console.log(err)
        // const { response } = err
        // if (response && response.status === FORBIDDEN) {
        //   dispatch(setStoryLikes())
        // } else if (response && response.status === NOT_FOUND) {
        //   dispatch(setStoryLikesStory())
        //   dispatch({ type: 'SET_NOT_FOUND', notFound: true })
        // }
      })
  }
}

export function setDeleteStory(id) {
  return {
    type: SET_DELETE_STORY,
    id,
  }
}

export function deleteStory(id) {
  return dispatch =>
    axiosClient
      .delete(`${MAIN_URL}/stories/${id}`)
      .then(response => response.data && dispatch(setDeleteStory(id)))
}

export function reportStory(target, reason) {
  return dispatch =>
    axiosClient.post(`${MAIN_URL}/complaints`, {
      reason,
      type: 'Story',
      target,
    })
}

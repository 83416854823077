/**
 * Created by boris on 3/31/17.
 */
import React from 'react'
import Paper from '@material-ui/core/Paper'
import UserPageInfo from '../UserPageInfo'
import ContentLoading from '../ContentLoading'
import { browserHistory } from 'react-router'
import { connect } from 'react-redux'
import {
  loadFollowersList,
  initialFollowersList,
  loadNextFollowersList,
} from '../../actions/FollowersActions'
import {
  setShowUser,
  getShowUser,
  showUserInitial,
} from '../../actions/ShowUserActions'
import UsersList from '../UsersList'
import {
  deleteFollower,
  followUser,
  unfollowUser,
} from '../../actions/FollowUnfollowActions'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import { searchFollowers } from '../../actions/SearchActions'
import { setActiveItem } from '../../actions/NavbarActions'

import '../../css/Follows.css'
import { Box } from '@material-ui/core'

class FollowersPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      followersSearch: '',
    }
  }

  componentWillMount() {
    const { loadFollowersList, getShowUser, auth } = this.props
    const { user, isAuthenticated } = auth
    const { user_id } = this.props.params
    if (user_id !== user.id) getShowUser(user_id, !isAuthenticated)
    loadFollowersList(user_id)

    // hightlight havbar
    const { setActiveItem } = this.props
    setActiveItem('nav-my-nuuz')
  }

  componentWillUnmount() {
    this.props.showUserInitial()
    this.props.initialFollowersList()
  }

  loadNextData() {
    const { followers, loadNextFollowersList } = this.props
    loadNextFollowersList(followers.pagination.next_url)
  }

  handleFollowClick() {
    const { followUser, unfollowUser, setShowUser, auth, showUser } = this.props

    if (showUser.user.followed_by_me_status === 'none') {
      followUser(auth.user.id, showUser.user.id).then(data => setShowUser(data))
    } else {
      unfollowUser(auth.user.id, showUser.user.id).then(data =>
        setShowUser(data)
      )
    }
  }

  deleteFollower(user_id) {
    const { deleteFollower, auth } = this.props
    deleteFollower(auth.user.id, user_id)
  }

  handleFollowersSearch(event) {
    const { searchFollowers, auth } = this.props
    this.setState(
      {
        [event.target.name]: event.target.value,
      },
      () => searchFollowers(auth.user.id, this.state.followersSearch)
    )
  }

  render() {
    const { followers, auth, showUser } = this.props
    const { followersSearch } = this.state
    const { user_id } = this.props.params
    let { user } = auth
    let { users, pagination, isLoading } = followers
    users = [...users.values()]
    let userPage = (
      <UserPageInfo
        followingsLink={`/users/${user.id}/followings`}
        followersLink={`/users/${user.id}/followers`}
        buttonTitle={'MY NUUZ'}
        handleButtonClick={() => browserHistory.push(`/users/${user.id}`)}
        linkTo={`/users/${user.id}`}
        user={user}
      />
    )

    let showUserPage = null
    const isItCurrentUserPage = Number(user_id) === Number(user.id)
    if (!isItCurrentUserPage && !showUser.isLoading) {
      const status =
        (showUser.user && showUser.user.followed_by_me_status) || 'none'

      let buttonTitle
      if (status === 'none') {
        buttonTitle = 'FOLLOW'
      } else if (status === 'approved') {
        buttonTitle = 'UNFOLLOW'
      } else {
        buttonTitle = status.toUpperCase()
      }

      showUserPage = (
        <UserPageInfo
          followingsLink={`/users/${showUser.user.id}/followings`}
          followersLink={`/users/${showUser.user.id}/followers`}
          buttonTitle={buttonTitle}
          handleButtonClick={this.handleFollowClick.bind(this)}
          linkTo={`/users/${showUser.user.id}`}
          user={showUser.user}
        />
      )
    }

    return (
      <Box className="page-content page-content-full-height">
        <Paper elevation={5} className="user-page">
          {user_id === user.id && userPage}
          {user_id !== user.id && !isLoading && showUserPage}
          <div className="blacklist-content">
            <div className="follows-list-top">
              <Typography className="subheader-20">Followers</Typography>
              <TextField
                placeholder="Search..."
                name="followersSearch"
                className="search-tabs-searchfield followers-list-search"
                onChange={this.handleFollowersSearch.bind(this)}
                value={followersSearch}
              />
            </div>
            {isLoading ? (
              <ContentLoading small="true" />
            ) : (
              <div className="blacklist-container">
                {users.length === 0 ? (
                  <Typography className="no-stories">
                    No users to display
                  </Typography>
                ) : (
                  <UsersList
                    menuItems={[
                      {
                        handler:     this.deleteFollower.bind(this),
                        label:       'Delete follower',
                        currentUser: true,
                      },
                    ]}
                    followers={true}
                    users={users}
                    loadNextData={this.loadNextData.bind(this)}
                    pagination={pagination}
                  />
                )}
              </div>
            )}
          </div>
        </Paper>
      </Box>
    )
  }
}

function mapStateToProps(state) {
  return {
    showUser:  state.showUser,
    auth:      state.auth,
    followers: state.followers,
  }
}

export default connect(mapStateToProps, {
  loadFollowersList,
  initialFollowersList,
  loadNextFollowersList,
  getShowUser,
  showUserInitial,
  followUser,
  unfollowUser,
  setShowUser,
  deleteFollower,
  searchFollowers,
  setActiveItem,
})(FollowersPage)

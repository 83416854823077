/**
 * Created by boris on 4/9/17.
 */
import React from 'react'

import Typography from '@material-ui/core/Typography'
import InfiniteScroll from 'react-infinite-scroll-component'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'

import ActivityHistoryItem from './ActivityHistoryItem'
import ContentLoading from '../ContentLoading'
import { Paper } from '@material-ui/core'

class ActivityHistoryList extends React.Component {
  render() {
    const { activityHistory, subheader, loadNextData } = this.props
    const activitiesList = [
      ...activityHistory.activities.values(),
    ].map(activity => (
      <ActivityHistoryItem key={activity.id} activity={activity} />
    ))
    return (
      <Paper elevation={0} className="users-list-container">
        <Typography>{subheader}</Typography>
        <InfiniteScroll
          next={loadNextData}
          hasMore={
            activityHistory.pagination &&
            Boolean(activityHistory.pagination.next_url)
          }
          loader={' '}
          scrollThreshold={0.5}
          endMessage=" "
          dataLength={activitiesList.length}
          style={{ overflow: 'none' }}
        >
          <ReactCSSTransitionGroup
            transitionName="add-delete-story"
            transitionAppearTimeout={700}
            transitionEnterTimeout={700}
            transitionLeaveTimeout={500}
          >
            {activitiesList}
          </ReactCSSTransitionGroup>
          {activityHistory.pagination && activityHistory.pagination.next_url ? (
            <ContentLoading small={true} list={true} />
          ) : null}
        </InfiniteScroll>
      </Paper>
    )
  }
}

export default ActivityHistoryList

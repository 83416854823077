import React from 'react'
import { connect } from 'react-redux'
import Button from '@material-ui/core/Button'
import { toggleActivation } from '../../actions/AuthActions'
import { browserHistory } from 'react-router'
import Dialog from '@material-ui/core/Dialog'
import Paper from '@material-ui/core/Paper'

import '../../css/Account/Account.css'
import {
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core'

class DeactivatedAccountPage extends React.Component {
  state = {
    open: false,
  }

  handleOpen = () => {
    this.setState({ open: true })
  }

  handleClose = () => {
    this.setState({ open: false })
  }

  handleClick(e) {
    let { toggleActivation, user } = this.props
    toggleActivation(user.id).then(() => browserHistory.push('/'))
  }

  render() {
    const actions = [
      <Button color="primary" onClick={this.handleClose}>
        Cancel
      </Button>,
      <Button color="primary" onClick={this.handleClick.bind(this)}>
        Activate
      </Button>,
    ]

    return (
      <div className="deactivate-page">
        <Paper className="deactivated-container" elevation={3}>
          <h1>Your account is now deactivated</h1>
          <h4>
            Your account is deactivated. If you want to reactivate your account,
            click here:
            <Button
              variant="contained"
              className="activate-account"
              color="primary"
              onClick={this.handleOpen}
            >
              Activate account
            </Button>
          </h4>
          <Dialog
            aria-labelledby="Your account is deactivated"
            modal={false}
            open={this.state.open}
            onClose={this.handleClose}
          >
            <DialogTitle>Your account is deactivated</DialogTitle>
            <DialogContent>
              <DialogContentText>
                {'To reactivate your account click \'Submit\''}
              </DialogContentText>
            </DialogContent>
            <DialogActions>{actions}</DialogActions>
          </Dialog>
        </Paper>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    user: state.auth.user,
  }
}
export default connect(mapStateToProps, { toggleActivation })(
  DeactivatedAccountPage
)

/**
 * Created by boris on 4/3/17.
 */
import axios from 'axios'
import axiosClient from '../axiosClient'
import {
  loadFollowersList,
  setFollowersList,
} from '../actions/FollowersActions'
import {
  loadFollowingsList,
  setFollowingsList,
} from '../actions/FollowingsActions'

import {
  MAIN_URL,
  SET_SEARCH_PEOPLE,
  SET_SEARCH_PEOPLE_LOADING,
  SET_NEXT_SEARCH_PEOPLE,
  INITIAL_SEARCH_PEOPLE,
  SET_SEARCH_TAG_STORIES,
  SET_SEARCH_TAG_STORIES_LOADING,
  SET_NEXT_SEARCH_TAG_STORIES,
  INITIAL_SEARCH_TAG_STORIES,
  SET_SEARCH_PEOPLE_LIST_USER,
  SET_SEARCH_LOCATION_STORIES,
  INITIAL_SEARCH_LOCATION_STORIES,
  SET_SEARCH_LOCATION_STORIES_LOADING,
  SET_NEXT_SEARCH_LOCATION_STORIES,
  SET_SEARCH_TAB,
  SET_SEARCH_FOLLOWERS_LOADING,
  SET_SEARCH_FOLLOWINGS_LOADING,
  SET_CURRENT_SEARCH_USER_STORIES,
  INITIAL_CURRENT_SEARCH_USER_STORIES,
  SET_CURRENT_SEARCH_USER_STORIES_LOADING,
  SET_NEXT_CURRENT_SEARCH_USER_STORIES,
  SET_CURRENT_SEARCH_USER_TEXT,
  SET_OTHER_SEARCH_USER_STORIES,
  INITIAL_OTHER_SEARCH_USER_STORIES,
  SET_OTHER_SEARCH_USER_STORIES_LOADING,
  SET_NEXT_OTHER_SEARCH_USER_STORIES,
  SET_OTHER_SEARCH_USER_TEXT,
  SET_OTHER_SEARCH_USER_ID,
} from '../actions/constants'

const requestsSources = {
  searchPeople:       null,
  tagStories:         null,
  locationStories:    null,
  followers:          null,
  followings:         null,
  currentUserStories: null,
  otherUserStories:   null,
}

export function setSearchPeople(data, name) {
  return {
    type: SET_SEARCH_PEOPLE,
    data,
    name,
  }
}

export function setSearchPeopleInitial() {
  return {
    type: INITIAL_SEARCH_PEOPLE,
  }
}

export function searchPeople(name) {
  return dispatch => {
    dispatch(setSearchPeopleInitial())
    dispatch({ type: SET_SEARCH_PEOPLE_LOADING })
    requestsSources.searchPeople && requestsSources.searchPeople.cancel()

    if (name.trim().length !== 0 || name === '') {
      // for list of all users on comment mentions
      requestsSources.searchPeople = axios.CancelToken.source()
      const data = {
        params:      { name: name.trim() },
        cancelToken: requestsSources.searchPeople.token,
      }
      return axiosClient
        .get(`${MAIN_URL}/users`, data)
        .then(
          response => response && dispatch(setSearchPeople(response.data, name))
        )
        .catch(err => {
          console.log(err)
        })
    }
  }
}

export function setNextSearchPeople(data) {
  return {
    type: SET_NEXT_SEARCH_PEOPLE,
    data,
  }
}

export function loadNextSearchPeople(url) {
  return dispatch =>
    axiosClient
      .get(url)
      .then(
        response => response && dispatch(setNextSearchPeople(response.data))
      )
}

export function setSearchPeopleListUser(user) {
  return {
    type: SET_SEARCH_PEOPLE_LIST_USER,
    user,
  }
}

//  Tag stories

export function setSearchTagStories(data, text) {
  return {
    type: SET_SEARCH_TAG_STORIES,
    data,
    text,
  }
}

export function setSearchTagStoriesInitial() {
  return {
    type: INITIAL_SEARCH_TAG_STORIES,
  }
}

export function searchTagStories(text) {
  return dispatch => {
    dispatch({ type: SET_SEARCH_TAG_STORIES_LOADING })
    requestsSources.tagStories && requestsSources.tagStories.cancel()

    if (text.trim().length !== 0) {
      const tags = text.split()
      requestsSources.tagStories = axios.CancelToken.source()
      const data = {
        params:      { hashtags: tags },
        cancelToken: requestsSources.tagStories.token,
      }
      return axiosClient
        .get(`${MAIN_URL}/stories`, data)
        .then(
          response =>
            response && dispatch(setSearchTagStories(response.data, text))
        )
        .catch(err => {
          console.log(err)
        })
    } else dispatch(setSearchTagStoriesInitial())
  }
}

export function setNextSearchTagStories(data) {
  return {
    type: SET_NEXT_SEARCH_TAG_STORIES,
    data,
  }
}

export function loadNextSearchTagStories(url) {
  return dispatch =>
    axiosClient
      .get(url)
      .then(response => dispatch(setNextSearchTagStories(response.data)))
}

//  User stories

export function setSearchUserText(text, currentUser) {
  return {
    type: currentUser
      ? SET_CURRENT_SEARCH_USER_TEXT
      : SET_OTHER_SEARCH_USER_TEXT,
    text,
  }
}

export function setSearchUserId(user_id) {
  return {
    type: SET_OTHER_SEARCH_USER_ID,
    user_id,
  }
}

export function setSearchUserStories(data, currentUser) {
  return {
    type: currentUser
      ? SET_CURRENT_SEARCH_USER_STORIES
      : SET_OTHER_SEARCH_USER_STORIES,
    data,
  }
}

export function setSearchUserStoriesInitial(currentUser) {
  return {
    type: currentUser
      ? INITIAL_CURRENT_SEARCH_USER_STORIES
      : INITIAL_OTHER_SEARCH_USER_STORIES,
  }
}

export function searchUserStories(user_id, text, currentUser = true) {
  return dispatch => {
    dispatch({
      type: currentUser
        ? SET_CURRENT_SEARCH_USER_STORIES_LOADING
        : SET_OTHER_SEARCH_USER_STORIES_LOADING,
    })
    dispatch(setSearchUserText(text, currentUser))
    !currentUser && dispatch(setSearchUserId(user_id))

    let cancelToken = currentUser
      ? requestsSources.currentUserStories
      : requestsSources.otherUserStories

    cancelToken && cancelToken.cancel()

    if (text.trim().length !== 0) {
      cancelToken = axios.CancelToken.source()
      if (currentUser) requestsSources.currentUserStories = cancelToken
      else requestsSources.otherUserStories = cancelToken

      const data = {
        params:      { text: text.trim() },
        cancelToken: cancelToken.token,
      }
      return axiosClient
        .get(`${MAIN_URL}/users/${user_id}/stories`, data)
        .then(
          response =>
            response &&
            dispatch(setSearchUserStories(response.data, currentUser))
        )
        .catch(err => {
          console.log(err)
        })
    } else dispatch(setSearchUserStoriesInitial(currentUser))
  }
}

export function setNextSearchUserStories(data, currentUser) {
  return {
    type: currentUser
      ? SET_NEXT_CURRENT_SEARCH_USER_STORIES
      : SET_NEXT_OTHER_SEARCH_USER_STORIES,
    data,
  }
}

export function loadNextSearchUserStories(url, currentUser = true) {
  return dispatch =>
    axiosClient
      .get(url)
      .then(response =>
        dispatch(setNextSearchUserStories(response.data, currentUser))
      )
}

// Location stories

export function setSearchLocationStories(data, text) {
  return {
    type: SET_SEARCH_LOCATION_STORIES,
    data,
    text,
  }
}

export function setSearchLocationStoriesInitial() {
  return {
    type: INITIAL_SEARCH_LOCATION_STORIES,
  }
}

export function searchLocationStories(text) {
  return dispatch => {
    dispatch({ type: SET_SEARCH_LOCATION_STORIES_LOADING })
    requestsSources.locationStories && requestsSources.locationStories.cancel()

    if (text.trim().length !== 0) {
      requestsSources.locationStories = axios.CancelToken.source()
      const data = {
        params:      { location_name: text.trim() },
        cancelToken: requestsSources.locationStories.token,
      }
      return axiosClient
        .get(`${MAIN_URL}/stories`, data)
        .then(
          response =>
            response && dispatch(setSearchLocationStories(response.data, text))
        )
        .catch(err => {
          console.log(err)
        })
    } else dispatch(setSearchLocationStoriesInitial())
  }
}

export function setNextSearchLocationStories(data) {
  return {
    type: SET_NEXT_SEARCH_LOCATION_STORIES,
    data,
  }
}

export function loadNextSearchLocationStories(url) {
  return dispatch =>
    axiosClient
      .get(url)
      .then(response => dispatch(setNextSearchLocationStories(response.data)))
}

export function setSearchTabIndex(index) {
  return {
    type: SET_SEARCH_TAB,
    index,
  }
}

export function setSearchInitial() {
  return dispatch => {
    dispatch(setSearchPeopleInitial())
    dispatch(setSearchTagStoriesInitial())
    dispatch(setSearchLocationStoriesInitial())
  }
}

// Followers search

export function searchFollowers(user_id, name) {
  return dispatch => {
    dispatch({ type: SET_SEARCH_FOLLOWERS_LOADING })
    requestsSources.followers && requestsSources.followers.cancel()

    if (name.trim().length !== 0) {
      requestsSources.followers = axios.CancelToken.source()
      const data = {
        params:      { name: name.trim() },
        cancelToken: requestsSources.followers.token,
      }
      return axiosClient
        .get(`${MAIN_URL}/users/${user_id}/followers`, data)
        .then(response => response && dispatch(setFollowersList(response.data)))
        .catch(err => {
          console.log(err)
        })
    } else dispatch(loadFollowersList(user_id))
  }
}

// Followings search

export function searchFollowings(user_id, name) {
  return dispatch => {
    dispatch({ type: SET_SEARCH_FOLLOWINGS_LOADING })
    requestsSources.followings && requestsSources.followings.cancel()

    if (name.trim().length !== 0) {
      requestsSources.followings = axios.CancelToken.source()
      const data = {
        params:      { name: name.trim() },
        cancelToken: requestsSources.followings.token,
      }
      return axiosClient
        .get(`${MAIN_URL}/users/${user_id}/followings`, data)
        .then(
          response => response && dispatch(setFollowingsList(response.data))
        )
        .catch(err => {
          console.log(err)
        })
    } else dispatch(loadFollowingsList(user_id))
  }
}

/**
 * Created by borysyudin on 15.02.17.
 */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import { Link, browserHistory } from 'react-router'
import { likeStory } from '../actions/StoryActions'
import { connect } from 'react-redux'
import classnames from 'classnames'
import { setEditStory } from '../actions/EditStoryActions'
import { SET_SHARE_STORY } from '../actions/constants'
import {
  addToReadingList,
  deleteFromReadingList,
} from '../actions/ReadingListActions'

import Dialog from '@material-ui/core/Dialog'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'

import activeReadingList from '../img/bookmark-active.png'
import disabledReadingList from '../img/bookmark-disabled.png'
import commentsIcon from '../img/comments-icon.png'
import replyIcon from '../img/reply-icon.png'
import likeIcon from '../img/like-icon.png'
import likeSelectedIcon from '../img/like-selected-icon.png'

import '../css/Activities.css'
import {
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core'

class Activities extends Component {
  constructor(props) {
    super(props)
    this.state = {
      openConfirmWindow: false,
    }

    this.handleOpenDialog = this.handleOpenDialog.bind(this)
    this.handleCloseDialog = this.handleCloseDialog.bind(this)
    this.deleteEvent = this.deleteEvent.bind(this)
  }

  getAmount(amount) {
    return amount < 1000 ? amount : amount / 1000 + 'k'
  }

  likeClick(event) {
    const { story, actionsURL, likeStory } = this.props
    likeStory(`${actionsURL}/${story.id}/like`, story.liked)
  }

  shareEvent() {
    const { story, setEditStory } = this.props
    setEditStory(story, SET_SHARE_STORY)
    browserHistory.push('/addstory')
  }

  handleOpenDialog() {
    this.setState({
      openConfirmWindow: true,
    })
  }

  handleCloseDialog() {
    this.setState({
      openConfirmWindow: false,
    })
  }

  deleteEvent() {
    const { story, deleteFromReadingList } = this.props
    this.handleCloseDialog()
    deleteFromReadingList('Story', story.id)
  }

  render() {
    const {
      story,
      comments,
      isAuthenticated,
      preview,
      addToReadingList,
    } = this.props
    const { openConfirmWindow } = this.state

    let content = null
    if (preview) {
      content = (
        <div className="activities">
          <img
            className={classnames('activity', 'like')}
            src={likeIcon}
            alt="Like Icon"
          />
          <img className="activity" src={commentsIcon} alt="Comments Icon" />
          <img className="activity" src={replyIcon} alt="Reply Icon" />
          <Typography className="activities-info">
            <span className="likes-info">0 likes</span>
            <span className="comments-info">0 comments</span>
          </Typography>
        </div>
      )
    } else {
      const actions = [
        <Button key="cancel" color="primary" onClick={this.handleCloseDialog}>
          Cancel
        </Button>,
        <Button
          key="remove"
          color="primary"
          focusVisible={true}
          onClick={this.deleteEvent}
        >
          Remove
        </Button>,
      ]

      const confirmDelete = (
        <Dialog
          aria-labelledby="Remove story"
          modal={false}
          open={openConfirmWindow}
          onClose={this.handleClose}
        >
          <DialogTitle>Remove story</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want remove story from reading list?
            </DialogContentText>
          </DialogContent>
          <DialogActions>{actions}</DialogActions>
        </Dialog>
      )

      content = (
        <div className="activities">
          {isAuthenticated && (
            <img
              className={classnames('activity', 'like', { liked: story.liked })}
              src={story.liked ? likeSelectedIcon : likeIcon}
              alt="Like Icon"
              onClick={this.likeClick.bind(this)}
            />
          )}
          {comments && (
            <Link
              to={`/users/${story.user.id}/stories/${story.id}`}
              className={classnames('activity', {
                'unauth-chat-bubble': !isAuthenticated,
              })}
            >
              <img src={commentsIcon} alt="Comments Icon" />
            </Link>
          )}
          {isAuthenticated && (
            <img
              className="activity"
              src={replyIcon}
              alt="Reply Icon"
              onClick={this.shareEvent.bind(this)}
            />
          )}
          {isAuthenticated && (
            <div className="reading_list">
              {story.read_later ? (
                <img
                  src={activeReadingList}
                  alt="Reading list"
                  onClick={this.handleOpenDialog}
                />
              ) : (
                <img
                  src={disabledReadingList}
                  alt="Reading list"
                  onClick={() => addToReadingList('Story', story.id)}
                />
              )}
            </div>
          )}
          {isAuthenticated && confirmDelete}
          <Box className="activities-info">
            <Link
              to={`/users/${story.user.id}/stories/${story.id}?open_likes=true`}
              className="likes-info-link"
            >
              <span className="likes-info">
                {this.getAmount(story.likes)} likes
              </span>
            </Link>
            {comments ? (
              <Link
                to={`/users/${story.user.id}/stories/${story.id}`}
                className="comments-info-link"
              >
                <span className="comments-info">
                  {this.getAmount(story.comments_count)} comments
                </span>
              </Link>
            ) : null}
          </Box>
        </div>
      )
    }
    return content
  }
}

Activities.propTypes = {
  likeStory: PropTypes.func.isRequired,
}

function mapStateToProps(state) {
  return {
    user:            state.auth.user,
    isAuthenticated: state.auth.isAuthenticated,
  }
}

export default connect(mapStateToProps, {
  likeStory,
  setEditStory,
  addToReadingList,
  deleteFromReadingList,
})(Activities)

/**
 * Created by borysyudin on 16.02.17.
 */
import { parse } from 'query-string'
import React, { Component } from 'react'
import Story from './StoryCommentsPage/Story'
import StoryLikesList from './StoryCommentsPage/StoryLikesList'
import CommentsList from './CommentsList'
import ContentLoading from './ContentLoading'

import { connect } from 'react-redux'
import { loadCommentsStory, loadStoryLikes } from '../actions/StoryActions'
import { setStoryCommentsPageInitial } from '../actions/CommentsActions'
import isEmpty from 'lodash/isEmpty'
import { browserHistory } from 'react-router'
import { MAIN_URL } from '../actions/constants'
import { submitEditStory } from '../actions/EditStoryActions'
import registerStoryShowPageRealtimeUpdatesEffect from '../realtime-updates/per-page/storyShow'

class StoryComments extends Component {
  constructor(props) {
    super(props)

    // show likes if url is /users/:user_id/stories/:story_id?open_likes=true
    const parsed = parse(window.location.search)
    const opennedListType = parsed.open_likes === 'true' ? 'likes' : 'comments'

    this.state = {
      opennedListType, // comments/likes
      opennedCommentLikes: false,
      currentComment:      {},
    }

    this.onLikesInfoClick = this.onLikesInfoClick.bind(this)
    this.onCommentsInfoClick = this.onCommentsInfoClick.bind(this)
    this.handleLoadCommentLikes = this.handleLoadCommentLikes.bind(this)
  }

  componentWillMount() {
    const { isAuthenticated, isLoading, story } = this.props

    if (!isAuthenticated && !isLoading && isEmpty(story)) {
      browserHistory.push('/login')
    }
  }

  componentDidMount() {
    const { loadCommentsStory } = this.props
    const { story_id } = this.props.params
    loadCommentsStory(story_id)

    const { loadStoryLikes } = this.props
    loadStoryLikes(story_id)

    this.storyShowPageRealtimeUpdatesEffectUnsubscribe = registerStoryShowPageRealtimeUpdatesEffect()
  }

  loadCommentsOrLikesBasedOnOpennedListType(opennedListType) {
    if (opennedListType === 'comments') {
      const { loadCommentsStory } = this.props
      const { story_id } = this.props.params
      loadCommentsStory(story_id)
    } else {
      const { loadStoryLikes } = this.props
      const { story_id } = this.props.params
      loadStoryLikes(story_id)
    }
  }

  componentWillUpdate(nextProps) {
    const {
      isAuthenticated,
      story,
      isLoading,
      deletedStory,
      blockedUser,
    } = nextProps

    if (!isAuthenticated && !isLoading && isEmpty(story)) {
      browserHistory.push('/login')
    }

    if (deletedStory) browserHistory.push(`/users/${story.user.id}`)

    if (blockedUser) browserHistory.push('/')
  }

  componentWillUnmount() {
    this.props.setStoryCommentsPageInitial()

    this.storyShowPageRealtimeUpdatesEffectUnsubscribe()
  }

  onLikesInfoClick(e) {
    this.setState({
      opennedListType:     'likes',
      opennedCommentLikes: false,
      currentComment:      {},
    })
    this.loadCommentsOrLikesBasedOnOpennedListType('likes')
  }

  onCommentsInfoClick(e) {
    this.setState({
      opennedListType:     'comments',
      opennedCommentLikes: false,
      currentComment:      {},
    })
    this.loadCommentsOrLikesBasedOnOpennedListType('comments')
  }

  moveToArchive(story) {
    const { submitEditStory, loadCommentsStory, isAuthenticated } = this.props
    const { story_id } = this.props.params
    const { text, id } = story

    submitEditStory({ rank: '', text }, id).then(response => {
      loadCommentsStory(story_id, !isAuthenticated)
    })
  }

  handleLoadCommentLikes(comment) {
    this.setState({
      opennedCommentLikes: true,
      currentComment:      comment,
    })
  }

  render() {
    const { story_id } = this.props.params
    const { isLoading, story, storyLikesLoading, storyLikes } = this.props

    const pageStory = isEmpty(story) ? null : (
      <Story
        moveToArchive={this.moveToArchive.bind(this)}
        story={story}
        actionsURL={MAIN_URL + '/stories'}
        onLikesInfoClick={this.onLikesInfoClick}
        onCommentsInfoClick={this.onCommentsInfoClick}
      />
    )

    let commentsOrLikesList = null

    if (this.state.opennedListType === 'comments' && !isEmpty(story)) {
      commentsOrLikesList = (
        <CommentsList
          story_id={story_id}
          commentsCount={story.comments_count}
          opennedCommentLikes={this.state.opennedCommentLikes}
          currentComment={this.state.currentComment}
          handleLoadCommentLikes={this.handleLoadCommentLikes}
        />
      )
    }

    if (this.state.opennedListType === 'likes' && !isEmpty(storyLikes)) {
      commentsOrLikesList = (
        <StoryLikesList
          storyLikesLoading={storyLikesLoading}
          storyLikes={storyLikes}
        />
      )
    }

    return (
      <div className="page-content">
        {(this.state.opennedListType === 'comments' && isLoading) ||
        (this.state.opennedListType === 'likes' && storyLikesLoading) ? (
            <ContentLoading />
          ) : null}
        {pageStory}
        {commentsOrLikesList}
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    blockedUser:       state.storyCommentsStory.blockedUser,
    deletedStory:      state.storyCommentsStory.deletedStory,
    story:             state.storyCommentsStory.story,
    isLoading:         state.storyCommentsStory.isLoading,
    storyLikesLoading: state.storyLikes.isLoading,
    storyLikes:        state.storyLikes.likes,
    isAuthenticated:   state.auth.isAuthenticated,
    user:              state.auth.user,
  }
}

export default connect(mapStateToProps, {
  loadCommentsStory,
  loadStoryLikes,
  setStoryCommentsPageInitial,
  submitEditStory,
})(StoryComments)

/**
 * Created by boris on 5/3/17.
 */
import React from 'react'
import { browserHistory } from 'react-router'

import { store } from '../Store'

class Page404 extends React.Component {
  render() {
    return (
      <div
        className="not-found-page"
        onClick={() => {
          store.dispatch({ type: 'SET_NOT_FOUND', notFound: false })
          browserHistory.push('/')
        }}
      >
        <div className="sign-404">404</div>
        Page not found
      </div>
    )
  }
}

export default Page404

/**
 * Created by boris on 2/27/17.
 */
/**
 * Created by boris on 2/27/17.
 */
import React from 'react'
import { connect } from 'react-redux'
import { browserHistory } from 'react-router'
import isEmpty from 'lodash/isEmpty'

import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'

import { UNAUTHORIZED, BAD_REQUEST } from '../../actions/constants'
import { signup, checkUsername } from '../../actions/SignUpActions'
import { getSignupToken } from '../../actions/AuthActions'
import classnames from 'classnames'

import textToError from '../../utils/textToError'

import '../../css/SignUp/SignUpForm.css'

class SignUpForm extends React.Component {
  constructor(props) {
    super(props)
    const { email } = this.props
    const username = email.split('@')[0]
    this.state = {
      first_name:      null,
      last_name:       null,
      email:           email,
      username:        username || null,
      password:        null,
      repeat_password: null,
      errors:          {},
      isLoading:       false,
      isValid:         true,
      usernameInUse:   '',
    }
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.checkUsername = this.checkUsername.bind(this)
  }

  isValid(data) {
    let errors = {}
    let { first_name, last_name, username, password, repeat_password } = data
    const { token } = this.props
    const { usernameInUse } = this.state
    if (first_name !== null && isEmpty(first_name)) {
      errors.first_name = 'First name is required'
    }

    if (last_name !== null && isEmpty(last_name)) {
      errors.last_name = 'Last name is required'
    }

    if (username !== null && isEmpty(username)) {
      errors.username = 'Username is required'
    }

    if (usernameInUse) errors.username = usernameInUse

    if (!token && password !== null && password.length < 8) {
      errors.password = 'Passwords must be at least six characters.'
    }

    if (!token && password !== null && isEmpty(password)) {
      errors.password = 'Password is required'
    }

    if (!token && repeat_password !== null && isEmpty(repeat_password)) {
      errors.repeat_password = 'Repeat password is required'
    }

    if (!token && password !== repeat_password) {
      errors.repeat_password = 'Passwords do not match'
    }

    return {
      errors,
      isValid: isEmpty(errors),
    }
  }

  checkUsername(event) {
    const { checkUsername } = this.props
    const username = event.target.value.trim()
    if (this.state.username === null && !username) return
    this.setState({ username: username }, () => {
      checkUsername(username)
        .then(response => {
          let errors = Object.assign({}, this.state.errors)
          delete errors['username']
          this.setState(
            {
              usernameInUse: '',
              errors:        errors,
            },
            () => {
              const { isValid, errors } = this.isValid(this.state)
              this.setState({ isValid, errors })
            }
          )
        })
        .catch(err => {
          if (err.response.status === BAD_REQUEST) {
            this.setState(
              {
                errors: {
                  username: 'Username ' + username + ' is in use already',
                },
                usernameInUse: 'Username ' + username + ' is in use already',
              },
              () => {
                const { isValid, errors } = this.isValid(this.state)
                this.setState({ isValid, errors })
              }
            )
          } else if (err.response.status === UNAUTHORIZED) {
            getSignupToken().then(response => {
              this._usernameInput.focus()
              this._usernameInput.blur()
            })
          }
        })
    })
  }

  handleSubmit(event) {
    event.preventDefault()
    const {
      first_name,
      last_name,
      username,
      password,
      repeat_password,
    } = this.state
    const { token } = this.props
    const data = {
      first_name:      (first_name && first_name.trim()) || '',
      last_name:       (last_name && last_name.trim()) || '',
      username:        (username && username.trim()) || '',
      password:        (password && password.trim()) || '',
      repeat_password: (repeat_password && repeat_password.trim()) || '',
    }
    let { errors, isValid } = this.isValid(data)
    const { signup, email, handleBadRequest } = this.props
    if (isValid) {
      this.setState({ errors: {}, isLoading: true })
      delete data['repeat_password']
      data['email'] = email
      if (token) {
        delete data['email']
        delete data['password']
        data['token'] = token
      }
      signup(data).catch(err => {
        const error = err.response
        if (error && error.status === UNAUTHORIZED) {
          getSignupToken().then(response => {
            this._submitButton.refs.container.refs.enhancedButton.click()
          })
        } else if (error && error.status === BAD_REQUEST) handleBadRequest()
      })
    } else this.setState({ errors, ...data, isValid })
  }

  handleChange(event) {
    this.setState(
      {
        [event.target.name]: event.target.value,
      },
      () => {
        const { errors, isValid } = this.isValid(this.state)
        this.setState({
          errors: errors,
          isValid,
        })
      }
    )
  }

  componentDidMount() {
    this._usernameInput.focus()
    this._usernameInput.blur()
  }

  componentWillUpdate(nextProps) {
    if (nextProps.isAuthenticated) browserHistory.push('/editprofile')
  }

  render() {
    let {
      first_name,
      last_name,
      username,
      password,
      repeat_password,
      errors,
      isLoading,
      isValid,
    } = this.state
    const { token } = this.props
    return (
      <form onSubmit={this.handleSubmit} className="signup-form">
        <TextField
          label="First name*"
          name="first_name"
          type="text"
          value={first_name || ''}
          {...textToError(errors.first_name)}
          onChange={this.handleChange}
          className={classnames({
            'invalid-input': errors.first_name,
          })}
        />
        <TextField
          label="Last name*"
          name="last_name"
          type="text"
          value={last_name || ''}
          {...textToError(errors.last_name)}
          onChange={this.handleChange}
          className={classnames({
            'invalid-input': errors.last_name,
          })}
        />
        <TextField
          ref={input => (this._usernameInput = input)}
          label="Username*"
          name="username"
          type="text"
          value={username || ''}
          {...textToError(errors.username)}
          onChange={this.checkUsername}
          onBlur={this.checkUsername}
          className={classnames({
            'invalid-input': errors.username,
          })}
        />
        {token ? null : (
          <TextField
            label="Password*"
            name="password"
            type="password"
            value={password || ''}
            {...textToError(errors.password)}
            onChange={this.handleChange}
            className={classnames({
              'invalid-input': errors.password,
            })}
          />
        )}
        {token ? null : (
          <TextField
            label="Repeat password*"
            name="repeat_password"
            type="password"
            value={repeat_password || ''}
            {...textToError(errors.repeat_password)}
            onChange={this.handleChange}
            className={classnames({
              'invalid-input': errors.repeat_password,
            })}
          />
        )}
        <div className="signup-submit-button">
          <Button
            variant="contained"
            onClick={this.props.handleBackClick}
            className="signup-form-back"
          >
            Back
          </Button>
          <Button
            variant="contained"
            ref={input => (this._submitButton = input)}
            disabled={isLoading || !isValid}
            color="primary"
            onClick={this.handleSubmit}
            className="send-comment"
          >
            Create
          </Button>
        </div>
      </form>
    )
  }
}

function mapStateToProps(state) {
  return {
    isAuthenticated: state.auth.isAuthenticated,
  }
}

export default connect(mapStateToProps, { signup, checkUsername })(SignUpForm)

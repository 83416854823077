/**
 * Created by boris on 3/1/17.
 */
import isEmpty from 'lodash/isEmpty'
import validator from 'validator'

export function loginValidation(data, submit = false) {
  const errors = {}
  const { username, password } = data
  if (
    (!submit && username !== null && isEmpty(username)) ||
    (submit && (username === null || isEmpty(username)))
  ) {
    errors.username = 'Username is required'
  }

  if (
    (!submit && password !== null && isEmpty(password)) ||
    (submit && (password === null || isEmpty(password)))
  ) {
    errors.password = 'Password is required'
  }

  return {
    errors,
    isValid: isEmpty(errors),
  }
}

export function EmailFormValidation(email) {
  const errors = {}
  if (email === null) errors.email = 'Email is required'
  else if (!validator.isEmail(email.trim())) errors.email = 'Email is not valid'

  return {
    errors,
    isValid: isEmpty(errors),
  }
}

export function ResetPasswordValidation(data, submit = false) {
  const errors = {}
  const fieldNames = {
    email:           'Email ',
    code:            'Code',
    password:        'Password',
    repeat_password: 'Repeat password',
  }

  for (let key in data) {
    if (
      submit &&
      data.hasOwnProperty(key) &&
      (data[key] === null || data[key].trim().length === 0)
    ) {
      errors[key] = `${fieldNames[key]} is required`
    }
  }

  if (data.password && data.password.length < 6) {
    errors.password = 'Passwords must be at least six characters.'
  }

  if (data.password !== data.repeat_password) {
    errors.repeat_password = 'Passwords do not match'
  }

  return {
    errors,
    isValid: isEmpty(errors),
  }
}

export function EmailValidation(email) {
  const errors = {}

  if (email === null || email.trim().length === 0) {
    errors.email = 'Email is required'
  } else if (!validator.isEmail(email.trim())) {
    errors.email = 'Email is not valid'
  }

  return {
    errors,
    isValid: isEmpty(errors),
  }
}

export function editValidation(data) {
  const errors = {}
  let {
    first_name,
    last_name,
    email,
    username,
    facebookUser,
    new_password,
    new_password_repeat,
  } = data
  if (first_name.trim().length === 0) {
    errors['first_name'] = 'First name is required'
  }
  if (last_name.trim().length === 0) {
    errors['last_name'] = 'Last name is required'
  }
  if (username.trim().length === 0) errors['username'] = 'Username is required'

  email = email && email.trim()
  if (!facebookUser && email.length === 0) errors['email'] = 'Email is required'
  else if (
    (!facebookUser && !validator.isEmail(email)) ||
    (facebookUser && email && !validator.isEmail(email))
  ) {
    errors['email'] = 'Email is invalid'
  }

  if (new_password.length > 0 && new_password.length < 6) {
    errors.new_password = 'Passwords must be at least six characters.'
  }

  new_password_repeat = new_password_repeat || ''
  if (
    (new_password.length > 0 || new_password_repeat.length > 0) &&
    new_password !== new_password_repeat
  ) {
    errors.new_password_repeat = 'Passwords do not match'
  }

  return {
    isValid: isEmpty(errors),
    errors,
  }
}

export function addStoryValidation(data) {
  // console.log('addStoryValidation', data)
  const errors = {}
  const { link, rank } = data
  if (!validator.isURL(link)) errors.link = 'Link is not valid'
  //
  // Implemented according to https://pm.nordicresults.com/issues/37167
  if (!rank || rank === 'None' || rank < 0) errors.rank = 'Rank is not defined'

  return {
    isValid: isEmpty(errors),
    errors,
  }
}

export function addReadingListValidation(data) {
  const errors = {}
  const { link } = data
  if (!validator.isURL(link)) errors.link = 'Link is not valid'

  return {
    isValid: isEmpty(errors),
    errors,
  }
}

export function EditPasswordValidation(data) {
  const errors = {}
  let { new_password, new_password_repeat, current_password } = data

  if (!current_password) {
    errors.current_password = 'Must be completed'
  }

  if (new_password.length > 0 && new_password.length < 6) {
    errors.new_password = 'Passwords must be at least six characters.'
  }

  new_password_repeat = new_password_repeat || ''

  if (
    (new_password.length > 0 || new_password_repeat.length > 0) &&
    new_password !== new_password_repeat
  ) {
    errors.new_password_repeat = 'Passwords do not match'
  }

  if (new_password.length === 0) {
    errors.new_password = 'Must be completed'
  }

  if (new_password_repeat.length === 0) {
    errors.new_password_repeat = 'Must be completed'
  }

  return {
    isValid: isEmpty(errors),
    errors,
  }
}

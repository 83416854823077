import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Story from './FeedPage/Story'
import ContentLoading from './ContentLoading'
import InfiniteScroll from 'react-infinite-scroll-component'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import { MAIN_URL } from '../actions/constants'
import isEmpty from 'lodash/isEmpty'

import '../css/StoryList.css'

class FeedPageList extends Component {
  render() {
    const { pagination, loadNextData, moveToArchive, data } = this.props

    if (isEmpty(data)) {
      return (
        <div className="page-content">
          <div className="no-stories">No data to display</div>
        </div>
      )
    } else {
      const feeds = Array.from(data, ([key, story]) => {
        return (
          <Story
            moveToArchive={moveToArchive}
            key={key}
            story={story}
            actionsURL={MAIN_URL + this.props.actionsURL}
            comments={true}
          />
        )
      })

      const feeds_ = (
        <InfiniteScroll
          next={loadNextData}
          hasMore={pagination && Boolean(pagination.next_url)}
          loader={' '}
          scrollThreshold={0.8}
          endMessage=" "
          dataLength={feeds.length}
        >
          <ReactCSSTransitionGroup
            transitionName="add-delete-story"
            transitionAppearTimeout={700}
            transitionEnterTimeout={700}
            transitionLeaveTimeout={500}
          >
            {feeds}
          </ReactCSSTransitionGroup>
          {pagination && Boolean(pagination.next_url) ? (
            <ContentLoading small={true} list={true} />
          ) : null}
        </InfiniteScroll>
      )
      return <div className="page-content">{feeds_}</div>
    }
  }
}

FeedPageList.propTypes = {
  loadNextData:  PropTypes.func.isRequired,
  moveToArchive: PropTypes.func.isRequired,
  pagination:    PropTypes.object.isRequired,
  data:          PropTypes.object,
}

export default FeedPageList

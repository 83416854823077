/**
 * Created by boris on 4/28/17.
 */
import { SIGNUP_RELOAD, SHOW_SIGNUP_FORM } from '../actions/constants'

export default function(state = { showSignUpForm: false }, action) {
  switch (action.type) {
    case SIGNUP_RELOAD: {
      return { showSignUpForm: false }
    }

    case SHOW_SIGNUP_FORM: {
      return { showSignUpForm: true }
    }

    default:
      return state
  }
}

/**
 * Created by boris on 4/25/17.
 */
import axiosClient from '../axiosClient'
import {
  MAIN_URL,
  SET_READING_LIST,
  SET_READING_LIST_LOADING,
  SET_READING_LIST_INITIAL,
  SET_READING_LIST_ITEM,
  DELETE_READING_LIST_ITEM,
  SET_NEXT_READING_LIST,
  SET_READINGLIST_CURRENT_POSITION,
} from '../actions/constants'

export function initialReadingList() {
  return {
    type: SET_READING_LIST_INITIAL,
  }
}

export function setReadingList(data) {
  return {
    type: SET_READING_LIST,
    data,
  }
}

export function getReadingList() {
  return dispatch => {
    dispatch({ type: SET_READING_LIST_LOADING })
    axiosClient
      .get(`${MAIN_URL}/reading_list`)
      .then(response => dispatch(setReadingList(response.data)))
  }
}

export function setReadingListItem(item) {
  return {
    type: SET_READING_LIST_ITEM,
    item,
  }
}

export function addToReadingList(type, item_id) {
  return dispatch =>
    axiosClient
      .post(`${MAIN_URL}/reading_list`, { type, item_id })
      .then(response => response && dispatch(setReadingListItem(response.data)))
}

export function deleteReadingListItem(item_id) {
  return {
    type: DELETE_READING_LIST_ITEM,
    item_id,
  }
}

export function deleteFromReadingList(type, item_id) {
  return dispatch =>
    axiosClient
      .delete(`${MAIN_URL}/reading_list/${type}/${item_id}`)
      .then(response => {
        response &&
          response.data === true &&
          dispatch(deleteReadingListItem(item_id))
      })
}

export function setNextReadingList(data) {
  return {
    type: SET_NEXT_READING_LIST,
    data,
  }
}
export function loadNextReadingList(url) {
  return dispatch =>
    axiosClient
      .get(url)
      .then(response => dispatch(setNextReadingList(response.data)))
}

export function setCurrentReadingListPosition(position) {
  return {
    type: SET_READINGLIST_CURRENT_POSITION,
    position,
  }
}

import subscribe from '../lib/subscribe'
import merge from '../lib/merge'

import {
  REALTIME_MY_NUUZ_PAGE_COMMENT_CREATED,
  REALTIME_MY_NUUZ_PAGE_COMMENT_DELETED,
  REALTIME_MY_NUUZ_PAGE_COMMENT_LIKED,
  REALTIME_MY_NUUZ_PAGE_COMMENT_UNLIKED,
  REALTIME_MY_NUUZ_PAGE_STORY_LIKED,
  REALTIME_MY_NUUZ_PAGE_STORY_UNLIKED,
} from '../../actions/constants'

export default function registerRealtimeUpdatesEffect() {
  return merge([
    subscribe(
      { channel: 'CommentCreatedChannel' },
      REALTIME_MY_NUUZ_PAGE_COMMENT_CREATED
    ),
    subscribe(
      { channel: 'CommentDeletedChannel' },
      REALTIME_MY_NUUZ_PAGE_COMMENT_DELETED
    ),
    subscribe(
      { channel: 'CommentLikeChannel' },
      REALTIME_MY_NUUZ_PAGE_COMMENT_LIKED
    ),
    subscribe(
      { channel: 'CommentUnlikeChannel' },
      REALTIME_MY_NUUZ_PAGE_COMMENT_UNLIKED
    ),
    subscribe(
      { channel: 'StoryLikeChannel' },
      REALTIME_MY_NUUZ_PAGE_STORY_LIKED
    ),
    subscribe(
      { channel: 'StoryUnlikeChannel' },
      REALTIME_MY_NUUZ_PAGE_STORY_UNLIKED
    ),
  ])
}

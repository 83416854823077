import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'

import UserInfo from '../UserInfo'
import Page from '../Page'
import StoryActivities from './StoryActivities'
import { deleteStory, reportStory } from '../../actions/StoryActions'
import { connect } from 'react-redux'
import { setEditStory, submitEditStory } from '../../actions/EditStoryActions'
import { browserHistory } from 'react-router'
import { blockUser } from '../../actions/ShowUserActions'
import classnames from 'classnames'
import { SET_EDIT_STORY, REPORT_MESSAGES } from '../../actions/constants'

import Dialog from '@material-ui/core/Dialog'
import Button from '@material-ui/core/Button'

import '../../css/StoryCommentsPage/Story.css'
import {
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Radio,
  RadioGroup,
} from '@material-ui/core'
import FormControlLabel from '@material-ui/core/FormControlLabel'

class Story extends Component {
  constructor(props) {
    super(props)
    this.state = {
      openConfirmWindow: false,
      windowType:        'DELETE_STORY',
      reportText:        '',
      errors:            {},
    }

    this.reportMessages = REPORT_MESSAGES

    this.deleteEvent = this.deleteEvent.bind(this)
    this.handleCloseDialog = this.handleCloseDialog.bind(this)
    this.handleOpenDialog = this.handleOpenDialog.bind(this)
    this.editEvent = this.editEvent.bind(this)
    this.blockUserEvent = this.blockUserEvent.bind(this)
    this.moveToArchiveEvent = this.moveToArchiveEvent.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.reportStoryEvent = this.reportStoryEvent.bind(this)
  }

  editEvent() {
    const { story, setEditStory } = this.props
    setEditStory(story, SET_EDIT_STORY)
    browserHistory.push('/addstory')
  }

  deleteEvent() {
    const { story, deleteStory } = this.props
    this.handleCloseDialog()
    deleteStory(story.id)
  }

  blockUserEvent() {
    const { story, blockUser } = this.props
    this.handleCloseDialog()
    blockUser(story.user.id)
  }

  moveToArchiveEvent() {
    const { story, moveToArchive } = this.props
    this.handleCloseDialog()
    moveToArchive(story)
  }

  reportStoryEvent() {
    const { story, reportStory } = this.props
    const { reportText } = this.state
    this.handleCloseDialog()
    reportStory(story.id, this.reportMessages[reportText])
  }

  handleOpenDialog(windowType) {
    this.setState({
      openConfirmWindow: true,
      windowType,
    })
  }

  handleCloseDialog() {
    this.setState({
      openConfirmWindow: false,
      windowType:        'DELETE_STORY',
      reportText:        '',
      errors:            {},
    })
  }

  handleChange(event) {
    this.setState({
      reportText: event.target.value,
    })
  }

  render() {
    const { openConfirmWindow, windowType, reportText } = this.state

    let reason = (
      <RadioGroup onChange={this.handleChange} className="report-post-group">
        <FormControlLabel
          value="spam"
          control={<Radio />}
          label={this.reportMessages['spam']}
        />
        <FormControlLabel
          value="sexually"
          control={<Radio />}
          label={this.reportMessages['sexually']}
        />
        <FormControlLabel
          value="child"
          control={<Radio />}
          label={this.reportMessages['child']}
        />
        <FormControlLabel
          value="hate"
          control={<Radio />}
          label={this.reportMessages['hate']}
        />
        <FormControlLabel
          value="harassment"
          control={<Radio />}
          label={this.reportMessages['harassment']}
        />
      </RadioGroup>
    )

    const confirmWindows = {
      DELETE_STORY: {
        message:      'Are you sure you want to delete the post?',
        label:        'Delete post',
        eventHandler: this.deleteEvent,
      },
      MOVE_TO_ARCHIVE: {
        message:      'Are you sure that you want to move this to your archive?',
        label:        'Move to archive',
        eventHandler: this.moveToArchiveEvent,
      },
      BLOCK_USER: {
        message:      'Are you sure you want to block this user?',
        label:        'Block user',
        eventHandler: this.blockUserEvent,
      },
      REPORT_STORY: {
        title:        'Report post',
        message:      reason,
        label:        'Report',
        eventHandler: this.reportStoryEvent,
      },
    }

    const { title, message, label, eventHandler } = confirmWindows[windowType]

    const actions = [
      <Button color="primary" onClick={this.handleCloseDialog}>
        Cancel
      </Button>,
      <Button
        className={classnames({
          'block-user-label': label === 'Block user',
        })}
        disabled={
          windowType === 'REPORT_STORY' && reportText.trim().length === 0
        }
        color="primary"
        focusVisible={true}
        onClick={eventHandler}
      >
        {label}
      </Button>,
    ]

    const confirmDelete = (
      <Dialog
        aria-labelledby={title}
        modal={false}
        open={openConfirmWindow}
        onClose={this.handleClose}
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{message}</DialogContentText>
        </DialogContent>
        <DialogActions>{actions}</DialogActions>
      </Dialog>
    )

    const {
      story,
      comments,
      onLikesInfoClick,
      onCommentsInfoClick,
    } = this.props

    return (
      <Paper elevation={5} className="story_comments_page__story__paper">
        <UserInfo
          handleEvent={this.handleOpenDialog}
          editStory={this.editEvent}
          storyUser={story.user}
          created_at={story.created_at}
          rank={story.rank}
        />
        <Page
          page={story.page}
          text={story.text}
          comments={comments}
          mentioned_active_usernames={story.mentioned_active_usernames}
        />
        <StoryActivities
          story={story}
          actionsURL={this.props.actionsURL}
          comments={comments}
          onLikesInfoClick={onLikesInfoClick}
          onCommentsInfoClick={onCommentsInfoClick}
        />
        {confirmDelete}
      </Paper>
    )
  }
}

Story.propTypes = {
  story: PropTypes.object.isRequired,
}

export default connect(null, {
  deleteStory,
  setEditStory,
  submitEditStory,
  blockUser,
  reportStory,
})(Story)

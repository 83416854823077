import { store } from '../../Store'

import cableObservable from './cableObservable'

export default function subscribe(params, actionType) {
  return cableObservable.subscribe({
    actionCableSubscription: null,
    next(cable) {
      if (!cable) {
        return
      }

      console.log(`subsribed ${params.channel}`)
      const subscription = cable.subscriptions.create(params, {
        received: payload => {
          console.log(`channel ${params.channel} received`, payload)
          store.dispatch({ type: actionType, payload })
        },
      })

      this.actionCableSubscription = subscription
    },
    error() {
      throw new Error()
    },
    complete() {
      console.log(`channel ${params.channel} unsubscribed`)

      if (!this.actionCableSubscription) {
        throw new Error()
      }

      this.actionCableSubscription.unsubscribe()
    },
  })
}

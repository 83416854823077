/**
 * Created by boris on 3/31/17.
 */
import React from 'react'
import Paper from '@material-ui/core/Paper'
import UserPageInfo from '../UserPageInfo'
import ContentLoading from '../ContentLoading'
import { browserHistory } from 'react-router'
import { connect } from 'react-redux'
import {
  loadFollowingsList,
  initialFollowingsList,
  loadNextFollowingsList,
} from '../../actions/FollowingsActions'
import {
  getShowUser,
  showUserInitial,
  setShowUser,
} from '../../actions/ShowUserActions'
import UsersList from '../UsersList'
import { followUser, unfollowUser } from '../../actions/FollowUnfollowActions'
import { setActiveItem } from '../../actions/NavbarActions'

import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import { searchFollowings } from '../../actions/SearchActions'
import { Box } from '@material-ui/core'

class FollowingsPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      followingsSearch: '',
    }
  }

  componentWillMount() {
    const { loadFollowingsList, getShowUser, auth } = this.props
    const { user, isAuthenticated } = auth
    const { user_id } = this.props.params
    if (user_id !== user.id) getShowUser(user_id, !isAuthenticated)
    loadFollowingsList(user_id)

    // hightlight havbar
    const { setActiveItem } = this.props
    setActiveItem('nav-my-nuuz')
  }

  componentWillUnmount() {
    this.props.showUserInitial()
    this.props.initialFollowingsList()
  }

  loadNextData() {
    const { followings, loadNextFollowingsList } = this.props
    loadNextFollowingsList(followings.pagination.next_url)
  }

  handleFollowClick() {
    const { followUser, unfollowUser, auth, setShowUser, showUser } = this.props

    if (showUser.user.followed_by_me_status === 'none') {
      followUser(auth.user.id, showUser.user.id).then(data => setShowUser(data))
    } else {
      unfollowUser(auth.user.id, showUser.user.id).then(data =>
        setShowUser(data)
      )
    }
  }

  handleFollowingsSearch(event) {
    const { searchFollowings, auth } = this.props
    this.setState(
      {
        [event.target.name]: event.target.value,
      },
      () => searchFollowings(auth.user.id, this.state.followingsSearch)
    )
  }

  render() {
    const { followings, auth, showUser } = this.props
    const { followingsSearch } = this.state
    let { user } = auth
    const { user_id } = this.props.params
    let { users, pagination, isLoading } = followings
    users = [...users.values()]
    let userPage = (
      <UserPageInfo
        followingsLink={`/users/${user.id}/followings`}
        followersLink={`/users/${user.id}/followers`}
        buttonTitle={'MY NUUZ'}
        handleButtonClick={() => browserHistory.push(`/users/${user.id}`)}
        linkTo={`/users/${user.id}`}
        user={user}
      />
    )

    let showUserPage = null
    const isItCurrentUserPage = Number(user_id) === Number(user.id)
    if (!isItCurrentUserPage && !showUser.isLoading) {
      const status =
        (showUser.user && showUser.user.followed_by_me_status) || 'none'

      let buttonTitle
      if (status === 'none') {
        buttonTitle = 'FOLLOW'
      } else if (status === 'approved') {
        buttonTitle = 'UNFOLLOW'
      } else {
        buttonTitle = status.toUpperCase()
      }

      showUserPage = (
        <UserPageInfo
          followingsLink={`/users/${showUser.user.id}/followings`}
          followersLink={`/users/${showUser.user.id}/followers`}
          buttonTitle={buttonTitle}
          handleButtonClick={this.handleFollowClick.bind(this)}
          linkTo={`/users/${showUser.user.id}`}
          user={showUser.user}
        />
      )
    }

    return (
      <Box className="page-content page-content-full-height">
        <Paper elevation={5} className="user-page">
          {user_id === user.id && userPage}
          {user_id !== user.id && !isLoading && showUserPage}
          <div className="blacklist-content">
            <div className="follows-list-top">
              <Typography className="subheader-20">Following</Typography>
              <TextField
                placeholder="Search..."
                name="followingsSearch"
                className="search-tabs-searchfield followers-list-search"
                onChange={this.handleFollowingsSearch.bind(this)}
                value={followingsSearch}
              />
            </div>
            {isLoading ? (
              <ContentLoading small={true} />
            ) : (
              <div className="blacklist-container">
                {users.length === 0 ? (
                  <Typography className="no-stories">
                    No users to display
                  </Typography>
                ) : (
                  <UsersList
                    followings={true}
                    users={users}
                    loadNextData={this.loadNextData.bind(this)}
                    pagination={pagination}
                  />
                )}
              </div>
            )}
          </div>
        </Paper>
      </Box>
    )
  }
}

function mapStateToProps(state) {
  return {
    showUser:   state.showUser,
    auth:       state.auth,
    followings: state.followings,
  }
}

export default connect(mapStateToProps, {
  loadFollowingsList,
  initialFollowingsList,
  loadNextFollowingsList,
  getShowUser,
  showUserInitial,
  followUser,
  unfollowUser,
  setShowUser,
  searchFollowings,
  setActiveItem,
})(FollowingsPage)

/**
 * Created by boris on 3/17/17.
 */
import { SET_ADD_STORY_PAGE, SET_REQUEST_SENT } from '../actions/constants'

const initialState = {
  requestSent: false,
  isLoading:   true,
  page:        {},
}

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_ADD_STORY_PAGE: {
      const newState = Object.assign({}, initialState)
      if (action.page) {
        newState.requestSent = false
        newState.isLoading = false
        newState.page = action.page
      }

      return newState
    }
    case SET_REQUEST_SENT: {
      const newState = Object.assign({}, initialState)
      newState.requestSent = true

      return newState
    }
    default:
      return state
  }
}

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router'
import moment from 'moment'
import Avatar from '@material-ui/core/Avatar'
import Paper from '@material-ui/core/Paper'
import ContentLoading from '../ContentLoading'

import '../../css/StoryCommentsPage/StoryLikesList.css'

class StoryLikesList extends Component {
  getCreatinInfo(date) {
    let SEC_IN_DAY = 86399 // Seconds in day - 1 second
    if (Math.abs(moment().diff(date)) < SEC_IN_DAY * 1000) {
      // 1000 milliseconds
      return moment(date).fromNow()
    }
    return `${date.getDate()} ${date.toLocaleString('en-us', {
      month: 'short',
    })} ${date.getFullYear()}`
  }

  render() {
    const { storyLikes, storyLikesLoading } = this.props

    if (storyLikesLoading) {
      return <ContentLoading small={true} />
    }

    const empty = storyLikes.length === 0

    if (empty) {
      return (
        <div className="story_comments_page__story_likes_list__no_likes">
          No likes
        </div>
      )
    }

    return (
      <Paper className="story_comments_page__story_likes_list__root_container">
        {storyLikes.map((storyLike, key) => {
          const { created_at, user_username, user_avatar, user_id } = storyLike

          const userPath = `/users/${user_id}`

          return (
            <div
              key={key}
              className="story_comments_page__story_likes_list__story_item_container"
            >
              <Link
                to={userPath}
                className="story_comments_page__story_likes_list__avatar_container"
              >
                <Avatar
                  src={user_avatar.medium}
                  style={{ width: '50px', height: '50px' }}
                />
              </Link>

              <div className="story_comments_page__story_likes_list__right_container">
                <Link
                  to={userPath}
                  className="story_comments_page__story_likes_list__username"
                >
                  @{user_username}
                </Link>
                &nbsp;liked this&nbsp;
                <span className="story_comments_page__story_likes_list__created_info">
                  {this.getCreatinInfo(new Date(created_at))}
                </span>
              </div>
            </div>
          )
        })}
      </Paper>
    )
  }
}

StoryLikesList.propTypes = {
  storyLikes:        PropTypes.array,
  storyLikesLoading: PropTypes.bool.isRequired,
}

export default StoryLikesList

/**
 * Created by boris on 3/1/17.
 */
import {
  SET_SHOW_USER,
  SET_SHOW_USER_STORIES,
  SET_STORY_LIKE,
  SHOW_USER_INITIAL,
  SET_NEXT_SHOW_USER_STORIES,
  SHOW_USER_STORIES_INITIAL,
  SET_READING_LIST_ITEM,
  DELETE_READING_LIST_ITEM,
  SET_SHOWUSER_CURRENT_POSITION,
} from '../actions/constants'

const userInitialState = {
  isLoading: true,
  user:      {},
}
export function showUser(state = userInitialState, action) {
  switch (action.type) {
    case SHOW_USER_INITIAL: {
      return {
        isLoading: true,
        user:      {},
      }
    }

    case SET_SHOW_USER: {
      let newState = {
        isLoading: false,
        user:      {},
      }
      if (action.user) newState.user = action.user

      return newState
    }
    default:
      return state
  }
}

const initialState = {
  isLoading:       true,
  success:         false,
  stories:         new Map(),
  pagination:      {},
  currentPosition: null,
}

export function showUserStories(state = initialState, action) {
  switch (action.type) {
    case SHOW_USER_STORIES_INITIAL: {
      return {
        isLoading:       true,
        success:         false,
        stories:         new Map(),
        pagination:      {},
        currentPosition: null,
      }
    }
    case SET_SHOW_USER_STORIES: {
      let newState = {
        isLoading:       false,
        success:         false,
        stories:         new Map(),
        pagination:      {},
        currentPosition: null,
      }

      if (action.data) {
        const { data, pagination } = action.data
        data.forEach(story => newState.stories.set(story.id, story))
        newState.pagination = Object.assign({}, pagination)
        newState.success = true
      }

      return newState
    }

    case SET_STORY_LIKE: {
      let newState = Object.assign({}, state, {
        pagination: Object.assign({}, state.pagination),
      })
      newState.stories = new Map(state.stories)
      newState.stories.set(action.story.id, action.story)
      return newState
    }

    case SET_NEXT_SHOW_USER_STORIES: {
      let newState = {
        isLoading:       false,
        success:         false,
        stories:         new Map(state.stories),
        pagination:      {},
        currentPosition: null,
      }

      if (action.data) {
        const { data, pagination } = action.data
        data.forEach(story => newState.stories.set(story.id, story))
        newState.pagination = Object.assign({}, pagination)
        newState.success = true
      }

      return newState
    }

    case SET_READING_LIST_ITEM: {
      const { item } = action.item
      let newState = Object.assign({}, state, {
        stories:    new Map(state.stories),
        pagination: Object.assign({}, state.pagination),
      })
      newState.stories.set(item.id, item)

      return newState
    }

    case DELETE_READING_LIST_ITEM: {
      let newState = Object.assign({}, state, {
        stories:    new Map(state.stories),
        pagination: Object.assign({}, state.pagination),
      })
      let story = Object.assign({}, newState.stories.get(action.item_id))
      story.read_later = false
      newState.stories.set(story.id, story)

      return newState
    }

    case SET_SHOWUSER_CURRENT_POSITION: {
      let newState = Object.assign({}, state, {
        stories:    new Map(state.stories),
        pagination: Object.assign({}, state.pagination),
      })
      newState.currentPosition = action.position

      return newState
    }

    default:
      return state
  }
}

/**
 * Created by boris on 4/3/17.
 */
import { combineReducers } from 'redux'
import {
  SET_SEARCH_PEOPLE,
  SET_SEARCH_PEOPLE_LOADING,
  INITIAL_SEARCH_PEOPLE,
  SET_NEXT_SEARCH_PEOPLE,
  SET_SEARCH_PEOPLE_LIST_USER,
  INITIAL_SEARCH_TAG_STORIES,
  SET_SEARCH_TAG_STORIES_LOADING,
  SET_SEARCH_TAG_STORIES,
  SET_NEXT_SEARCH_TAG_STORIES,
  SET_SEARCH_LOCATION_STORIES,
  INITIAL_SEARCH_LOCATION_STORIES,
  SET_SEARCH_LOCATION_STORIES_LOADING,
  SET_NEXT_SEARCH_LOCATION_STORIES,
  SET_SEARCH_TAB,
  SET_CURRENT_SEARCH_USER_STORIES,
  INITIAL_CURRENT_SEARCH_USER_STORIES,
  SET_CURRENT_SEARCH_USER_STORIES_LOADING,
  SET_NEXT_CURRENT_SEARCH_USER_STORIES,
  SET_CURRENT_SEARCH_USER_TEXT,
  SET_OTHER_SEARCH_USER_STORIES,
  INITIAL_OTHER_SEARCH_USER_STORIES,
  SET_OTHER_SEARCH_USER_STORIES_LOADING,
  SET_NEXT_OTHER_SEARCH_USER_STORIES,
  SET_OTHER_SEARCH_USER_TEXT,
  SET_OTHER_SEARCH_USER_ID,
} from '../actions/constants'

const initialPeople = {
  isLoading:  false,
  text:       '',
  users:      new Map(),
  pagination: {},
}

export function people(state = initialPeople, action) {
  switch (action.type) {
    case INITIAL_SEARCH_PEOPLE: {
      return {
        isLoading:  false,
        text:       '',
        users:      new Map(),
        pagination: {},
      }
    }

    case SET_SEARCH_PEOPLE_LOADING: {
      return {
        isLoading:  true,
        text:       state.text,
        users:      new Map(state.users),
        pagination: Object.assign(state.pagination),
      }
    }

    case SET_SEARCH_PEOPLE: {
      let newState = {
        isLoading:  false,
        text:       action.name,
        users:      new Map(),
        pagination: {},
      }

      if (action.data) {
        const { data, pagination } = action.data
        data.forEach(user => newState.users.set(user.id, user))
        newState.pagination = Object.assign({}, pagination)
      }

      return newState
    }

    case SET_NEXT_SEARCH_PEOPLE: {
      let newState = Object.assign({}, state, {
        users:      new Map(state.users),
        pagination: Object.assign({}, state.pagination),
      })
      if (action.data) {
        const { data, pagination } = action.data
        data.forEach(user => newState.users.set(user.id, user))
        newState.pagination = Object.assign({}, pagination)
      }

      return newState
    }

    case SET_SEARCH_PEOPLE_LIST_USER: {
      let newState = {
        isLoading:  false,
        text:       state.name,
        users:      new Map(state.users),
        pagination: Object.assign({}, state.pagination),
      }
      if (action.user) {
        const { user } = action
        newState.users.set(user.id, user)
      }

      return newState
    }
    default:
      return state
  }
}
const initialTagStories = {
  isLoading:  false,
  text:       '',
  stories:    new Map(),
  pagination: {},
}

export function tagStories(state = initialTagStories, action) {
  switch (action.type) {
    case INITIAL_SEARCH_TAG_STORIES: {
      return {
        isLoading:  false,
        text:       '',
        stories:    new Map(),
        pagination: {},
      }
    }

    case SET_SEARCH_TAG_STORIES_LOADING: {
      return {
        isLoading:  true,
        text:       state.text,
        stories:    new Map(state.stories),
        pagination: Object.assign(state.pagination),
      }
    }

    case SET_SEARCH_TAG_STORIES: {
      let newState = {
        isLoading:  false,
        text:       action.text,
        stories:    new Map(),
        pagination: {},
      }

      if (action.data) {
        const { data, pagination } = action.data
        data.forEach(story => newState.stories.set(story.id, story))
        newState.pagination = Object.assign({}, pagination)
      }

      return newState
    }

    case SET_NEXT_SEARCH_TAG_STORIES: {
      let newState = Object.assign({}, state, {
        stories:    new Map(state.stories),
        pagination: Object.assign({}, state.pagination),
      })
      if (action.data) {
        const { data, pagination } = action.data
        data.forEach(story => newState.stories.set(story.id, story))
        newState.pagination = Object.assign({}, pagination)
      }

      return newState
    }

    default:
      return state
  }
}

const initialLocationStories = {
  isLoading:  false,
  text:       '',
  stories:    new Map(),
  pagination: {},
}

export function locationStories(state = initialLocationStories, action) {
  switch (action.type) {
    case INITIAL_SEARCH_LOCATION_STORIES: {
      return {
        isLoading:  false,
        text:       '',
        stories:    new Map(),
        pagination: {},
      }
    }

    case SET_SEARCH_LOCATION_STORIES_LOADING: {
      return {
        isLoading:  true,
        text:       state.text,
        stories:    new Map(state.stories),
        pagination: Object.assign(state.pagination),
      }
    }

    case SET_SEARCH_LOCATION_STORIES: {
      let newState = {
        isLoading:  false,
        text:       action.text,
        stories:    new Map(),
        pagination: {},
      }

      if (action.data) {
        const { data, pagination } = action.data
        data.forEach(story => newState.stories.set(story.id, story))
        newState.pagination = Object.assign({}, pagination)
      }

      return newState
    }

    case SET_NEXT_SEARCH_LOCATION_STORIES: {
      let newState = Object.assign({}, state, {
        stories:    new Map(state.stories),
        pagination: Object.assign({}, state.pagination),
      })
      if (action.data) {
        const { data, pagination } = action.data
        data.forEach(story => newState.stories.set(story.id, story))
        newState.pagination = Object.assign({}, pagination)
      }

      return newState
    }

    default:
      return state
  }
}

// Current User Stories

const initialCurrentUserStories = {
  isLoading:  false,
  stories:    new Map(),
  text:       '',
  pagination: {},
}

export function currentUserStories(state = initialCurrentUserStories, action) {
  switch (action.type) {
    case INITIAL_CURRENT_SEARCH_USER_STORIES: {
      return Object.assign({}, initialCurrentUserStories, {
        stories:    new Map(),
        pagination: {},
      })
    }

    case SET_CURRENT_SEARCH_USER_STORIES_LOADING: {
      return {
        isLoading:  true,
        text:       state.text,
        stories:    new Map(state.stories),
        pagination: Object.assign(state.pagination),
      }
    }

    case SET_CURRENT_SEARCH_USER_STORIES: {
      let newState = Object.assign({}, state, {
        isLoading:  false,
        stories:    new Map(),
        pagination: {},
      })

      if (action.data) {
        const { data, pagination } = action.data
        data.forEach(story => newState.stories.set(story.id, story))
        newState.pagination = Object.assign({}, pagination)
      }

      return newState
    }

    case SET_NEXT_CURRENT_SEARCH_USER_STORIES: {
      let newState = Object.assign({}, state, {
        stories:    new Map(state.stories),
        pagination: Object.assign({}, state.pagination),
      })
      if (action.data) {
        const { data, pagination } = action.data
        data.forEach(story => newState.stories.set(story.id, story))
        newState.pagination = Object.assign({}, pagination)
      }

      return newState
    }

    case SET_CURRENT_SEARCH_USER_TEXT: {
      let newState = Object.assign({}, state, {
        stories:    new Map(state.stories),
        pagination: Object.assign({}, state.pagination),
      })
      if (action.text) newState.text = action.text

      return newState
    }

    default:
      return state
  }
}

// Other User Stories

const initialOtherUserStories = {
  isLoading:  false,
  stories:    new Map(),
  text:       '',
  pagination: {},
  user_id:    null,
}

export function otherUserStories(state = initialOtherUserStories, action) {
  switch (action.type) {
    case INITIAL_OTHER_SEARCH_USER_STORIES: {
      return Object.assign({}, initialOtherUserStories, {
        stories:    new Map(),
        pagination: {},
      })
    }

    case SET_OTHER_SEARCH_USER_STORIES_LOADING: {
      return Object.assign({}, state, {
        isLoading:  true,
        stories:    new Map(state.stories),
        pagination: Object.assign(state.pagination),
      })
    }

    case SET_OTHER_SEARCH_USER_STORIES: {
      let newState = Object.assign({}, state, {
        isLoading:  false,
        stories:    new Map(),
        pagination: {},
      })

      if (action.data) {
        const { data, pagination } = action.data
        data.forEach(story => newState.stories.set(story.id, story))
        newState.pagination = Object.assign({}, pagination)
      }

      return newState
    }

    case SET_NEXT_OTHER_SEARCH_USER_STORIES: {
      let newState = Object.assign({}, state, {
        stories:    new Map(state.stories),
        pagination: Object.assign({}, state.pagination),
      })
      if (action.data) {
        const { data, pagination } = action.data
        data.forEach(story => newState.stories.set(story.id, story))
        newState.pagination = Object.assign({}, pagination)
      }

      return newState
    }

    case SET_OTHER_SEARCH_USER_TEXT: {
      let newState = Object.assign({}, state, {
        stories:    new Map(state.stories),
        pagination: Object.assign({}, state.pagination),
      })
      if (action.text) newState.text = action.text

      return newState
    }

    case SET_OTHER_SEARCH_USER_ID: {
      let newState = Object.assign({}, state, {
        stories:    new Map(state.stories),
        pagination: Object.assign({}, state.pagination),
      })
      if (action.user_id) newState.user_id = action.user_id

      return newState
    }

    default:
      return state
  }
}

export function searchTabIndex(state = 0, action) {
  switch (action.type) {
    case SET_SEARCH_TAB: {
      return action.index
    }
    default:
      return state
  }
}

export default combineReducers({
  people,
  tagStories,
  locationStories,
  searchTabIndex,
  currentUserStories,
  otherUserStories,
})

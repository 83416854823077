// stolen from https://github.com/date-fns/date-fns/blob/a239752a1d/src/locale/en-US/_lib/formatDistance/index.js

import _ from 'lodash'
import enLocale from 'date-fns/locale/en-US'
import formatDistanceStrict from 'date-fns/formatDistanceStrict'

const formatDistanceLocale = {
  xSeconds: {
    other: '{{count}}s',
  },

  xMinutes: {
    other: '{{count}}m',
  },

  xHours: {
    other: '{{count}}h',
  },

  xDays: {
    other: '{{count}}d',
  },

  xMonths: {
    other: '{{count}}m',
  },

  xYears: {
    other: '{{count}}y',
  },
}

function formatDistance(token, count, options) {
  options = options || {}

  var result
  if (typeof formatDistanceLocale[token] === 'string') {
    result = formatDistanceLocale[token]
  } else {
    result = formatDistanceLocale[token].other.replace('{{count}}', count)
  }

  return result
}

const customLocale = _.merge(enLocale, {
  formatDistance: formatDistance,
})

export default function formatActivityCreatedAtAgo(createdAt) {
  return formatDistanceStrict(new Date(), new Date(createdAt), {
    locale: customLocale,
  })
}

/**
 * Created by boris on 3/7/17.
 */
import React from 'react'
import Paper from '@material-ui/core/Paper'
import EditPassword from './EditPassword'
import { connect } from 'react-redux'
import { setActiveItem } from '../../actions/NavbarActions'
import { Box } from '@material-ui/core'

class EditPasswordPage extends React.Component {
  componentWillMount() {
    const { setActiveItem } = this.props
    setActiveItem('nav-my-nuuz')
  }

  render() {
    return (
      <Box className="page-content">
        <Paper elevation={5} className="user-page">
          <div className="profile-edit-content">
            <EditPassword />
          </div>
        </Paper>
      </Box>
    )
  }
}

export default connect(null, {
  setActiveItem,
})(EditPasswordPage)

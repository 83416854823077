/**
 * Created by boris on 3/31/17.
 */
import axiosClient from '../axiosClient'
import {
  MAIN_URL,
  SET_FOLLOWERS_REQUEST_LIST,
  INITIAL_FOLLOWERS_REQUESTS_LIST,
  SET_NEXT_FOLLOWERS_REQUESTS_LIST,
  SET_APPROVE_REQUEST,
  SET_REJECT_REQUEST,
} from '../actions/constants'

export function initialFollowersRequestsList() {
  return {
    type: INITIAL_FOLLOWERS_REQUESTS_LIST,
  }
}

export function setFollowersRequestsList(data) {
  return {
    type: SET_FOLLOWERS_REQUEST_LIST,
    data,
  }
}

export function loadFollowersRequestsList(user_id) {
  return dispatch =>
    axiosClient
      .get(`${MAIN_URL}/users/${user_id}/followers/requests`)
      .then(response => dispatch(setFollowersRequestsList(response.data)))
}

export function setNextFollowersRequestsList(data) {
  return {
    type: SET_NEXT_FOLLOWERS_REQUESTS_LIST,
    data,
  }
}

export function loadNextFollowersRequestsList(url) {
  return dispatch =>
    axiosClient
      .get(url)
      .then(response => dispatch(setNextFollowersRequestsList(response.data)))
}

export function setApproveRequest(user_id) {
  return {
    type: SET_APPROVE_REQUEST,
    user_id,
  }
}

export function approveRequest(current_user_id, user_id) {
  return dispatch =>
    axiosClient
      .put(`${MAIN_URL}/users/${current_user_id}/followers/${user_id}`, {
        status: 'approved',
      })
      .then(
        response => response && dispatch(setApproveRequest(response.data.id))
      )
}

export function setRejectRequest(user_id) {
  return {
    type: SET_REJECT_REQUEST,
    user_id,
  }
}

export function rejectRequest(current_user_id, user_id) {
  return dispatch =>
    axiosClient
      .put(`${MAIN_URL}/users/${current_user_id}/followers/${user_id}`, {
        status: 'rejected',
      })
      .then(
        response => response && dispatch(setRejectRequest(response.data.id))
      )
}

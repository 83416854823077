import { distinct } from 'rxjs/operators'
import currentUserEmailObservable from '../observables/currentUserEmail'
;(function() {
  if (!window.gtag) {
    return
  }

  if (process.env.NODE_ENV !== 'production') {
    return
  }

  currentUserEmailObservable.pipe(distinct()).subscribe(email => {
    if (email) {
      window.gtag('config', window.gtagId, {
        user_id: email,
        email:   email,
      })
    } else {
      window.gtag('config', window.gtagId, {
        user_id: null,
        email:   null,
      })
    }
  })
})()

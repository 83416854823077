import axiosClient from '../axiosClient'

import {
  SET_ALERTS_INITIAL,
  SET_ALERTS,
  SET_NEXT_ALERTS,
  SET_ALERTS_LOADING,
  SET_ALERTS_CURRENT_POSITION,
  MAIN_URL,
} from './constants'

export function setAlertsInitial() {
  return {
    type: SET_ALERTS_INITIAL,
  }
}

export function setAlerts(data) {
  return {
    type: SET_ALERTS,
    data,
  }
}

export function setAlertsLoading() {
  return {
    type: SET_ALERTS_LOADING,
  }
}

export function getAlerts() {
  return dispatch => {
    dispatch(setAlertsLoading())
    return axiosClient.get(`${MAIN_URL}/feed/notifications`).then(response => {
      dispatch(setAlerts(response.data))
      return response
    })
  }
}

export function markAlertAsRead(feed_id) {
  return dispatch => {
    return axiosClient.put(`${MAIN_URL}/feeds`, {
      action_name: 'mark_read',
      target:      'some',
      ids:         [feed_id],
    })
  }
}

export function setNextAlerts(data) {
  return {
    type: SET_NEXT_ALERTS,
    data,
  }
}

export function getNextAlerts(url) {
  return dispatch => {
    return axiosClient.get(url).then(response => {
      dispatch(setNextAlerts(response.data))
    })
  }
}

export function setAlertsCurrentPosition(position) {
  return {
    type: SET_ALERTS_CURRENT_POSITION,
    position,
  }
}

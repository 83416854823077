export const auth_url = process.env.REACT_APP__BACKEND_ORIGIN_FROM_OUTSIDE
export const main_url =
  process.env.REACT_APP__BACKEND_ORIGIN_FROM_OUTSIDE + '/api/v2'
export const public_url = process.env.PUBLIC_URL

// Tokens

export const tokens = {
  client_id:     process.env.REACT_APP__FRONTEND_OAUTH_UID,
  client_secret: process.env.REACT_APP__FRONTEND_OAUTH_SECRET,
}

// Facebook appId
export const APP_ID = process.env.REACT_APP__FRONTEND_FB_APP_ID

export const actionCableUrl =
  process.env.REACT_APP__ACTIONCABLE_ORIGIN_FROM_OUTSIDE

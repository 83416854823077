export default function textExcerpt(text, currentSearchedTag) {
  const tag = currentSearchedTag.toLowerCase()

  const wordsArray = text.split(' ').filter(x => x.trim().length > 0)

  // console.log(wordsArray)

  const index = wordsArray.findIndex(x => {
    // console.log(`x`, x)
    // console.log(`x[0] === "#"`, x[0] === "#")
    // console.log(`x.toLowerCase().includes(tag)`, x.toLowerCase().includes(tag))
    return x[0] === '#' && x.toLowerCase().includes(tag)
  })

  // console.log(index)
  // console.log(wordsArray.slice(index - 1, 4))

  if (index !== -1) {
    const start = index === 0 ? 0 : index - 1
    return '... ' + wordsArray.slice(start, 4).join(' ') + ' ...'
  }

  return text
}

/**
 * Created by boris on 4/3/17.
 */
import React from 'react'
import { connect } from 'react-redux'
import { parse } from 'query-string'
import AppBar from '@material-ui/core/AppBar'
import TextField from '@material-ui/core/TextField'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

import ContentLoading from './ContentLoading'
import PeopleItemsList from './SearchPage/PeopleItemsList'
import TagsStoryItemsList from './SearchPage/TagsStoryItemsList'

import {
  searchPeople,
  loadNextSearchPeople,
  searchTagStories,
  loadNextSearchTagStories,
} from '../actions/SearchActions'
import { setActiveItem } from '../actions/NavbarActions'

import '../css/SearchPage.css'

const styles = {
  headline: {
    fontSize:     24,
    paddingTop:   16,
    marginBottom: 12,
    fontWeight:   400,
  },
  slide: {
    padding: 10,
  },
}

class SearchPage extends React.Component {
  constructor(props) {
    super(props)

    const { people, tagStories } = this.props

    const { pathname } = window.location

    const currentTabIndex =
      pathname === '/search/people'
        ? 0
        : pathname === '/search/hashtags'
          ? 1
          : 0

    this.state = {
      currentTabIndex,
      peopleText: people.text,
      tagsText:   tagStories.text,
    }
  }

  componentDidMount() {
    const { currentTabIndex } = this.state

    const { searchPeople, searchTagStories, setActiveItem } = this.props

    const { search } = window.location
    const { q } = parse(search)

    if (q) {
      if (currentTabIndex === 0) {
        this.setState({ peopleText: q })
        searchPeople(q)
      } else if (currentTabIndex === 1) {
        this.setState({ tagsText: q })
        searchTagStories(q)
      }
    }

    // hightlight havbar
    setActiveItem('nav-search')
  }

  handlePeopleSearch(event) {
    const { searchPeople } = this.props
    this.setState(
      {
        [event.target.name]: event.target.value,
      },
      () => searchPeople(this.state.peopleText)
    )
  }

  handleTagStoriesSearch(event) {
    const { searchTagStories } = this.props
    this.setState(
      {
        [event.target.name]: event.target.value,
      },
      () => searchTagStories(this.state.tagsText)
    )
  }

  urlTabSwitch(param) {
    switch (param) {
      case 1:
        return '/search/hashtags'
      default:
        return '/search/people'
    }
  }

  handleTabChange(_event, childIndex) {
    window.history.pushState({}, null, this.urlTabSwitch(childIndex))
    this.setState({
      currentTabIndex: childIndex,
    })
  }

  loadNextData() {
    const { people, loadNextSearchPeople } = this.props
    loadNextSearchPeople(people.pagination.next_url)
  }

  render() {
    const { peopleText, tagsText, currentTabIndex } = this.state
    const { people, tagStories, loadNextSearchTagStories } = this.props

    let { users, pagination } = people
    users = [...users.values()]

    const tabContents = {
      people: (
        <Box className="search-tab-content">
          <TextField
            placeholder="Search..."
            name="peopleText"
            className="search-tabs-searchfield"
            onChange={this.handlePeopleSearch.bind(this)}
            value={peopleText}
          />
          <h2 style={styles.headline}>People</h2>
          {people.isLoading ? (
            <ContentLoading small={true} />
          ) : (
            <Box className="blacklist-container">
              {users.length === 0 ? (
                <Typography className="no-stories">
                  No users to display
                </Typography>
              ) : (
                <PeopleItemsList
                  followers={true}
                  users={users}
                  loadNextData={this.loadNextData.bind(this)}
                  pagination={pagination}
                />
              )}
            </Box>
          )}
        </Box>
      ),
      hashtags: (
        <Box className="search-tab-content">
          <TextField
            placeholder="Search..."
            name="tagsText"
            className="search-tabs-searchfield"
            onChange={this.handleTagStoriesSearch.bind(this)}
            value={tagsText}
          />
          <h2 style={styles.headline}>Posts</h2>
          {tagStories.isLoading ? (
            <ContentLoading small={true} />
          ) : (
            <div className="blacklist-container">
              {tagStories.stories.size === 0 ? (
                <Typography className="no-stories">
                  Nothing to display
                </Typography>
              ) : (
                <TagsStoryItemsList
                  loadNextData={loadNextSearchTagStories.bind(
                    null,
                    tagStories.pagination.next_url
                  )}
                  stories={tagStories}
                  currentSearchedTag={tagsText}
                />
              )}
            </div>
          )}
        </Box>
      ),
    }

    const renderTabSwitch = param => {
      switch (param) {
        case 1:
          return tabContents.hashtags
        default:
          return tabContents.people
      }
    }

    return (
      <Box className="page-content page-content-full-height">
        <Box className="search-page">
          <AppBar elevation={0} position="static" color="default">
            <Tabs
              className="search-tabs"
              value={currentTabIndex}
              onChange={this.handleTabChange.bind(this)}
            >
              <Tab label="people" className="search-tab" />
              <Tab label="tags" className="search-tab" />
            </Tabs>
            {renderTabSwitch(currentTabIndex)}
          </AppBar>
        </Box>
      </Box>
    )
  }
}

function mapStateToProps(state) {
  return {
    people:     state.search.people,
    tagStories: state.search.tagStories,
    auth:       state.auth,
  }
}

export default connect(mapStateToProps, {
  searchPeople,
  loadNextSearchPeople,
  searchTagStories,
  loadNextSearchTagStories,
  setActiveItem,
})(SearchPage)

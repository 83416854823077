import React from 'react'
import { Link } from 'react-router'
import { connect } from 'react-redux'

import { setCurrentFeedPosition } from '../../actions/FeedActions'

import logo from '../../img/nuuz_web.png'
import nuuz from '../../img/nuuz.png'

import appStore from '../../img/app_store.png'
import { Box } from '@material-ui/core'

class NavbarLogos extends React.Component {
  render() {
    const { setCurrentFeedPosition } = this.props
    return (
      <div className="logos-block">
        <Box className="logo-block" flexDirection="row">
          <Box
            component="span"
            display="inline-flex"
            className="logo-block-image"
          >
            <Link to="/" onClick={e => setCurrentFeedPosition(null)}>
              <img src={logo} alt="logo" className="logo" />
            </Link>
          </Box>
          <Box
            component="span"
            display="inline-flex"
            className="logo-statement"
          >
            <Link to="/" onClick={e => setCurrentFeedPosition(null)}>
              <img src={nuuz} alt="nuuz" className="nuuz" />
            </Link>
          </Box>
        </Box>
        <div className="stores">
          <a href="#">
            <img src={appStore} alt="App Store" className="app-store" />
          </a>
        </div>
      </div>
    )
  }
}

export default connect(null, { setCurrentFeedPosition })(NavbarLogos)

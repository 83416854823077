import React, { Component } from 'react'
import Avatar from '@material-ui/core/Avatar'
import default_avatar from '../img/default_avatar.png'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { Link } from 'react-router'
import { connect } from 'react-redux'
import { setShowUserCurrentPosition } from '../actions/ShowUserActions'
import { setMyNuuzCurrentPosition } from '../actions/MyNuuzActions'
import classnames from 'classnames'

import '../css/UserPageInfo.css'
import { Box } from '@material-ui/core'

class UserPageInfo extends Component {
  getFullName(user) {
    return user.first_name + ' ' + user.last_name
  }

  render() {
    const {
      buttonTitle,
      linkTo,
      user,
      handleButtonClick,
      isAuthenticated,
      followersLink,
      followingsLink,
      setShowUserCurrentPosition,
      setMyNuuzCurrentPosition,
    } = this.props

    let userPageButton = (
      <Link to="/login">
        <Button variant="contained" className="edit-profile">
          {'FOLLOW'}
        </Button>
      </Link>
    )

    if (isAuthenticated) {
      userPageButton = (
        <Button
          variant="contained"
          className="edit-profile"
          onClick={handleButtonClick}
        >
          {buttonTitle}
        </Button>
      )
    }

    return (
      <div className="user-page-info">
        <Link to={linkTo} onClick={e => setShowUserCurrentPosition(null)}>
          <Avatar
            src={user.is_active ? user.avatar.medium : default_avatar}
            size={150}
            className="avatar"
          />
          <Typography className="user-userCustomLocation">
            {user.custom_location}
          </Typography>
          <Typography className="user-userBio">{user.description}</Typography>
        </Link>
        <div className="container">
          <Box fontSize={20}>
            <Link to={linkTo} onClick={e => setShowUserCurrentPosition(null)}>
              {this.getFullName(user)}
            </Link>
          </Box>
          <Typography className="user-userName">
            {'@' + user.username}
          </Typography>
          {user.is_active ? userPageButton : null}{' '}
          {user.is_active ? (
            <div className="additional-info">
              <Link
                to={linkTo}
                className={classnames({
                  'disabled-link': user.can_see_user_info === false,
                })}
                onClick={e => {
                  e.preventDefault()

                  setShowUserCurrentPosition(null)
                  setMyNuuzCurrentPosition(null)
                }}
              >
                <Typography>
                  <span className="user-info-numbers">{user.posts_count}</span>
                  posts
                </Typography>
              </Link>
              <Link
                to={followersLink}
                className={classnames({
                  'disabled-link': user.can_see_user_info === false,
                })}
              >
                <Typography>
                  <span className="user-info-numbers">
                    {user.followers_count}
                  </span>
                  followers
                </Typography>
              </Link>
              <Link
                to={followingsLink}
                className={classnames({
                  'disabled-link': user.can_see_user_info === false,
                })}
              >
                <Typography>
                  <span className="user-info-numbers">
                    {user.followings_count}
                  </span>
                  following
                </Typography>
              </Link>
            </div>
          ) : (
            <h3 className="user-info-deactivated">Account Deactivated</h3>
          )}
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    isAuthenticated: state.auth.isAuthenticated,
  }
}

export default connect(mapStateToProps, {
  setShowUserCurrentPosition,
  setMyNuuzCurrentPosition,
})(UserPageInfo)

import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router'
import classnames from 'classnames'
import PropTypes from 'prop-types'

import _ from 'lodash'

import Avatar from '@material-ui/core/Avatar'
import Divider from '@material-ui/core/Divider'

import { setShowUserCurrentPosition } from '../../actions/ShowUserActions'
import { messageContent } from './lib/messageContent'
import formatActivityCreatedAtAgo from '../../utils/formatActivityCreatedAtAgo'
import { Box } from '@material-ui/core'

class ActivityHistoryItem extends React.Component {
  render() {
    const { activity, setShowUserCurrentPosition } = this.props
    const { user } = activity

    const message = messageContent(activity)

    const readState = _.get(activity, 'read', true)

    return (
      <div
        className={classnames('user-item', 'story-item', {
          unread: !readState,
        })}
      >
        <div className="user-item-content">
          <Link
            to={`/users/${user.id}`}
            className="user-info-avatar"
            onClick={e => setShowUserCurrentPosition(null)}
          >
            <Avatar
              src={user.avatar.medium}
              style={{ width: '45px', height: '45px' }}
            />
          </Link>
          <div
            onClick={() => this.context.router.push(activity.link_to_content)}
            className="user-info-name"
          >
            <div className="container">
              <Box className="story-item-info">
                <span
                  className="story-item-excerpt story-item-description"
                  style={{ color: '#030303' }}
                >
                  {message}
                </span>
              </Box>
              <div className="user-item__created_at_ago">
                {formatActivityCreatedAtAgo(_.get(activity, 'created_at'))}
              </div>
            </div>
          </div>
          <Link to={activity.link_to_content}>
            <div className="story-item-image">
              <img
                src={
                  activity.feedable_type === 'Relationship'
                    ? activity.item_owner.avatar.medium
                    : activity.image
                }
                alt=""
              />
            </div>
          </Link>
        </div>
        <Divider inset="true" className="story-item-divider" />
      </div>
    )
  }
}

ActivityHistoryItem.contextTypes = {
  router: PropTypes.object,
}

export default connect(null, { setShowUserCurrentPosition })(
  ActivityHistoryItem
)

export default function mk_following_users_comments_(following_users_comments) {
  if (following_users_comments.length === 0) {
    return []
  }

  const following_users_comments_ = []

  if (following_users_comments.length === 1) {
    following_users_comments_.push({
      type: 'user',
      user: following_users_comments[0].user,
    })

    following_users_comments_.push({
      type: 'text',
      text: ' commented',
    })
  } else if (following_users_comments.length === 2) {
    following_users_comments_.push({
      type: 'user',
      user: following_users_comments[0].user,
    })

    following_users_comments_.push({
      type: 'text',
      text: ' ',
    })

    following_users_comments_.push({
      type: 'user',
      user: following_users_comments[1].user,
    })

    following_users_comments_.push({
      type: 'text',
      text: ' commented',
    })
  } else {
    following_users_comments_.push({
      type: 'user',
      user: following_users_comments[0].user,
    })

    following_users_comments_.push({
      type: 'text',
      text: ' ',
    })

    following_users_comments_.push({
      type: 'user',
      user: following_users_comments[1].user,
    })

    following_users_comments_.push({
      type: 'text',
      text: ' and others commented',
    })
  }

  return following_users_comments_
}

/**
 * Created by boris on 2/21/17.
 */
import React, { Component } from 'react'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import AddComment from './AddComment'

import '../css/CommentReplies.css'

export default class CommentReplies extends Component {
  render() {
    const { replyClick, handleAddComment, comment, allUsers } = this.props
    return (
      <div className="comment-reply">
        <ReactCSSTransitionGroup
          transitionName="add-reply-block"
          transitionEnterTimeout={700}
          transitionLeaveTimeout={700}
        >
          {replyClick ? (
            <AddComment
              addComment={handleAddComment}
              hint="Write reply..."
              initialValue={'@' + comment.user.username + ', '}
              setFocus={true}
              parent_id={comment.id}
              allUsers={allUsers}
            />
          ) : null}
        </ReactCSSTransitionGroup>
      </div>
    )
  }
}

/**
 * Created by boris on 3/7/17.
 */
import React from 'react'
import Paper from '@material-ui/core/Paper'
import UserPageInfo from '../UserPageInfo'
import EditProfile from './EditProfile'
import { browserHistory } from 'react-router'
import { connect } from 'react-redux'
import { setActiveItem } from '../../actions/NavbarActions'
import { Box } from '@material-ui/core'

class EditProfilePage extends React.Component {
  componentWillMount() {
    const { setActiveItem } = this.props
    setActiveItem('nav-my-nuuz')
  }

  render() {
    const { user } = this.props.auth
    return (
      <Box className="page-content">
        <Paper elevation={5} className="user-page">
          <div>
            <UserPageInfo
              followingsLink={`/users/${user.id}/followings`}
              followersLink={`/users/${user.id}/followers`}
              buttonTitle={'MY NUUZ'}
              handleButtonClick={() => browserHistory.push(`/users/${user.id}`)}
              linkTo={`/users/${user.id}`}
              user={user}
            />
            <Box className="profile-edit-content">
              <EditProfile />
            </Box>
          </div>
        </Paper>
      </Box>
    )
  }
}

function mapStateToProps(state) {
  return {
    state: state,
    auth:  state.auth,
  }
}
export default connect(mapStateToProps, { setActiveItem })(EditProfilePage)

/**
 * Created by boris on 4/25/17.
 */
import React from 'react'
import {
  getReadingList,
  loadNextReadingList,
  initialReadingList,
  setCurrentReadingListPosition,
} from '../actions/ReadingListActions'
import { setActiveItem } from '../actions/NavbarActions'
import { connect } from 'react-redux'
import InfiniteScroll from 'react-infinite-scroll-component'
import { MAIN_URL } from '../actions/constants'
import ContentLoading from './ContentLoading'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import { loadMyNuuz } from '../actions/MyNuuzActions'
import { submitEditStory } from '../actions/EditStoryActions'
import Story from '../components/ReadingListPage/Story'
import PageItem from '../components/ReadingListPage/PageItem'
import Scroll from 'react-scroll'

class ReadingList extends React.Component {
  componentWillMount() {
    const {
      getReadingList,
      readingListCurrentPosition,
      initialReadingList,
    } = this.props
    if (readingListCurrentPosition === null) {
      initialReadingList()
      getReadingList()
    }
  }

  componentDidMount() {
    const { readingListCurrentPosition, setActiveItem } = this.props
    if (readingListCurrentPosition != null) {
      Scroll.animateScroll.scrollTo(readingListCurrentPosition, { duration: 0 })
    }

    setActiveItem('nav-reading-list')
  }

  componentWillUnmount() {
    const { setCurrentReadingListPosition } = this.props
    setCurrentReadingListPosition(window.pageYOffset)
  }

  loadNextData() {
    const { pagination, loadNextReadingList } = this.props
    loadNextReadingList(pagination.next_url)
  }

  moveToArchive(story) {
    const { submitEditStory, getReadingList } = this.props
    const { text, id } = story
    submitEditStory({ rank: '', text }, id).then(getReadingList)
  }

  render() {
    const { pagination, isLoading } = this.props
    let items = [...this.props.items.values()].map(item => {
      if (item.type === 'Story') {
        return (
          <Story
            moveToArchive={this.moveToArchive.bind(this)}
            key={item.item.id}
            story={item.item}
            actionsURL={MAIN_URL + this.props.actionsURL}
            comments={false}
          />
        )
      } else {
        return <PageItem key={item.item.id} page={item} />
      }
    })

    items =
      items.length === 0 ? (
        <div className="no-stories">Nothing to display</div>
      ) : (
        <InfiniteScroll
          next={this.loadNextData.bind(this)}
          hasMore={pagination && Boolean(pagination.next_url)}
          loader={' '}
          scrollThreshold={0.5}
          endMessage=" "
          dataLength={items.length}
        >
          <ReactCSSTransitionGroup
            transitionName="add-delete-story"
            transitionAppearTimeout={700}
            transitionEnterTimeout={700}
            transitionLeaveTimeout={500}
          >
            {items}
          </ReactCSSTransitionGroup>
          {pagination && pagination.next_url ? (
            <ContentLoading small={true} list={true} />
          ) : null}
        </InfiniteScroll>
      )

    return (
      <div className="page-content">
        {isLoading ? <ContentLoading /> : items}
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    items:                      state.readingList.items,
    isLoading:                  state.readingList.isLoading,
    pagination:                 state.readingList.pagination,
    readingListCurrentPosition: state.readingList.currentPosition,
  }
}

export default connect(mapStateToProps, {
  getReadingList,
  loadNextReadingList,
  loadMyNuuz,
  submitEditStory,
  initialReadingList,
  setCurrentReadingListPosition,
  setActiveItem,
})(ReadingList)

/**
 * Created by boris on 2/28/17.
 */
import { SET_CURRENT_USER } from '../actions/constants'

const initialState = {
  isLoaded: false,
  notFound: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_CURRENT_USER: {
      return {
        isLoaded: true,
        notFound: state.notFound,
      }
    }
    case 'SET_NOT_FOUND': {
      return {
        isLoaded: state.isLoaded,
        notFound: action.notFound,
      }
    }

    default:
      return state
  }
}

/**
 * Created by boris on 2/27/17.
 */
import React from 'react'
import LoginForm from './LoginForm'
import Paper from '@material-ui/core/Paper'

import { login, logout, getSignupToken } from '../../actions/AuthActions'
import { connect } from 'react-redux'

import axiosClient from '../../axiosClient'
import { AUTH_URL, UNAUTHORIZED } from '../../actions/constants'
import { browserHistory } from 'react-router'
import { tokens, APP_ID } from '../../config'

import '../../css/Login/LoginPage.css'

class LoginPage extends React.Component {
  constructor(props) {
    super(props)
    this.checkLoginState = this.checkLoginState.bind(this)
  }
  //* *********************************************************************************************************************
  componentDidMount() {
    window.fbAsyncInit = () => {
      window.FB.init({
        appId:   APP_ID,
        xfbml:   true,
        version: 'v2.8',
      })
    }

    // Load the SDK asynchronously
    ;(function(d, s, id) {
      let js

      let fjs = d.getElementsByTagName(s)[0]
      if (d.getElementById(id)) return
      js = d.createElement(s)
      js.id = id
      js.src = '//connect.facebook.net/en_US/sdk.js'
      fjs.parentNode.insertBefore(js, fjs)
    })(document, 'script', 'facebook-jssdk')
  }

  tryLogin(token) {
    const { login } = this.props
    const data = Object.assign({}, tokens, {
      grant_type: 'password',
      scope:      'user_all',
      token,
    })

    axiosClient
      .post(AUTH_URL + '/oauth/token', data)
      .then(response => {
        login({ token })
      })
      .catch(err => {
        const error = err.response
        if (error && error.data.error === 'invalid_grant') {
          browserHistory.push('/register')
        } else if (error && error.status === UNAUTHORIZED) {
          getSignupToken().then(response => {
            this.tryLogin(token)
          })
        }
      })
  }

  statusChangeCallback(response) {
    const _login = () => {
      window.FB.login(response => {
        if (response.authResponse) {
          this.tryLogin(response.authResponse.accessToken)
        } else {
          // Cancel login
        }
      })
    }
    if (response === undefined || response.status === 'connected') {
      this.tryLogin(response.authResponse.accessToken)
    } else if (response.status === 'not_authorized') _login()
    else _login()
  }

  checkLoginState() {
    window.FB.getLoginStatus(response => {
      this.statusChangeCallback(response)
    }, true)
  }

  componentWillUpdate(nextProps) {
    if (nextProps.isAuthenticated) browserHistory.push('/')
  }
  //* *********************************************************************************************************************

  render() {
    return (
      <div className="login-page-content">
        <Paper elevation={5} className="login-form-container">
          <LoginForm handleFacebookClick={this.checkLoginState} />
        </Paper>
      </div>
    )
  }
}

export default connect(null, { login, logout })(LoginPage)

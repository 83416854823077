import React from 'react'
import { connect } from 'react-redux'

import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'

import AlertsList from './AlertsList'
import ContentLoading from '../ContentLoading'
import { getAlerts, getNextAlerts } from '../../actions/Alerts'

import { setActiveItem } from '../../actions/NavbarActions'

import '../../css/Alerts.css'

class AlertsPage extends React.Component {
  componentDidMount() {
    const { getAlerts } = this.props
    getAlerts()
  }

  componentWillMount() {
    const { setActiveItem } = this.props
    setActiveItem('nav-notifications')
  }

  render() {
    const { getNextAlerts, alerts } = this.props

    if (alerts.isLoading) {
      return (
        <div className="page-content">
          <ContentLoading />
        </div>
      )
    }

    return (
      <div className="page-content">
        <div className="nuuz-archive-content__title">MY NOTIFICATIONS</div>

        {alerts.alerts.size === 0 ? (
          <Typography className="no-stories">No alerts to display</Typography>
        ) : (
          <Paper elevation={5} className="alerts-page">
            <AlertsList
              loadNextData={getNextAlerts.bind(
                null,
                alerts.pagination.next_url
              )}
              alerts={alerts}
            />
          </Paper>
        )}
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    alerts: state.alerts,
    auth:   state.auth,
  }
}

export default connect(mapStateToProps, {
  getAlerts,
  getNextAlerts,
  setActiveItem,
})(AlertsPage)

/**
 * Created by boris on 3/1/17.
 */
import {
  SET_STORY_PAGE_COMMENTS,
  SET_DELETE_COMMENT,
  SET_ADD_COMMENT,
  SET_UPDATE_COMMENT,
  SET_COMMENT_LIKE,
  SET_STORY_COMMENTS_INITIAL,
  SET_NEXT_STORY_PAGE_COMMENTS,
  SET_IMIDIATE_LIKE,
  SET_IMIDIATE_UNLIKE,
  REALTIME_STORY_SHOW_PAGE_COMMENT_CREATED,
  REALTIME_STORY_SHOW_PAGE_COMMENT_DELETED,
  REALTIME_STORY_SHOW_PAGE_COMMENT_LIKED,
  REALTIME_STORY_SHOW_PAGE_COMMENT_UNLIKED,
} from '../../actions/constants'
import * as R from 'ramda'

function updateStoryCommentsOnRealtimeUpdate(action, state) {
  const { story_id, story_comments_data } = action.payload

  if (parseInt(state.story_id, 10) !== story_id) {
    console.log('updateStoryCommentsOnRealtimeUpdate not changed')
    return state
  }

  console.log('updateStoryCommentsOnRealtimeUpdate', action, state)

  const comments = new Map()

  story_comments_data.forEach(item => comments.set(item.id, item))

  return R.mergeDeepRight(state, { comments: { data: comments } })
}

const commentsInitialState = {
  isLoading: true,
  story_id:  null,
  comments:  null,
}

export default function storyCommentsComments(
  state = commentsInitialState,
  action
) {
  switch (action.type) {
    case SET_STORY_COMMENTS_INITIAL: {
      return Object.assign({}, commentsInitialState)
    }

    case SET_STORY_PAGE_COMMENTS: {
      const newState = Object.assign({}, commentsInitialState)

      if (action.comments) {
        const { pagination, data } = action.comments
        const comments = new Map()
        data.forEach(item => comments.set(item.id, item))
        newState.comments = {
          data:       comments,
          pagination: Object.assign({}, pagination),
        }
      }

      newState.story_id = action.story_id

      return newState
    }

    case SET_NEXT_STORY_PAGE_COMMENTS: {
      const newState = Object.assign({}, commentsInitialState)

      const { pagination, data } = action.data

      const comments = new Map()
      state.comments.data.forEach(item => comments.set(item.id, item))
      data.forEach(item => comments.set(item.id, item))

      newState.comments = {
        data:       comments,
        pagination: Object.assign({}, pagination),
      }

      return newState
    }

    case SET_ADD_COMMENT: {
      const newState = {
        isLoading: false,
        comments:  {
          pagination: Object.assign({}, state.comments.pagination),
          data:       new Map([
            [action.comment.id, action.comment],
            ...state.comments.data.entries(),
          ]),
        },
      }

      return Object.assign({}, newState)
    }

    case SET_UPDATE_COMMENT: {
      const updatedComments = new Map(state.comments.data)

      const newState = {
        isLoading: false,
        comments:  {
          pagination: Object.assign({}, state.comments.pagination),
          data:       new Map([
            ...updatedComments.entries(),
            [action.comment.id, action.comment],
          ]),
        },
      }

      return Object.assign({}, newState)
    }

    case SET_DELETE_COMMENT: {
      const updatedComments = new Map(state.comments.data)

      updatedComments.delete(action.id)

      const newState = {
        isLoading: false,
        comments:  {
          pagination: Object.assign({}, state.comments.pagination),
          data:       updatedComments,
        },
      }

      return Object.assign({}, newState)
    }

    case SET_COMMENT_LIKE: {
      let updatedComments = new Map(state.comments.data)
      updatedComments.set(action.comment.id, action.comment)

      const newState = {
        isLoading: false,
        comments:  {
          pagination: Object.assign({}, state.comments.pagination),
          data:       updatedComments,
        },
      }

      return Object.assign({}, newState)
    }

    case SET_IMIDIATE_LIKE: {
      let updatedComments = new Map(state.comments.data)
      let newState = Object.assign({}, state)
      updatedComments.set(
        action.comment_id,
        Object.assign({}, updatedComments.get(action.comment_id), {
          liked: true,
        })
      )
      newState.comments.data = updatedComments

      return newState
    }

    case SET_IMIDIATE_UNLIKE: {
      let updatedComments = new Map(state.comments.data)

      let newState = Object.assign({}, state)

      updatedComments.set(
        action.comment_id,
        Object.assign({}, updatedComments.get(action.comment_id), {
          liked: false,
        })
      )

      newState.comments.data = updatedComments

      return newState
    }

    case REALTIME_STORY_SHOW_PAGE_COMMENT_CREATED: {
      return updateStoryCommentsOnRealtimeUpdate(action, state)
    }
    case REALTIME_STORY_SHOW_PAGE_COMMENT_DELETED: {
      return updateStoryCommentsOnRealtimeUpdate(action, state)
    }
    case REALTIME_STORY_SHOW_PAGE_COMMENT_LIKED: {
      return updateStoryCommentsOnRealtimeUpdate(action, state)
    }
    case REALTIME_STORY_SHOW_PAGE_COMMENT_UNLIKED: {
      return updateStoryCommentsOnRealtimeUpdate(action, state)
    }

    default:
      return state
  }
}

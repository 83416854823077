/**
 * Created by boris on 2/27/17.
 */
import React from 'react'
import SignUpForm from './SignUpForm'
import Paper from '@material-ui/core/Paper'
import EmailForm from './EmailForm'

import '../../css/SignUp/SignUpPage.css'

import axiosClient from '../../axiosClient'
import { AUTH_URL, UNAUTHORIZED } from '../../actions/constants'
import { browserHistory } from 'react-router'
import { login, logout, getSignupToken } from '../../actions/AuthActions'
import { connect } from 'react-redux'

import { signupReload, showSignupForm } from '../../actions/SignUpActions'

import { APP_ID, tokens } from '../../config'

class SignUpPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      email: '',
      token: null,
    }
    this.handleNextClick = this.handleNextClick.bind(this)
    this.checkLoginState = this.checkLoginState.bind(this)
    this.handleBadRequest = this.handleBadRequest.bind(this)
    this.handleBackClick = this.handleBackClick.bind(this)
  }

  //* *********************************************************************************************************************

  componentDidMount() {
    window.fbAsyncInit = () => {
      window.FB.init({
        appId:   APP_ID,
        xfbml:   true,
        version: 'v2.8',
      })
    }

    // Load the SDK asynchronously
    ;(function(d, s, id) {
      let js

      let fjs = d.getElementsByTagName(s)[0]
      if (d.getElementById(id)) return
      js = d.createElement(s)
      js.id = id
      js.src = '//connect.facebook.net/en_US/sdk.js'
      fjs.parentNode.insertBefore(js, fjs)
    })(document, 'script', 'facebook-jssdk')
  }

  tryLogin(token) {
    const { login } = this.props
    const data = Object.assign({}, tokens, {
      grant_type: 'password',
      scope:      'user_all',
      token,
    })
    axiosClient
      .post(AUTH_URL + '/oauth/token', data)
      .then(response => {
        login({ token })
      })
      .catch(err => {
        const error = err.response
        if (error && error.data.error === 'invalid_grant') {
          this.handleFacebookNext(token)
        } else if (error && error.status === UNAUTHORIZED) {
          getSignupToken().then(response => {
            this.tryLogin(token)
          })
        }
      })
  }

  statusChangeCallback(response) {
    const _login = () => {
      window.FB.login(response => {
        if (response.authResponse) {
          this.tryLogin(response.authResponse.accessToken)
        } else {
          // Cancel login
        }
      })
    }
    if (response === undefined || response.status === 'connected') {
      this.tryLogin(response.authResponse.accessToken)
    } else if (response.status === 'not_authorized') _login()
    else _login()
  }

  checkLoginState() {
    window.FB.getLoginStatus(response => {
      this.statusChangeCallback(response)
    }, true)
  }

  componentWillUpdate(nextProps) {
    if (nextProps.isAuthenticated) browserHistory.push('/')
  }
  //* *********************************************************************************************************************

  handleNextClick(data) {
    this.setState({ email: data.email }, this.props.showSignupForm)
  }

  handleBackClick() {
    this.setState({ token: null }, this.props.signupReload)
  }

  handleFacebookNext(token) {
    this.setState({ token }, this.props.showSignupForm)
  }

  handleBadRequest() {
    this.setState({ token: null }, this.props.signupReload)
  }

  render() {
    const { email, token } = this.state
    const { showSignUpForm } = this.props

    let showForm = (
      <EmailForm
        email={email}
        handleNextClick={this.handleNextClick}
        handleFacebookClick={this.checkLoginState}
      />
    )

    if (showSignUpForm) {
      showForm = (
        <SignUpForm
          email={email}
          token={token}
          handleBackClick={this.handleBackClick}
          handleBadRequest={this.handleBadRequest}
        />
      )
    }

    return (
      <div className="signup-page-content">
        <Paper elevation={5} className="signup-form-container">
          {showForm}
        </Paper>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    showSignUpForm: state.signup.showSignUpForm,
  }
}

export default connect(mapStateToProps, {
  login,
  logout,
  signupReload,
  showSignupForm,
})(SignUpPage)
